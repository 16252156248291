import Check from "@geist-ui/icons/check";
import { NextPage } from "next";
import { Button, Grid, Text, useTheme } from "sparkl-ui";
import { Sparkles } from "../icons";
import Image from "next/image";

export type JobStatus =
  | "not_started"
  | "requesting"
  | "submitted"
  | "in_progress"
  | "done";

export function disabledForStatus(status: JobStatus): boolean {
  return status !== "not_started";
}

export function imageForStatus(status: JobStatus): JSX.Element {
  switch (status) {
    case "not_started":
      return <Sparkles />;
    case "requesting":
    case "in_progress":
      return <Image src="/images/spinner.svg" layout="fill" alt="" />;
    case "submitted":
    case "done":
      return <Check />;
  }
}

interface GenerateButtonProps {
  onClick: () => void;
  text: string;
  creditCost: number;
  timeEstimate: string;
  fontSize?: string;
  status: JobStatus;
}

export const GenerateButton: NextPage<GenerateButtonProps> = ({
  onClick,
  text,
  creditCost,
  timeEstimate,
  fontSize,
  status,
}) => {
  // TODO: Remove this once we integrate this change into sparkl-ui
  const theme = useTheme();
  const disabled = disabledForStatus(status);
  const icon = imageForStatus(status);
  const disabledSuccessStyle = disabled
    ? {
        backgroundColor: theme.palette.successLight,
        borderColor: theme.palette.successLight,
        color: "#fff",
      }
    : {};

  return (
    <Grid xs={24}>
      <Button
        shadow
        type="success"
        width="100%"
        onClick={onClick}
        height={"4rem"}
        style={{ ...disabledSuccessStyle, lineHeight: "2rem" }}
        disabled={disabled}
      >
        <Grid.Container direction="column" justify="center">
          <Grid>
            <Grid.Container
              direction="row"
              alignItems="center"
              justify="center"
            >
              <Grid
                height={"1.7rem"}
                style={{ width: "1.7rem", position: "relative" }}
              >
                {icon}
              </Grid>
              <Grid>
                <Text
                  font={fontSize ?? "18px"}
                  style={{ paddingRight: "0.5rem" }}
                >
                  {text}
                </Text>
              </Grid>
            </Grid.Container>
          </Grid>

          <Grid>
            <Grid.Container
              direction="row"
              alignItems="center"
              style={{ textTransform: "none" }}
              justify="center"
            >
              <Grid>
                <Text font="12px">{`(${
                  creditCost === 1
                    ? creditCost + " credit"
                    : creditCost + " credits"
                },`}</Text>
              </Grid>
              <Grid>
                {/* The current font has _really_ _really_ bad tildes */}
                <Text font="12px" style={{ fontFamily: "serif" }}>{`~`}</Text>
              </Grid>
              <Grid>
                <Text font="12px">{`${timeEstimate})`}</Text>
              </Grid>
            </Grid.Container>
          </Grid>
        </Grid.Container>
      </Button>
    </Grid>
  );
};
