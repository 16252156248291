// This is a layout component that splits its parent container into two columns,  either 50/50 or with a golden ratio.
// The golden ratio is 1.618.
// The smaller column is collapsable using teh <Collapse> component, and when it is collapsed it has no width.
// The layout is responsive, so when used on a vertically oriented design the two panels are vertically oriented
// and when used on a horizontally oriented design the two panels are horizontally oriented.
// The component has two children, which go in each column. The children should have width and height set to 100%
// and be responsive to the shape of container they are in.

import { NextPage } from "next";
import { CSSProperties } from "react";
import { useMediaQuery } from "sparkl-ui";

export interface GridTemplateProps {
  gridTemplateRows: string;
  gridTemplateColumns: string;
  gridTemplateAreas: string;
}

const getTwoPaneStyle = (
  ratio: number,
  auto: boolean,
  direction?: "column" | "row" | "column-reverse" | "row-reverse"
): GridTemplateProps => {
  const smallPaneSize = auto ? "auto" : "1fr";
  switch (direction) {
    case "column":
      return {
        gridTemplateRows: `${ratio}fr ${smallPaneSize}`,
        gridTemplateColumns: "1fr",
        gridTemplateAreas: "'bigPane' 'smallPane'",
      };
    case "column-reverse":
      return {
        gridTemplateRows: `${smallPaneSize} ${ratio}fr`,
        gridTemplateColumns: "1fr",
        gridTemplateAreas: "'smallPane' 'bigPane'",
      };
    case "row":
      return {
        gridTemplateRows: "1fr",
        gridTemplateColumns: `${ratio}fr ${smallPaneSize}`,
        gridTemplateAreas: "'bigPane smallPane'",
      };
    case "row-reverse":
      return {
        gridTemplateRows: "1fr",
        gridTemplateColumns: `${smallPaneSize} ${ratio}fr`,
        gridTemplateAreas: "'smallPane bigPane'",
      };
    default:
      return {
        gridTemplateRows: `${ratio}fr ${smallPaneSize}`,
        gridTemplateColumns: "1fr",
        gridTemplateAreas: "'bigPane smallPane'",
      };
  }
};
export interface TwoPaneGridProps {
  bigPane: React.ReactNode;
  smallPane: React.ReactNode;
  fixedDirection?: "column" | "row" | "column-reverse" | "row-reverse";
  ratio?: number;
  auto?: boolean;
}

export const TwoPaneGrid: NextPage<TwoPaneGridProps> = ({
  bigPane,
  smallPane,
  fixedDirection,
  ratio = 1.618,
  auto = false,
}) => {
  // Get the size of the viewport and set our layout direction if it's not set.
  const upMD = useMediaQuery("md", { match: "up" });
  let direction = fixedDirection;
  if (fixedDirection === undefined) {
    direction = upMD ? "row" : "column";
  }

  const { gridTemplateRows, gridTemplateColumns, gridTemplateAreas } =
    getTwoPaneStyle(ratio, auto, direction);

  // This div uses CSS grid to arrange the two panes along the `direction` axis
  // The first pane is the big pane, and the second pane is the small pane. The small pane is collapsable.
  //  The panes are sized using the golden ratio, which means the big pane is 1.618 times the size of the small pane.
  const containerStyle: CSSProperties = {
    display: "grid",
    gridTemplateRows: gridTemplateRows,
    gridTemplateColumns: gridTemplateColumns,
    gridTemplateAreas: gridTemplateAreas,
    alignItems: "start",
    width: "100%",
    height: "100%",
  };

  const bigPaneStyle: CSSProperties = {
    gridArea: "bigPane",
    width: "100%",
    height: "100%",
    padding: "0",
  };

  const smallPaneStyle: CSSProperties = {
    gridArea: "smallPane",
    width: "100%",
    height: "100%",
    padding: "0",
  };

  return (
    <div style={containerStyle}>
      <div style={bigPaneStyle}>{bigPane}</div>
      <div style={smallPaneStyle}>{smallPane}</div>
    </div>
  );
};
