// This is a custom hook that does the logic for blurhashes in images

import { decode } from "blurhash";
import { useLayoutEffect, useState } from "react";

const shimmer = (w: number, h: number) => `
<svg width="${w}" height="${h}" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
    <linearGradient id="g" x1="0%" y1="0%" x2="0%" y2="100%">
      <stop stop-color="#f4f2f4" offset="20%" />
      <stop stop-color="#dcdbdd" offset="50%" />  
      <stop stop-color="#f4f2f4" offset="70%" />
    </linearGradient>
  </defs>
  <rect width="${w}" height="${h}" fill="#f4f2f4" />
  <rect id="r" width="${w}" height="${h}" fill="url(#g)" />
  <animate xlink:href="#r" attributeName="y" from="-${h}" to="${h}" dur="1s" repeatCount="indefinite"  />
</svg>`;

const toBase64 = (str: string) =>
  typeof window === "undefined"
    ? Buffer.from(str).toString("base64")
    : window.btoa(str);

export const shimmerUrl = (w: number, h: number) =>
  `data:image/svg+xml;base64,${toBase64(shimmer(w, h))}`;

export const getBlurCanvas = (blur: string) => {
  const BLURHASH_DIM = 16;
  // punch adjusts the contrast on the decoded blur image.
  // 1 means normal, smaller values will make the effect more subtle, and larger values will make it stronger.
  // Technically, what it does is scale the AC components up or down.
  const punch = 1;
  // decode hash
  const pixels = decode(blur, BLURHASH_DIM, BLURHASH_DIM, punch);
  // temporary canvas to create a blob from decoded ImageData
  const canvas = document.createElement("canvas");
  canvas.width = BLURHASH_DIM;
  canvas.height = BLURHASH_DIM;
  const context = canvas.getContext("2d");
  const imageData = context!.createImageData(BLURHASH_DIM, BLURHASH_DIM);
  imageData.data.set(pixels);
  context!.putImageData(imageData, 0, 0);
  return canvas;
};

export const useBlur = (
  maybe_blur: string | undefined,
  w: number,
  h: number
) => {
  const [blur_url, setBlurUrl] = useState(shimmerUrl(w, h));

  useLayoutEffect(() => {
    let isCancelled = false;
    if (window === undefined || maybe_blur === undefined) {
      return;
    }
    try {
      const canvas = getBlurCanvas(maybe_blur);
      canvas.toBlob((blob) => {
        if (!isCancelled && blob) {
          setBlurUrl((oldUrl) => {
            if (oldUrl) {
              URL.revokeObjectURL(oldUrl);
            }
            return URL.createObjectURL(blob);
          });
        }
      });
    } catch (error) {
      const message = `${error} (blurhash: ${maybe_blur})`;
      fetch("/api/admin", { method: "POST", body: message });
    }

    return function cleanupBlurhash() {
      isCancelled = true;
      setBlurUrl((oldUrl) => {
        if (oldUrl) {
          URL.revokeObjectURL(oldUrl);
        }
        return oldUrl; // maybe this should be null or shimmer instead?
      });
    };
  }, [maybe_blur]);
  return blur_url;
};
