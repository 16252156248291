import { NextPage } from "next";
import { useSession } from "next-auth/react";
import { CSSProperties, useEffect, useState } from "react";
import { Button, Text } from "sparkl-ui";
import { EmptyHeart, FilledHeart } from "../components/icons";
import { dev_maybeMockSession, dev_mockUserHeader } from "../utils/devtools";
import { Reaction } from "../utils/feed_types";
import { FinishedPrompt, InProgressPrompt } from "../utils/prompt_types";
import { mutateUserLikedFeed } from "../utils/swr";

export interface ReactionsProps {
  prompt: InProgressPrompt | FinishedPrompt;
  reaction?: Reaction;
  buttonStyle?: CSSProperties;
}

const reactionText: Record<Reaction, string> = {
  like: "♡",
  //sparkling star emoji for masterpiece
  masterpiece: "💫",
  //regular star emoji for curated
  curated: "✨",
  //emoji for great
  great: "⭐",
  okay: "👍",
  //thumbs down emoji for bad
  bad: "👎",
  //warning emoji for tos
  tos: "⚠",
};

export const UserReactions: NextPage<ReactionsProps> = ({
  prompt,
  reaction,
  buttonStyle,
}) => {
  const session = dev_maybeMockSession(useSession());

  let user_in_passed_reactions = false;
  let num_others_reacted = null;
  let total_reacted = 0;

  if ("reactions" in prompt) {
    if (session && prompt.reactions[session.user_id] == reaction) {
      user_in_passed_reactions = true;
    }
    const users_reacted = Object.keys(prompt.reactions).filter(
      (user_id) => prompt.reactions[user_id] == reaction
    );
    total_reacted = users_reacted.length;
    num_others_reacted =
      users_reacted.length - Number(user_in_passed_reactions);
  }
  const [hasUserReacted, setUserReacted] = useState(user_in_passed_reactions);

  // Conceptually, we want the default of userReacted to be user_in_passed_reactions, but practically
  // this is difficult because useSession() is `null` for first render. This is how we get around that.
  useEffect(() => {
    setUserReacted(user_in_passed_reactions);
  }, [user_in_passed_reactions]);

  let Reaction = (
    <Button style={buttonStyle} className="reactionButton" disabled>
      <EmptyHeart />
    </Button>
  );
  if (num_others_reacted !== null && session !== null) {
    // we're passed a finishedPrompt with more information instead of a justGeneratedPrompt with less
    const react = async () => {
      setUserReacted(!hasUserReacted);
      await fetch(
        `/api/reactions/${prompt.id}?reaction=${reaction ?? "like"}`,
        {
          method: "POST",
          body: JSON.stringify({
            reaction: reaction ?? "like",
            type: hasUserReacted ? "undo" : "do",
          }),
          headers: dev_mockUserHeader(),
        }
      );
      mutateUserLikedFeed(session.user_id.toString());
      // TODO: maybe it would be nice to update what the user has from the server?
    };

    const reactionNumber = num_others_reacted + Number(hasUserReacted);

    // TODO: would be nice to have an animation here.
    Reaction = (
      <Button
        style={buttonStyle}
        onClick={react}
        className="reactionButton"
        title="Like"
      >
        {!reaction || reaction == "like" ? (
          hasUserReacted ? (
            <FilledHeart />
          ) : (
            <EmptyHeart />
          )
        ) : hasUserReacted ? (
          <Text b>{reactionText[reaction]}</Text>
        ) : (
          <Text>{reactionText[reaction]}</Text>
        )}
        <Text b>{reactionNumber > 0 ? " " + reactionNumber : ""}</Text>
      </Button>
    );
  }

  // Deleted stuff for multi-emoji reacts is deleted

  return <>{Reaction}</>;
};
