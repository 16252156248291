// Extracting the lightbox to its own component
// it will be owned by the Feed and passed down to each child component as a prop.
// The lightbox is a modal that opens when the user clicks on the image.

import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { Modal, useMediaQuery, useModal } from "sparkl-ui";
import { UrlObject } from "url";
import { FinishedPrompt } from "../../utils/prompt_types";
import { LightboxPost } from "./lightbox_post";

interface PreviousURL {
  url: UrlObject;
  as: string;
}

export const useLightbox = () => {
  const router = useRouter();
  const [lightboxPrompt, setLightboxPrompt] = useState<FinishedPrompt>();
  const [slideIndex, setSlideIndex] = useState(0);
  const { visible, setVisible, bindings } = useModal();

  const upMD = useMediaQuery("md", { match: "up" });
  function onClose() {
    if (router.query.noback !== "true") {
      //if router.back would make us leave the website, we want to not do that
      router.back();
    } else {
      router.replace("/", undefined, { shallow: true }).then(() => {
        // This hack was needed in the past - if pages get stuck when lightbox happens, know that this was a workaround that used to work
        //router.reload();
      });
    }
  }

  const closeLightbox = () => {
    if (visible) {
      setVisible(false);
      onClose();
    }
  };

  useEffect(() => {
    setVisible(router.query.prompt !== undefined);
  }, [setVisible, router.query.prompt]);

  // this function is weird, because it does two disparate things:
  // - set the URL + route to the prompt
  // - set the modal to the right state

  // it has the if condition in it, because in some contexts
  // the former is unneccessary
  const openWithLightbox = (
    prompt: FinishedPrompt,
    index: number
  ): Function => {
    return () => {
      const asPath =
        index === 0 ? `/prompt/${prompt.id}` : `/prompt/${prompt.id}/${index}`;

      if (router.asPath !== asPath) {
        router.push(
          {
            pathname: router.pathname,
            query: { ...router.query, prompt: prompt.id, image: index },
          },
          asPath,
          { shallow: true, scroll: false }
        );
      }

      setLightboxPrompt(prompt);
      setSlideIndex(index);
    };
  };

  const modalWidth = upMD ? "75vw" : "100vw";
  const modalHeight = upMD ? "auto" : "100%";
  const Lightbox = (): React.ReactElement => (
    <Modal
      width={modalWidth}
      height={modalHeight}
      padding={0}
      {...bindings}
      onClose={onClose}
    >
      <Modal.Content height="100%" width="100%" padding={0}>
        {lightboxPrompt && (
          <LightboxPost
            prompt={lightboxPrompt}
            wide={upMD ? true : false}
            indexForLightbox={slideIndex}
          />
        )}
      </Modal.Content>
    </Modal>
  );
  return { openWithLightbox, closeLightbox, Lightbox };
};
