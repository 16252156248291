import Head from "next/head";
import { models_with_feeds } from "../../utils/feed_types";

// Always prefetch feeds
// Should ruminate more on whether this is the right call at some point.
const prefetchUrls: string[] = [];
for (const model of models_with_feeds) {
  prefetchUrls.push(`/api/prompts?model=${model}&completed=true&type=New`);
  prefetchUrls.push(`/api/curated?model=${model}&completed=true&type=Popular`);
}
prefetchUrls.push("/api/prompts?completed=true&type=New");
prefetchUrls.push("/api/curated?completed=true&type=Popular");

export const SparklHead = () => {
  return (
    <Head>
      {/* General */}
      <title>Sparkl - Generate Images with AI</title>
      <meta name="description" content="usah intaface," />
      <link rel="icon" href="/favicon.ico" />
      {/* PWA */}
      <meta name="application-name" content="Sparkl" />
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta name="apple-mobile-web-app-title" content="Sparkl" />
      <meta name="description" content="Sparkl AI Art Generator" />
      <meta name="format-detection" content="telephone=no" />
      <meta name="mobile-web-app-capable" content="yes" />

      <link rel="apple-touch-icon" href="/icons/touch-icon-iphone.png" />
      <link
        rel="apple-touch-icon"
        sizes="152x152"
        href="/icons/touch-icon-ipad.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="180x180"
        href="/icons/touch-icon-iphone-retina.png"
      />
      <link
        rel="apple-touch-icon"
        sizes="167x167"
        href="/icons/touch-icon-ipad-retina.png"
      />

      <link
        rel="icon"
        type="image/png"
        sizes="32x32"
        href="/icons/favicon-32x32.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="16x16"
        href="/icons/favicon-16x16.png"
      />
      <link rel="manifest" href="/manifest.json" />
      <link
        rel="mask-icon"
        href="/icons/safari-pinned-tab.svg"
        color="#20134B"
      />
      <link rel="shortcut icon" href="/favicon.ico" />

      {prefetchUrls.map((url) => (
        <link
          key={url}
          rel="prefetch"
          href={url}
          as="fetch"
          crossOrigin="anonymous"
        />
      ))}
    </Head>
  );
};
