import { ImageSizeParam } from "../generator_subcomponents/image_size";
import { InitImageParam } from "../generator_subcomponents/init_image";
import { ParamsGrid } from "../generator_subcomponents/params_grid";
import { useGenie, useGenieDispatch } from "../use_genie";
import { VQGANParams } from "./model_generation_component_types";
import { SeedParam } from "../generator_subcomponents/seed";
import { AestheticParam } from "../generator_subcomponents/aesthetic";
import { CutPowParam } from "../generator_subcomponents/cut_power";

export const Vqgan: React.FC = () => {
  // Individual model components like this one should grab params and pass down
  // primitive types to the subcomponents. Subcomponents are responsible for
  // dispatching actions to the store.
  // Model specifics can be found at
  // https://github.com/dryadsystems/sprkpnt/blob/main/api/params.md#vqgan

  const params = useGenie();
  const genieDispatch = useGenieDispatch();
  const vqganParams: VQGANParams = params.allModelsParams.vqgan as VQGANParams;

  return (
    <ParamsGrid>
      <ImageSizeParam width={vqganParams.width} height={vqganParams.height} />
      {/* <SeedParam seed={vqganParams.seed} /> -- reproducability doesn't work*/}
      <AestheticParam reaction_loss={vqganParams.reaction_loss} />
      <CutPowParam cut_pow={vqganParams.cut_pow} />
    </ParamsGrid>
  );
};
