import { NextPage } from "next";
import { Carousel } from "react-responsive-carousel";
import { useTheme } from "sparkl-ui";

export interface ImageCarouselProps {
  images: JSX.Element[];
  selectedIndex?: number;
  changeIndex?: (index: number) => void;
}
export const ImageCarousel: NextPage<ImageCarouselProps> = ({
  images,
  selectedIndex,
  changeIndex,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        backgroundColor: theme.palette.accents_2,
        overflow: "hidden",
        width: "100%",
        height: "100%",
      }}
    >
      <Carousel
        selectedItem={selectedIndex}
        showThumbs={false}
        showStatus={false}
        showArrows={true}
        showIndicators={false}
        width={"100%"}
        onChange={changeIndex}
      >
        {images}
      </Carousel>
    </div>
  );
};
