import { NextPage } from "next";
import { ChangeEvent, useRef, useState } from "react";
import {
  Textarea,
  Text,
  Button,
  useTheme,
  useMediaQuery,
  useToasts,
} from "sparkl-ui";
import { FinishedPrompt } from "../../utils/prompt_types";
import AvatarEditor from "react-avatar-editor";
import Check from "@geist-ui/icons/check";
import Plus from "@geist-ui/icons/plus";
import Minus from "@geist-ui/icons/minus";
import { mutate } from "swr";
import { AvatarFailure, AvatarSuccess } from "../../pages/api/user/[id]/avatar";
import { Slider } from "../genie/generator_subcomponents/slider";
interface SetAvatarProps {
  prompt: FinishedPrompt;
  type: "avatar" | "cover";
  index: number;
  closeModal: () => void;
}

export const SetAvatarMenu: NextPage<SetAvatarProps> = ({
  prompt,
  type,
  index,
  closeModal,
}) => {
  const [submitted, setSubmitted] = useState<boolean>(false);
  const [scale, setScale] = useState<number>(type == "avatar" ? 1.6 : 1.0);
  const handler = (val: number) => {
    setScale(val);
  };
  const editor = useRef<AvatarEditor | null>(null);
  const theme = useTheme();
  const { setToast } = useToasts();

  async function submit() {
    setSubmitted(true);
    if (editor.current) {
      const canvas: HTMLCanvasElement = editor.current.getImage();
      const data = canvas.toBlob(async (blob) => {
        if (!blob) {
          //TODO: handle error
          setToast({
            text: `Setting {type} failed: no image data`,
            type: "error",
          });
          return;
        }
        const uuid = crypto.randomUUID();
        const file = new File([blob], `${uuid}.png`, {
          type: "image/png",
        });

        const url = `https://user-upload-worker.drysys.workers.dev/${uuid}.png`;
        //allow cors
        try {
          const resp = await fetch(url, {
            method: "PUT",
            body: file,
            headers: {
              "Access-Control-Allow-Origin": "*",
            },
          });

          if (resp.status != 200) {
            setToast({
              text: `Setting {type} failed: image upload failed - ${resp.status} ${resp.statusText}`,
              type: "error",
            });
            return;
          }

          mutate(
            `/api/user/${prompt.user_id}/avatar`,
            async () => {
              const res = await fetch(`/api/user/change_avatar`, {
                method: "POST",
                body: JSON.stringify({
                  url: url,
                  type: type,
                }),
              });

              if (res.status == 200) {
                return { status: "success", src: url } as AvatarSuccess;
              } else {
                return {
                  status: "failure",
                  code: "mutate failure",
                } as AvatarFailure;
              }
            },
            {
              optimisticData: { status: "success", src: url },
            }
          );
        } catch (e) {
          setToast({
            text: `Setting {type} failed: image upload failed`,
            type: "error",
          });
          console.log(e);
        }
      }, "image/png");
    } else {
      setToast({
        text: `Setting {type} failed: editor not found`,
        type: "error",
      });
    }
    closeModal();
  }

  return (
    <div
      onClick={(e) => e.stopPropagation()}
      //onClickCapture={(e) => e.stopPropagation()}
    >
      <Text h3> {type == "avatar" ? "Set Avatar" : "Set Profile Banner"} </Text>
      <div>
        {/* Note: The following pixel values don't control the actual size,
            rather just the aspect ratio, as they are scaled by the containing div. */}
        <AvatarEditor
          image={prompt.outputs.image_urls[index]}
          width={type == "avatar" ? 512 : 1024}
          height={type == "avatar" ? 512 : 256}
          border={type == "avatar" ? 50 : 100}
          borderRadius={type == "avatar" ? 256 : 0}
          color={[255, 255, 255, 0.8]} // RGBA
          scale={scale}
          rotate={0}
          style={{
            width: "100%",
            height: "100%",
          }}
          ref={editor}
          crossOrigin={"anonymous"}
        />
      </div>
      {/* The slider should prevent events from propagating on drag */}
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-around",
          marginTop: "0.6rem",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            width: "60%",
            gap: "0.5rem",
          }}
        >
          <Minus type="success" />
          <Slider
            value={1.2}
            onChange={handler}
            width="80%"
            step={0.1}
            min={1}
            max={2}
            hideValue
            style={{
              backgroundColor: theme.palette.accents_4,
            }}
          />
          <Plus />
        </div>
        <Button
          iconRight={<Check />}
          auto
          type="success"
          onClick={submit}
          loading={submitted}
        />
      </div>
    </div>
  );
};
