import { useState, useCallback } from "react";
import { useToasts } from "sparkl-ui";
import { useGenieDispatch } from "./use_genie";

function resizedSize(w: number, h: number) {
  const max_size = 704;
  const min_size = 512;
  const resize_mult = 64;

  // Downscale if needed
  // If the larger side is >704, find the scaling factor that makes it 704
  const scaling = max_size / (h > w ? h : w);

  if (scaling < 1) {
    h = h * scaling;
    w = w * scaling;
  }

  // Upscale if needed
  // If the larger side is <512, find the scaling factor that makes it 512
  const upscale_scaling = min_size / (h > w ? h : w);
  if (upscale_scaling > 1) {
    h = h * upscale_scaling;
    w = w * upscale_scaling;
  }

  return [
    Math.round(w / resize_mult) * resize_mult,
    Math.round(h / resize_mult) * resize_mult,
  ];
}

export const useUploadImage = () => {
  const [isUploading, setIsUploading] = useState(false);
  const genieDispatch = useGenieDispatch();
  const { setToast } = useToasts();

  const uploadImage = useCallback(
    async function uploadImage(file: File | null) {
      console.log(file);
      if (!file) {
        genieDispatch({ type: "setInitImageURL", initImageURL: undefined });
        return;
      }
      setIsUploading(true);
      const ext = file.name.split(".").pop();
      const uuid = crypto.randomUUID();
      //const url = `http://0.0.0.0:8787/${uuid}.${ext}`
      const url = `https://user-upload-worker.drysys.workers.dev/${uuid}.${ext}`;

      /* Get image size */
      var image = new Image();
      var objectUrl = URL.createObjectURL(file);
      image.src = objectUrl;
      const promise: Promise<{ width: number; height: number }> = new Promise(
        (resolve, reject) => {
          image.onload = () => {
            // Natural size is the actual image size regardless of rendering.
            // The 'normal' `width`/`height` are for the **rendered** size.
            const width = image.naturalWidth;
            const height = image.naturalHeight;

            // Resolve promise with the width and height
            resolve({ width, height });
          };
        }
      );

      //allow cors
      try {
        await fetch(url, {
          method: "PUT",
          body: file,
          headers: {
            "Access-Control-Allow-Origin": "*",
          },
        }).then(() => {
          genieDispatch({ type: "setInitImageURL", initImageURL: url });
          setIsUploading(false);
        });

        await promise.then(({ width, height }) => {
          const [w, h] = resizedSize(width, height);
          genieDispatch({
            type: "setImageSize",
            height: h,
            width: w,
          });
        });
      } catch (e) {
        genieDispatch({ type: "setInitImageURL", initImageURL: undefined });
        setIsUploading(false);
        setToast({
          text: `Failed to upload image`,
          type: "error",
        });
        console.log(e);
      }
    },
    [genieDispatch, setToast, setIsUploading]
  );

  return { uploadImage, isUploading };
};
