import { Description } from "sparkl-ui";
import { ButtonSelect } from "./button_select";
import { AspectLandscape, AspectPortrait, AspectSquare } from "../../icons";
import { LabeledParam } from "./labeled_param";
import { useGenie, useGenieDispatch } from "../use_genie";
import React from "react";
type ImageSize = "256x256" | "512x512" | "768x576" | "576x768";

export interface ImageSizeParamProps {
  width: number;
  height: number;
}

export const ImageSizeParam: React.FC<ImageSizeParamProps> = ({
  width,
  height,
}) => {
  const genieDispatch = useGenieDispatch();

  const imageSize = (width + "x" + height) as ImageSize;

  function setImageSize(imageSize: ImageSize) {
    //console.log("Setting to " + imageSize);
    const w = Number(imageSize.split("x")[0]);
    const h = Number(imageSize.split("x")[1]);
    if (isNaN(w) || isNaN(h)) {
      console.log("Invalid image size");
    }
    genieDispatch({
      type: "setImageSize",
      width: w,
      height: h,
    });
  }

  const imageSizeOptions = [
    {
      key: "512x512",
      icon: <AspectSquare />,
      callback: () => setImageSize("512x512"),
    },
    {
      key: "768x576",
      icon: <AspectLandscape />,
      callback: () => setImageSize("768x576"),
    },
    {
      key: "576x768",
      icon: <AspectPortrait />,
      callback: () => setImageSize("576x768"),
    },
  ];

  return (
    <LabeledParam label={<Description title={`Image Shape (${imageSize})`} />}>
      <ButtonSelect options={imageSizeOptions} selectedKey={imageSize} />
    </LabeledParam>
  );
};
