// import Edit2 from "@geist-ui/icons/edit2";
import Eye from "@geist-ui/icons/eye";
import X from "@geist-ui/icons/x";
import React, { useCallback, useState } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { Button, Card, Modal, Text, useModal, useToasts } from "sparkl-ui";
// have to import Image from sparkl-ui
// as SparklImage to avoid conflict
// with next/image
import { Image as SparklImage } from "sparkl-ui";
import { useUploadImage } from "../use_upload_image";

// type InitImageType = "none" | "init" | "inpaint";

export interface ImagePickerProps {
  initImage?: string;
  uploadCallback?: (file: File) => void;
}

export const ImagePicker: React.FC<ImagePickerProps> = ({ initImage = "" }) => {
  const { visible, setVisible, bindings } = useModal();
  const toggleVisible = useCallback(() => setVisible((v) => !v), [setVisible]);
  const { uploadImage, isUploading } = useUploadImage();
  const onDrop = useCallback(
    async (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      uploadImage(acceptedFiles[0]);
    },
    [uploadImage]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/png": [".png"],
      "image/jpeg": [".jpg", ".jpeg"],
      "image/webp": [".webp"],
    },
    multiple: false,
    maxFiles: 1,
    useFsAccessApi: false,
  });

  return (
    <Card
      type="secondary"
      hoverable
      style={{
        width: "100%",
        height: "100%",
      }}
    >
      <Card.Content padding={0} height="100%">
        {!initImage ? (
          <div
            {...getRootProps()}
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              margin: 0,
            }}
          >
            <input {...getInputProps()} />
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
              }}
            >
              <Text width="50%" padding="0.12rem">
                {isUploading
                  ? "Uploading..."
                  : "Drag or click to select an image..."}
              </Text>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignContent: "center",
              justifyItems: "start",
              height: "100%",
              width: "100%",
              backgroundImage: `url(${initImage})`,
              backgroundSize: "cover",
              backgroundPosition: "center",
              padding: "0.12rem",
            }}
            onClick={() => {
              toggleVisible();
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column-reverse",
                alignItems: "end",
                justifySelf: "end",
                bottom: "-100%",
                height: "19%",
                width: "100%",
              }}
            >
              <Button
                type="success"
                scale={0.38}
                ghost
                onClick={() => {
                  uploadImage(null);
                }}
                auto
                height={"100%"}
                iconRight={<X />}
              />
              {/* <Button
                type="success"
                scale={0.38}
                ghost
                onClick={() => {
                  setVisible(true);
                }}
                auto
                height={"100%"}
                iconRight={<Eye />}
                // iconRight={allowsEdit ? <Edit2 /> : <Eye />}
              /> */}
            </div>
            <Modal {...bindings}>
              <SparklImage src={initImage} alt="User-uploaded initial image" />
            </Modal>
          </div>
        )}
      </Card.Content>
    </Card>
  );
};
