import { ReactNode, useReducer, useState } from "react";
import { Page, useMediaQuery, useTheme } from "sparkl-ui";
import { GenieProvider } from "../components/genie/use_genie";
import { PermalinkHead } from "../pages/permalink_metadata";
import { LandscapeGrid } from "./layouts/landscape_grid";
import { NoGeneratorLayout } from "./layouts/no_generator_layout";
import { PortraitGrid } from "./layouts/portrait_grid";
import {
  defaultTabState,
  scrollReducer,
  useTabDispatch,
  useTabNav,
} from "./layouts/tabnav";
import { LightboxContext } from "./prompt_image/lightbox_context";
import { useLightbox } from "./prompt_image/use_lightbox";
// Our Grid is a responsive grid-based system, which changes at the MD breakpoint.

// Above MD, we have two main panes and a header bar. We use a golden ratio
// so we have one small pane and one large.
// The small pane always holds the generator and queue.`
// The large pane can hold a feed, a profile page, etc,
// The header always contains things that the user "owns" and expects to have access to.

// On mobile, the Grid becomes tabbed. The generator and queue are in the left tab.
// The feed is to the right of them.
// The header is always visible at the top.
// The tab navigation is always visible at the bottom.

// Ideally we can do browser URL routing as well as
// avoid rerendering things that havent changed, wherever possible.
// Keeping the Genie, Queue, and navbar items at this level should help.

export type LayoutType = "default" | "no-generator";

export interface LayoutProps {
  layoutType: LayoutType;
  children: ReactNode;
}

export default function Layout({ layoutType, children }: LayoutProps) {
  const theme = useTheme();
  const tabDispatch = useTabDispatch();
  const { openWithLightbox, closeLightbox, Lightbox } = useLightbox();
  const focusGenie = () => {
    //console.log("Calling focusGenie");
    closeLightbox();
    tabDispatch({ type: "switchTab", tab: "0", scroll: window.scrollY });
  };

  let upMD = useMediaQuery("md", { match: "up" });
  // fix SSR issues https://github.com/geist-org/geist-ui/issues/258
  if (typeof window === "undefined") {
    upMD = true;
  }
  //console.log("Redoing Grid");
  const isXS = useMediaQuery("xs");
  const topNavHeight = upMD ? "5rem" : isXS ? "2rem" : "3rem";

  let layoutContent: ReactNode;
  switch (layoutType) {
    case "no-generator":
      layoutContent = (
        <NoGeneratorLayout topNavHeight={topNavHeight}>
          {children}
        </NoGeneratorLayout>
      );
      break;
    case "default":
    default:
      if (upMD) {
        layoutContent = (
          <LandscapeGrid topNavHeight={topNavHeight}>{children}</LandscapeGrid>
        );
      } else {
        layoutContent = (
          <PortraitGrid topNavHeight={topNavHeight}>{children}</PortraitGrid>
        );
      }
  }

  return (
    <GenieProvider focusGenie={focusGenie}>
      <LightboxContext.Provider value={{ openWithLightbox }}>
        <main
          style={{
            backgroundColor: theme.palette.accents_1,
          }}
        >
          {children &&
            typeof children === "object" &&
            "props" in children &&
            "permalinkMetadata" in children.props &&
            children.props.permalinkMetadata && (
              <PermalinkHead
                permalinkMetadata={children.props.permalinkMetadata}
              />
            )}

          <Page
            width={"100%"}
            height={"100vh"}
            padding={0}
            className="page"
            render="effect"
          >
            {layoutContent}
          </Page>
        </main>
        <Lightbox />
      </LightboxContext.Provider>
    </GenieProvider>
  );
}
