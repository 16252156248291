import Tool from "@geist-ui/icons/tool";
import { Button, Grid, Popover, Radio, Spacer, Text } from "sparkl-ui";
//import { dev_getLocalStorageState, dev_setLocalStorageState } from "../utils/devtools"
import Power from "@geist-ui/icons/power";
import { useCookies } from "react-cookie";
import { mockUsers } from "../utils/devtools";
import { useGenie } from "./genie/use_genie";

export default function DevTools() {
  const [cookies, setCookies] = useCookies(["DryadDevTools_mockUser"]);
  const genieParams = useGenie();

  function setMockUser(user: string) {
    if (user == "unset") {
      //delete cookie by setting the expiration date in the past
      setCookies("DryadDevTools_mockUser", "", {
        path: "/",
        expires: new Date(0),
      });
      return;
    }
    for (const mockUser of mockUsers) {
      if (mockUser.user.username === user) {
        setCookies("DryadDevTools_mockUser", mockUser.encoded, {
          path: "/",
          sameSite: true,
        });
        return;
      }
    }
  }

  function getCurrentlySelected() {
    if (cookies["DryadDevTools_mockUser"]) {
      for (const mockUser of mockUsers) {
        // 1.5 to 3 string comparisons is prooobably faster than a parse? idk
        if (mockUser.encoded === cookies["DryadDevTools_mockUser"]) {
          return mockUser.user.username;
        }
      }
    }
  }

  const content = (
    <div
      style={{
        padding: "1rem",
      }}
    >
      <Text h4> Mock user </Text>
      <Radio.Group
        value={getCurrentlySelected() ?? "unset"}
        onChange={(k) => setMockUser(k as string)}
      >
        <Radio value="unset" type="success">
          None
        </Radio>
        <Radio value="mock_admin" type="success">
          Admin
        </Radio>
        <Radio value="mock_user" type="success">
          User
        </Radio>
        <Radio value="mock_subscriber" type="success">
          Subscriber
        </Radio>
      </Radio.Group>
      <Spacer />
      <Text h4> Log current model parameters </Text>
      <Button
        auto
        iconRight={<Power />}
        // TODO: thread genieParams through from index to here
        onClick={() => {
          const records =
            genieParams.allModelsParams[genieParams.selectedModel];
          Object.entries(records).forEach(([key, value]) => {
            console.log(key, value);
          });
        }}
      />
    </div>
  );

  return (
    <Popover
      content={content}
      style={{
        zIndex: 2,
      }}
    >
      <Button icon={<Tool />} auto ghost type="success" title="DevTools" />
    </Popover>
  );
}
