import { JWT } from "next-auth/jwt";

export type Role = "admin" | "mod" | "beta" | "alpha";

function getRole(token: JWT): Role | null {
  return token.role as Role | null;
}

export function isModerator(token: JWT): boolean {
  const role = getRole(token);
  return role === "admin" || role === "mod";
}

export function hasAlphaAccess(token: JWT): boolean {
  const role = getRole(token);
  return role === "admin" || role === "mod" || role === "alpha";
}
