import Image from "next/image";
import { useEffect, useState } from "react";
import { FinishedPrompt } from "../../utils/prompt_types";
import { useBlur } from "../../utils/use_blur";

// things shared across every image in the post
export interface SharedImageProp {
  width: number;
  height: number;
  sizes: string;
  quality: number;
  priority: boolean;
  onClick?: () => void; // FIXME: is this ever used?
}

export const getSharedImageProp = (
  prompt: FinishedPrompt,
  isLightboxPost: boolean | undefined,
  isMobile: boolean
): SharedImageProp => {
  // We get all the images for the prompt and return an arrayof ImageProps.
  let size: string;
  let quality: number;
  if (isLightboxPost) {
    size = "100vw";
    quality = 90;
  } else {
    const gridSize = prompt.outputs.image_urls.length;
    quality = isMobile && gridSize == 1 ? 90 : 75;
    if (gridSize >= 9) {
      size = "(max-width: 768px) 33vw, (max-width: 1280px) 11vw, 8vw";
    } else if (gridSize >= 4) {
      size = "(max-width: 768px) 50vw, (max-width: 1280px) 16vw, 12vw";
    } else {
      size = "(max-width: 768px) 100vw, (max-width: 1280px) 33vw, 25vw";
    }
  }
  return {
    height: prompt.params.modelParams.height ?? 512,
    width: prompt.params.modelParams.width ?? 512,
    sizes: size,
    quality: quality,
    priority: isLightboxPost ? true : false,
  };
};

export interface LoadingImageProps {
  sharedImageProp: SharedImageProp;
  prompt: FinishedPrompt;
  index: number;
}

export const LoadingImage: React.FC<LoadingImageProps> = ({
  sharedImageProp,
  prompt,
  index,
}) => {
  const image_url = prompt.outputs.image_urls.slice(0, 9)[index];
  const maybe_blur = (prompt.outputs?.blurhashes?.slice(0, 9) ?? [])[index];
  const promptText = prompt.params.sharedParams.prompts
    .map((prompt) => prompt.text)
    .join(", ");
  const alt = promptText + ` image #${index}`;
  const blur_url = useBlur(
    maybe_blur,
    sharedImageProp.width,
    sharedImageProp.height
  );

  return (
    <Image
      src={image_url}
      blurDataURL={blur_url}
      alt={alt}
      // shared image attributes
      quality={sharedImageProp.quality}
      sizes={sharedImageProp.sizes}
      priority={sharedImageProp.priority}
      height={sharedImageProp.height}
      width={sharedImageProp.width}
      onClick={sharedImageProp.onClick}
      // static image attributes
      placeholder="blur"
      layout="responsive"
      loading="eager"
      // style={{ cursor: "pointer"}}
    />
  );
};
