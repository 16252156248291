import { NextPage } from "next";
import {
  FinishedPrompt,
  PromptsFailure,
  PromptsSuccess,
} from "../../utils/prompt_types";
import { Post } from "./post";
import useSWR from "swr";
import { fetcher } from "../../utils/swr";

interface LightboxPostProps {
  prompt: FinishedPrompt;
  wide: boolean;
  indexForLightbox: number;
}

export const LightboxPost: NextPage<LightboxPostProps> = ({
  prompt,
  wide,
  indexForLightbox,
}) => {
  //completed: "true", id: prompt_id.toString(),
  const { data, error } = useSWR<PromptsSuccess, PromptsFailure>(
    () => {
      if (!prompt.id) {
        return null;
      }
      return "/api/prompts?completed=true&id=" + prompt.id.toString();
    },
    fetcher,
    {
      fallbackData: { status: "success", prompts: [prompt] },
      revalidateIfStale: false, // Don't revalidate on first load - only do so when we tell you to
      revalidateOnFocus: false,
      refreshWhenHidden: false,
      refreshInterval(latestData) {
        //console.log("Refreshing post info...");
        if (!latestData) {
          return 10000;
        }
        const prompt = latestData.prompts[0] as FinishedPrompt;
        /* Check if any upscaled image is == "pending" */
        if (
          prompt.outputs.upscaled_image_urls &&
          prompt.outputs.upscaled_image_urls.some((url) => url === "pending")
        ) {
          //console.log("Refreshing now, because we're waiting on an upscale!");
          return 1000;
        }
        return 10000;
      },
    }
  );

  // console.log("data", data);

  return (
    <Post
      prompt={
        !data || data.status !== "success" //Fallback to init prompt if error
          ? prompt
          : (data?.prompts[0] as FinishedPrompt)
      }
      wide={wide}
      carousel={true}
      indexForLightbox={indexForLightbox}
      isLightboxPost={true}
    />
  );
};
