import Image from "next/image";
import { Fieldset, Text, useTheme } from "sparkl-ui";
import { useBlur } from "../../../utils/use_blur";

// FIXME: pass meaningful alt text here
export const QueueItem = ({
  maybe_blur,
  maybe_image_url,
  status,
  openLightboxCallback,
}: {
  maybe_blur: string | undefined;
  maybe_image_url: string | undefined;
  status: string;
  openLightboxCallback: () => void;
}) => {
  const theme = useTheme();
  // is 200x200 right? should it be 100%x100%?
  const blur_url = useBlur(maybe_blur, 200, 200);
  // causes a render loop
  // const [img_url, setImgUrl] = useState(blur_url);
  // if (maybe_image_url) {
  //   // not sure if this is executed at the right time?
  //   // otherwise this can cause a small flicker when image_url becomes set
  //   // src={maybe_image_url ?? blur_url}
  //   setImgUrl(maybe_image_url);
  // }
  return (
    <div
      style={{
        padding: 0,
        margin: 0,
        border: 0,
        height: "100%",
        width: "100%",
        borderRadius: "0.5rem",
        cursor: "pointer",
        baselineShift: "sub",
      }}
    >
      {maybe_blur || maybe_image_url ? (
        <Image
          src={maybe_image_url ?? blur_url}
          className={maybe_image_url || maybe_blur ? "" : "loadingDiv"}
          width="100%"
          height="100%"
          style={{ borderRadius: "0.5rem" }}
          alt="Generated image"
          onClick={openLightboxCallback}
          placeholder="blur"
          blurDataURL={blur_url}
          loading="eager"
        />
      ) : status === "assigned" ||
        status === "pending" ||
        status === "uploading" ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            borderRadius: "0.5rem",
            padding: "0",
          }}
          className="loadingDiv"
        />
      ) : (
        <Fieldset
          style={{
            height: "100%",
            background: "inherit",
            borderColor: theme.palette.error,
            color: theme.palette.error,
            borderRadius: "0.5rem",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            flexDirection: "column",
          }}
        >
          <Text
            style={{ textAlign: "center", wordBreak: "break-word" }}
            type="error"
          >
            Failed
          </Text>
        </Fieldset>
      )}
    </div>
  );
};
