import { Grid, Text } from "sparkl-ui";
import { config } from "../../../utils/config";
import { InitImage, Inpaint } from "../../icons";
import Image from "next/image";
import React from "react";
let imgSrcImg2Img = [
  "/images/examples/seed/bear.png",
  "/images/examples/seed/tiger.png",
  "/images/examples/seed/sunrise.png",
  "/images/examples/seed/sunset.png",
];
let imgSrcInpaint = [
  "/images/examples/seed/bear.png",
  "/images/examples/seed/tiger.png",
  "/images/examples/seed/sunrise.png",
  "/images/examples/seed/sunset.png",
];

export const initImageDescription: React.FC = () => {
  return (
    <Grid.Container gap={1} direction="row">
      <Grid xs={24} md={config.allowNonfunctionalUI ? 12 : 24}>
        <Grid.Container direction="column">
          <Grid>
            <Grid.Container direction="row" alignItems="center">
              <div style={{ height: "30px" }}>
                <InitImage />
              </div>
              <Text h4>Transformation</Text>
            </Grid.Container>
          </Grid>
          <Grid>
            <Text>
              Pass in an initial image, and a prompt to spice it up with!
            </Text>
          </Grid>
          <Grid paddingBottom="20px">
            <Grid.Container gap={0.3}>
              {imgSrcImg2Img.map((src, i) => {
                return (
                  <Grid xs={12} key={src} justify="space-around">
                    <Image
                      width="160px"
                      height="160px"
                      src={src}
                      style={{ borderRadius: "3px" }}
                      alt="Images generated as transformations of an initial image"
                    />
                  </Grid>
                );
              })}
            </Grid.Container>
          </Grid>
        </Grid.Container>
      </Grid>

      {config.allowNonfunctionalUI && (
        <Grid xs={24} md={12}>
          <Grid.Container direction="column">
            <Grid>
              <Grid.Container direction="row">
                <div style={{ height: "30px" }}>
                  <Inpaint />
                </div>
                <Text h4>Inpainting</Text>
              </Grid.Container>
            </Grid>
            <Grid>
              <Text>
                Select a region to replace, and tell us what goes in there!
              </Text>
            </Grid>
            <Grid>
              <Grid.Container gap={0.3}>
                {imgSrcInpaint.map((src, i) => {
                  return (
                    <Grid xs={12} key={src} justify="space-around">
                      <Image
                        width="160px"
                        height="160px"
                        src={src}
                        style={{ borderRadius: "3px" }}
                        alt="Images generated via inpainting"
                      />
                    </Grid>
                  );
                })}
              </Grid.Container>
            </Grid>
          </Grid.Container>
        </Grid>
      )}
    </Grid.Container>
  );
};

let imgNoiseImgs = [
  "/images/examples/seed/bear.png",
  "/images/examples/seed/tiger.png",
  "/images/examples/seed/sunrise.png",
  "/images/examples/seed/sunset.png",
];

export const ImgNoiseDescription: React.FC = () => {
  return (
    <>
      <Text>Control how strongly your input image affects the output</Text>
      {imgNoiseImgs.map((src, i) => {
        return (
          <Grid xs={12} key={src} justify="space-around">
            <div></div>
            <Image
              width="160px"
              height="160px"
              src={src}
              style={{ borderRadius: "3px" }}
              alt="Images generated with different image strength values"
            />
          </Grid>
        );
      })}
    </>
  );
};
