import { Select, SelectProps } from "sparkl-ui";
import React, { useState } from "react";

export const GenericSelector: React.FC<SelectProps> = (props) => {
  return (
    <Select
      dropdownStyle={{
        zIndex: -1,
      }}
      style={{
        padding: "0 1.5rem 0 1rem",
        maxWidth: "fit-content",
        minWidth: "fit-content",
        width: "100%",
      }}
      type="success"
      disableMatchWidth
      {...props}
    ></Select>
  );
};
