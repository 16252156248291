import InfoFill from "@geist-ui/icons/infoFill";
import { NextPage } from "next";
import { ReactNode } from "react";
import { Modal, Popover, useMediaQuery, useModal } from "sparkl-ui";

interface InfoPopupProps {
  content: ReactNode;
  width?: string;
}

export const InfoPopup: NextPage<InfoPopupProps> = ({ content, width }) => {
  //const [aspectRatio, setAspectRatio] = useState<AspectRatio>(params.aspect_ratio as AspectRatio);

  let upMD = useMediaQuery("md", { match: "up" });
  const { visible, setVisible, bindings } = useModal();

  const popoverContent = (
    <Popover.Item width={width ?? "20vw"}>{content}</Popover.Item>
  );

  if (upMD) {
    return (
      <>
        <Popover content={popoverContent} trigger="hover" placement="right">
          <InfoFill color="grey" size={18} />
        </Popover>
      </>
    );
  } else {
    return (
      <>
        <InfoFill onClick={() => setVisible(true)} color="grey" size={18} />

        <Modal {...bindings}>{content}</Modal>
      </>
    );
  }
};
