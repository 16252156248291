import React from "react";
import { Description } from "sparkl-ui";
import { LabeledParam } from "../generator_subcomponents/labeled_param";
import { ParamsGrid } from "../generator_subcomponents/params_grid";
import { useGenie, useGenieDispatch } from "../use_genie";
import { DalleParams } from "./model_generation_component_types";
import { Slider } from "../generator_subcomponents/slider";
export const DalleMini: React.FC = () => {
  // Individual model components like this one should grab params and pass down
  // primitive types to the subcomponents. Subcomponents are responsible for
  // dispatching actions to the store.
  // https://github.com/dryadsystems/sprkpnt/blob/main/api/params.md#dalle

  const params = useGenie();
  const genieDispatch = useGenieDispatch();
  const dalleParams: DalleParams = params.allModelsParams[
    params.selectedModel
  ] as DalleParams;

  return (
    <ParamsGrid>
      <LabeledParam label={<Description title="Guidance" />}>
        <Slider
          value={dalleParams.cond_scale}
          min={1.0}
          max={12.0}
          step={0.1}
          onChange={(e) =>
            genieDispatch({ type: "setCondScale", condScale: e })
          }
        />
      </LabeledParam>
    </ParamsGrid>
  );
};
