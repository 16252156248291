import { NextPage } from "next";
import { Grid, Text, User, useTheme } from "sparkl-ui";
import Link from "next/link";
import { nameToModelTagInfo } from "../selectors/model_tags";
import { Model } from "../../utils/feed_types";
import useSWR from "swr";
import { AvatarFailure, AvatarSuccess } from "../../pages/api/user/[id]/avatar";
import { fetcher } from "../../utils/swr";
import Avatar from "../avatar";

export interface BylineProps {
  username?: string;
  modelName: string;
  userId: number;
}

export const Byline: NextPage<BylineProps> = ({
  username,
  modelName,
  userId,
}) => {
  const modelInfo = nameToModelTagInfo[modelName as Model] ?? null;
  const fallbackModelInfo = nameToModelTagInfo["verdant"];
  const theme = useTheme();

  const { data, error } = useSWR<AvatarSuccess, AvatarFailure>(
    `/api/user/${userId}/avatar`,
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
    }
  );

  const avatarSrc = data?.src ?? "/images/default_user.png";

  return (
    <Grid.Container
      gap={0}
      justify="space-between"
      alignItems="stretch"
      alignContent="stretch"
      width={"100%"}
      height={"100%"}
    >
      <Grid
        paddingTop={"0.5rem"}
        paddingBottom={"0.2rem"}
        style={{ width: "60%" }}
      >
        {username !== "bot" && (
          <Link href={`/profile/${username}`}>
            <a>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  flexDirection: "row",
                  color: theme.palette.foreground,
                }}
              >
                <Avatar src={avatarSrc} alt={"Avatar for " + username} />
                <Text style={{ marginLeft: "0.5rem" }} b font="14px">
                  {username}
                </Text>
              </div>
            </a>
          </Link>
        )}
      </Grid>
      <Grid
        height="100%"
        paddingTop={"0.5rem"}
        paddingBottom={"0.2rem"}
        paddingRight={"0.5rem"}
        style={{ width: "40%", display: "flex", justifyContent: "flex-end" }}
      >
        <Avatar
          alt={"Avatar for " + modelInfo.name}
          src={modelInfo ? modelInfo.avatar.src : fallbackModelInfo.avatar.src}
          style={{
            borderRadius: "50%",
            border: `1px solid ${modelInfo.color}`,
          }}
        />
      </Grid>
    </Grid.Container>
  );
};
