import Menu from "@geist-ui/icons/menu";
import { NextPage } from "next";
import { useSession } from "next-auth/react";
import NextLink from "next/link";
import { useState } from "react";
import { Button, Drawer, Image, Link, Text, useMediaQuery } from "sparkl-ui";
import { ALLOW_DEV, dev_maybeMockSession } from "../../../utils/devtools";
import { useGenie } from "../../genie/use_genie";
import { useLoginModal } from "../../modals/login_modal";
import { useUpsellModal } from "../../modals/upsell_modal";
import { HeadsUpDisplay } from "./hud";
import { SparklLogo } from "./sparkl_logo";
export interface NavbarProps {
  style?: React.CSSProperties;
}

export const Navbar: NextPage<NavbarProps> = ({ style }) => {
  const { toggleUpsell, UpsellModal } = useUpsellModal();
  const session = dev_maybeMockSession(useSession());
  const [drawerOpen, setDrawerOpen] = useState(false);
  const genieParams = useGenie();
  const { toggleLogin, LoginModal } = useLoginModal({
    callbackUrl:
      genieParams.sharedParams.prompts[0].text != ""
        ? "/?text=" + genieParams.sharedParams.prompts[0].text
        : undefined,
    clearUI: () => setDrawerOpen(false),
  });

  let upMD = useMediaQuery("md", { match: "up" });
  // fix SSR
  // https://github.com/geist-org/geist-ui/issues/258
  if (typeof window === "undefined") {
    upMD = true;
  }

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        height: "100%",
        width: "100%",
        paddingRight: "0 1.5rem",
        zIndex: 1,
        ...style,
      }}
    >
      {/* Modals that don't actually show up on the page */}
      <UpsellModal />
      {!upMD && (
        <Drawer
          width="62vw"
          placement="right"
          visible={drawerOpen}
          onClose={() => {
            setDrawerOpen(false);
          }}
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <HeadsUpDisplay toggleLogin={toggleLogin} />
        </Drawer>
      )}
      {/* Logo and HUD are stretched across the flexbox */}
      <NextLink href="/" passHref>
        <Link href="/">
          <SparklLogo />
        </Link>
      </NextLink>
      {upMD && <HeadsUpDisplay toggleLogin={toggleLogin} />}
      {!upMD && (
        <Button
          onClick={() => setDrawerOpen(true)}
          scale={0.62}
          auto
          type="abort"
          icon={<Menu />}
        />
      )}
      <LoginModal />
    </div>
  );
};
