// This file is built to contain non-secret config info, that doesn't need to be checked
// into the secret manager.

type Env = "dev" | "prod";

// To change which config is active, just change the following line
// TODO: maybe this checks for dev/prod in process.ENV
// and falls back to this string iff not?
const currentEnv: Env = "prod";

interface Config {
  /*
    Enables devtools, which adds a button to the homepage that allows you to do all kinds of things
    ALSO: Makes everything really really insecure, and allows you to just claim to be 
    a user via a cookie
    */
  allowDev: boolean;

  /*
    Restrict the website to users with certain roles
    */
  restrictWebsite: boolean;

  /* 
    Keeps in paramUI pieces that we've not quite got working yet...
    */
  allowNonfunctionalUI: boolean;

  /* 
    Subscription price ID 
  */
  subscriptionPriceId: string;

}

const devConfig: Config = {
  allowDev: true,
  restrictWebsite: false,
  allowNonfunctionalUI: false,
  subscriptionPriceId: "price_1LJg85DdKMl1S7gsRisFniYy"
};
const prodConfig: Config = {
  allowDev: false,
  restrictWebsite: false,
  allowNonfunctionalUI: false,
  subscriptionPriceId: "price_1LnuLvDdKMl1S7gs7KU43n1W"
};

const configs: Record<Env, Config> = {
  dev: devConfig,
  prod: prodConfig,
};

export const config = configs[currentEnv];
