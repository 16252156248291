import { NextPage } from "next";
import { useRouter } from "next/router";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Button, useTheme } from "sparkl-ui";

// This is the navigation for the mobile version of the app.
// It is a tab bar at the bottom of the viewport that creates one tab for each of its children
// and switches between them.

// The state is held in a context provider, so the app layout can hold the tab state.
// This is useful for when the user navigates to a page with a specific tab selected.
// The tabs are not rerendered when the selected tab changes, so that the state of the tab is preserved.
// The tabs are just hidden with the CSS feature `display: none`.

export type TabScrollStates = Record<string, number>;
export type TabState = { selectedTab: string; scrollStates: TabScrollStates };

export const defaultTabState: TabState = {
  selectedTab: "0",
  scrollStates: {},
};

export const scrollReducer = (
  state: TabState,
  action: { type: string; tab: string; scroll: number }
) => {
  switch (action.type) {
    case "switchTab":
      const currentTab = state.selectedTab;
      return {
        ...state,
        selectedTab: action.tab,
        scrollStates: {
          ...state.scrollStates,
          [currentTab]: action.scroll,
        },
      };
    default:
      return state;
  }
};
// This part is the actual tabnav component. The above reducer is called
// in the Layout component and passed down as props

export interface TabChild {
  label: JSX.Element;
  content: React.ReactNode;
  route?: string;
  onSecondClick?: () => void;
}

export interface TabNavProps {
  children: TabChild[];
}

export const TabNav: NextPage<TabNavProps> = ({ children }) => {
  const tabState = useTabNav();
  const tabDispatch = useTabDispatch();
  useEffect(() => {
    const currentTab = tabState.selectedTab;
    const currentScroll = tabState.scrollStates[currentTab];
    if (currentScroll) {
      // console.log("scrolling to", currentScroll);
      window.scrollTo(0, currentScroll);
    }
  }, [tabState.selectedTab, tabState.scrollStates]);

  const switchTab = (tab: string) => {
    // console.log(tab);
    tabDispatch({ type: "switchTab", tab, scroll: window.scrollY });
  };
  const theme = useTheme();
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: "1fr 4rem",
        gridTemplateAreas: `"content" "tab-nav"`,
        width: "100vw",
        backgroundColor: theme.palette.accents_1,
      }}
    >
      <header
        style={{
          gridArea: "tab-nav",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "4rem",
          width: "100%",
          backgroundColor: theme.palette.background,
          borderTop: `1px solid ${theme.palette.accents_2}`,
          position: "fixed",
          bottom: 0,
          zIndex: 3,
        }}
      >
        {children &&
          children.map((child, index) => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                cursor: "pointer",
              }}
              key={index}
              onClick={(e) => {
                if (tabState.selectedTab !== index.toString()) {
                  switchTab(index.toString());
                } else {
                  if (child.onSecondClick) {
                    child.onSecondClick();
                  }
                }
              }}
            >
              <Button
                auto
                type="abort"
                style={{
                  color:
                    tabState.selectedTab === index.toString()
                      ? theme.palette.success
                      : theme.palette.accents_5,
                }}
              >
                {child.label}
              </Button>
            </div>
          ))}
      </header>
      <div style={{ gridArea: "content", overflow: "clip" }}>
        {children &&
          children.map((child, index) => (
            <div
              key={index}
              id={`tab-${index}`}
              style={{
                display:
                  tabState.selectedTab === index.toString() ? "flex" : "none",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              {child.content}
            </div>
          ))}
      </div>
    </div>
  );
};

const TabNavContext = createContext(defaultTabState);
const TabDispatchContext = createContext(
  (action: { type: string; tab: string; scroll: number }) => {}
);

export function TabNavProvider({ children }: { children: React.ReactNode }) {
  const [tabState, tabDispatch] = useReducer(scrollReducer, defaultTabState);
  return (
    <TabNavContext.Provider value={tabState}>
      <TabDispatchContext.Provider value={tabDispatch}>
        {children}
      </TabDispatchContext.Provider>
    </TabNavContext.Provider>
  );
}

export function useTabNav() {
  return useContext(TabNavContext);
}

export function useTabDispatch() {
  return useContext(TabDispatchContext);
}
