import React from "react";
import Image from "next/image";
import { Description, Grid, Text } from "sparkl-ui";
import { useGenieDispatch } from "../use_genie";
import { InfoPopup } from "./info_popup";
import { LabeledParam } from "./labeled_param";
import { Slider } from "./slider";
import a from "../../../public/images/examples/steps/03.png";
import b from "../../../public/images/examples/steps/20.png";
import c from "../../../public/images/examples/steps/50.png";
import d from "../../../public/images/examples/steps/75.png";

export interface StepsParamProps {
  steps: number;
}

export const StepsParam: React.FC<StepsParamProps> = ({ steps }) => {
  //const [aspectRatio, setAspectRatio] = useState<AspectRatio>(params.aspect_ratio as AspectRatio);
  const genieDispatch = useGenieDispatch();

  let imgSrcs = [a, b, c, d];
  let values = [3, 20, 50, 75];

  // The following four images are generated with the same prompt and seed,
  // but ascending steps:
  const descriptionContent = (
    <Grid.Container direction="column">
      <Text>
        Use fewer steps to try things out quickly, and more steps to expand upon
        something you like and get more details in!
      </Text>
      <Text>
        {'"A tiger in a beautiful forest at sunset" with the same seed and 3, 30, 50, or 75 steps:'}
      </Text>
      <Grid>
        <Grid.Container gap={0.3}>
          {imgSrcs.map((src, i) => {
            return (
              <Grid xs={12} key={i} justify="space-around">
                <Image
                  width="160px"
                  height="160px"
                  src={src}
                  style={{ borderRadius: "3px" }}
                  alt={`'a tiger in a beautiful forest at sunset' with ${values[i]} steps`}
                  placeholder="blur"
                />
              </Grid>
            );
          })}
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );

  return (
    <LabeledParam
      label={
        <>
          <Description title={"Steps"} />
          <InfoPopup content={descriptionContent} />
        </>
      }
    >
      <Slider
        min={1}
        max={100}
        value={steps}
        onChange={(e) => genieDispatch({ type: "setSteps", steps: e })}
      />
    </LabeledParam>
  );
};
