import { signOut, useSession } from "next-auth/react";
import NextLink from "next/link";
import { NextPage } from "next";
import { Button, Popover, User } from "sparkl-ui";
import { dev_maybeMockSession } from "../utils/devtools";
import { AvatarSuccess } from "../pages/api/user/[id]/avatar";
import useSWR from "swr";
import { fetcher } from "../utils/swr";
import Avatar from "./avatar";
interface LoginProps {
  toggleLogin: () => void;
}

export const Login: NextPage<LoginProps> = ({ toggleLogin }) => {
  const session = dev_maybeMockSession(useSession());

  const { data } = useSWR<AvatarSuccess>(
    () => {
      if (!session || !session?.user_id) {
        return null;
      }
      return `/api/user/${session?.user_id}/avatar`;
    },
    fetcher,
    {
      revalidateOnFocus: false,
      revalidateOnReconnect: false,
      fallbackData: { src: session?.picture ?? "", status: "success" },
    }
  );

  if (session) {
    return (
      <div
        style={{
          alignSelf: "stretch",
        }}
      >
        {" "}
        <NextLink href="/profile?tab=recents">
          {/* causes errors if we don't surround with <a> https://stackoverflow.com/a/72166785 */}
          <a>
            <Avatar
              alt="Profile"
              src={!data ? undefined : data.src ?? "/images/default_user.png"}
              scale={2}
              text={session.username}
            />
          </a>
        </NextLink>{" "}
      </div>
    );
  }
  return (
    <div
      style={{
        alignSelf: "center",
      }}
    >
      <Button type="success" onClick={() => toggleLogin()}>
        Sign in
      </Button>
    </div>
  );
};
