import React from "react";
import { GuidanceScaleParam } from "../generator_subcomponents/condition_scale";
import { ImageSizeParam } from "../generator_subcomponents/image_size";
import {
  NumImages,
  NumImagesParam,
} from "../generator_subcomponents/num_images";
import { ParamsGrid } from "../generator_subcomponents/params_grid";
import { SeedParam } from "../generator_subcomponents/seed";
import { useGenie } from "../use_genie";
import { LatentParams, VQGANParams } from "./model_generation_component_types";

export const Diffusion: React.FC = () => {
  // Individual model components like this one should grab params and pass down
  // primitive types to the subcomponents. Subcomponents are responsible for
  // dispatching actions to the store.
  // Model specifics can be found at
  // https://github.com/dryadsystems/sprkpnt/blob/main/api/params.md#diffuse

  const params = useGenie();
  const diffuseParams = params.allModelsParams.diffuse as LatentParams;

  return (
    <ParamsGrid>
      <NumImagesParam num={diffuseParams.num_images} />
      <ImageSizeParam
        width={diffuseParams.width}
        height={diffuseParams.height}
      />
      <SeedParam seed={diffuseParams.seed} />
      <GuidanceScaleParam scale={diffuseParams.scale} />
    </ParamsGrid>
  );
};
