import { NextPage } from "next";
import { CSSProperties } from "react";
import { Button, Modal, useClipboard, useModal, useToasts } from "sparkl-ui";
import { FinishedPrompt } from "../../utils/prompt_types";
import { Share } from "../icons";
import { UserReactions } from "../reactions";
import { RemixButton } from "./remix_button";
export interface ButtonBarProps {
  prompt: FinishedPrompt;
  lightboxIndex?: number;
}

export const ButtonBar: NextPage<ButtonBarProps> = ({
  prompt,
  lightboxIndex,
}) => {
  const { visible, setVisible, bindings } = useModal();
  const { copy } = useClipboard();
  const { setToast } = useToasts();

  async function shareHandler() {
    const shareData = {
      title: "Look at this generation on Sparkl",
      text: prompt.params.sharedParams.prompts
        .map((prompt) => prompt.text)
        .join(", "),
      url: `${window.location.origin}/prompt/${prompt.id}`,
    };
    if (
      typeof navigator.canShare == "function" &&
      navigator.canShare(shareData)
    ) {
      try {
        //console.log("Trying to share");
        await navigator.share(shareData);
        return;
      } catch (err) {
        //The error is mostly an AbortError when the user decides not to share
        console.log(err);
      }
    } else {
      copy(`${window.location.origin}/prompt/${prompt.id}`);
      setToast({ text: "Link copied to clipboard", type: "success" });
      //console.log("Couldn't share at all");
    }
  }
  const buttonStyle: CSSProperties = {
    borderLeft: "0",
    width: "100%",
    height: "100%",
    margin: "0",
    zIndex: "0",
  };

  return (
    <>
      {/* Reactions button*/}
      <UserReactions
        prompt={prompt}
        reaction={"like"}
        buttonStyle={buttonStyle}
      />
      {/* Remix button */}
      <RemixButton prompt={prompt} style={buttonStyle} />

      {/* Copy link button */}
      <Button
        style={buttonStyle}
        onClick={shareHandler}
        className="reactionButton"
        title="Share"
      >
        <Share />
      </Button>

      {/* Remixes button */}
      {"remixes" in prompt && prompt.remixes && prompt.remixes.length > 0 && (
        <>
          <Button style={buttonStyle} onClick={() => setVisible(true)}>
            {prompt.remixes.length} other remix
            {prompt.remixes.length > 1 ? "es" : ""}
          </Button>
          <Modal {...bindings}>
            <>
              {prompt.remixes.map((remix) => (
                <div key={remix}>
                  {" "}
                  this should have promptid {remix} but this is in the middle of
                  a refactor{" "}
                </div>
              ))}
            </>
          </Modal>
        </>
      )}
    </>
  );
};

export const AdminButtonBar: NextPage<ButtonBarProps> = ({ prompt }) => {
  return (
    <>
      {/* Reactions button*/}
      <UserReactions prompt={prompt} reaction={"masterpiece"} />
      <UserReactions prompt={prompt} reaction={"curated"} />
      <UserReactions prompt={prompt} reaction={"great"} />
      <UserReactions prompt={prompt} reaction={"okay"} />
      <UserReactions prompt={prompt} reaction={"bad"} />
      <UserReactions prompt={prompt} reaction={"tos"} />
    </>
  );
};
