import React from "react";
import { Text, useTheme } from "sparkl-ui";
import { Range, getTrackBackground } from "react-range";

export interface SliderProps {
  value: number;
  onChange: (value: number) => void;
  min?: number;
  max?: number;
  step?: number;
  id?: string;
  width?: number | string;
  hideValue?: boolean;
  style?: React.CSSProperties;
}

export const Slider: React.FC<SliderProps> = ({
  value,
  onChange,
  min = 0,
  max = 100,
  step = 1,
  style = {},
  hideValue = false,
}) => {
  const theme = useTheme();
  const decimals = step.toString().split(".")[1]?.length || 0;
  return (
    <div
      style={{
        ...style,
        display: "flex",
        justifyContent: "center",
        flexWrap: "wrap",
        width: "100%",
      }}
    >
      <Range
        values={[value]}
        step={step}
        min={min}
        max={max}
        onChange={(values) => onChange(values[0])}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "0.5rem",
                width: "100%",
                borderRadius: "0.5rem",
                background: getTrackBackground({
                  values: [value],
                  colors: [theme.palette.secondary, theme.palette.accents_5],
                  min: min,
                  max: max,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              borderRadius: "1rem",
              width: "max-content",
              padding: "0.12rem",
              backgroundColor: theme.palette.success,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              color: theme.palette.accents_1,
              outlineColor: theme.palette.success,
            }}
          >
            {hideValue ? (
              <Text width={"1.39rem"} px={"0.12rem"} height="1rem" small></Text>
            ) : (
              <Text px={"0.12rem"} b small font={"0.75rem"}>
                {value.toFixed(decimals)}
              </Text>
            )}
          </div>
        )}
      />
    </div>
  );
};
