import {
  SingleModelParams,
  VerdantParams,
} from "../components/genie/model_generators/model_generation_component_types";

export function timeEstimate(m: SingleModelParams): string {
  const t = _timeEstimate(m);
  if (t < 60) {
    return `${Math.round(t)} seconds`;
  } else if (t < 60 * 60) {
    const m = `${Math.round((t + 20) / 60)}`;
    return m !== "1" ? `${m} minutes` : `${m} minute`;
  } else {
    const h = `${Math.round(t / 3600)}`;
    return h !== "1" ? `${h} hours` : `${h} hour`;
  }
}

function _timeEstimate(m: SingleModelParams): number {
  switch (m.model) {
    case "dalle":
      return 30;
    case "vqgan":
      return 100;
    case "diffuse":
      // We expect this to always be cold
      return 20 + 10 * (m.modelParams.num_images ?? 1);
    case "verdant":
    case "waifu":
    case "verdant2": //incorrect
      return 4 + 6 * verdantCreditCost(m);
  }
}

export function creditCost(m: SingleModelParams): number {
  switch (m.model) {
    case "dalle":
      return 1;
    case "vqgan":
      return 10;
    case "diffuse":
      return latentCreditCost(m);
    case "verdant":
    case "verdant2":
    case "waifu":
      return verdantCreditCost(m);
  }
}

function latentCreditCost(m: SingleModelParams): number {
  return 2 * (m.modelParams.num_images ?? 1);
}

function verdantCreditCost(m: SingleModelParams): number {
  let verdantParams = m.modelParams as VerdantParams;
  let cost = 1;
  if (
    (verdantParams.width == 768 && verdantParams.height == 576) ||
    (verdantParams.width == 576 && verdantParams.height == 768)
  ) {
    cost = 2;
  }
  cost *= verdantParams.num_images ?? 1;
  cost *= verdantParams.ddim_steps / 50;

  return Math.round(cost * 10) / 10;
}
