import { NextPage } from "next";
import { useSession } from "next-auth/react";
import { useState } from "react";
import { Collapse, Text, Toggle, useToasts } from "sparkl-ui";
import { mutate } from "swr";
import { creditCost } from "../../utils/credit_calculation";
import { dev_maybeMockSession, dev_mockUserHeader } from "../../utils/devtools";
import { Model } from "../../utils/feed_types";
import { GenerateParams, GenerateResponse } from "../../utils/prompt_types";
import { mutateQueue } from "../../utils/swr";
import { useLoginModal } from "../modals/login_modal";
import { JobStatus } from "./generate_button";
import { PromptBox } from "./generator_subcomponents/promptbox";
import { SmartButton } from "./generator_subcomponents/smart_button";
import { DalleMini } from "./model_generators/dalle_mini";
import { Diffusion } from "./model_generators/diffusion";
import { GeneratorCard } from "./model_generators/generator_card";
import { Verdant } from "./model_generators/verdant";
import { Verdant2 } from "./model_generators/verdant2";
import { Vqgan } from "./model_generators/vqgan";
import { Waifu } from "./model_generators/waifu";
import { InitImageParam } from "./generator_subcomponents/init_image";
import { useGenie, useGenieDispatch } from "./use_genie";
import { useNoCreditsModal } from "../modals/no_credits_modal";
import { ButtonToggle } from "./generator_subcomponents/button_toggle";
import Eye from "@geist-ui/icons/eye";
import EyeOff from "@geist-ui/icons/eyeOff";

type ModelOrNothing = Model | "";
const modelsToComponent: Record<ModelOrNothing, React.FC> = {
  dalle: DalleMini,
  diffuse: Diffusion,
  //disco: Disco,
  vqgan: Vqgan,
  verdant: Verdant,
  waifu: Waifu,
  verdant2: Verdant2,
  "": () => <></>,
};

// The generator layout has five main features:
// - a Promptbox (someday to be a Magic Promptbox with tags and autosuggest)
// - a GeneratorBar that decides which controls are shown
// - a control panel for setting API params
// - a Collapse that hides some portion of the control panel
// - a Generate button that displays info and sends the API call

// These are organized in hierarchical need for information.
// Features lower down rely on information from features above.
// We may present them in a different order than they are in the code.

// Each feature should take care of its own internal layout
// and expect to grow or shrink in both dimensions.
// They will all stack vertically in a GeneratorCard which will take care
// of layout and be called from this file.
// The GeneratorCard will try to fit the shape of the screen, so needs to be responsive
// and control the layout of the other components.

export interface GeneratorProps {
  fixedModel?: Model;
}

export const Generator: NextPage<GeneratorProps> = ({ fixedModel }) => {
  const genieParams = useGenie();
  const genieDispatch = useGenieDispatch();
  const { setToast } = useToasts();
  const session = dev_maybeMockSession(useSession());
  const { toggleLogin, LoginModal } = useLoginModal({
    callbackUrl:
      genieParams.sharedParams.prompts[0].text != ""
        ? "/?text=" + genieParams.sharedParams.prompts[0].text
        : undefined,
  });

  const { toggleModal, NoCreditsModal } = useNoCreditsModal();

  const [submitStatus, setSubmitStatus] = useState<JobStatus>("not_started");

  const handleSubmit = async () => {
    // Don't generate if prompt is empty
    if (genieParams.sharedParams.prompts[0].text === "") {
      return;
    }

    // This variable is here to prevent issues in case genieParams is changed
    // while the request to generate a prompt is inflight
    const params = {
      modelParams: genieParams.allModelsParams[genieParams.selectedModel],
      sharedParams: genieParams.sharedParams,
      model: genieParams.selectedModel,
      hidden: genieParams.sharedParams.hidden,
    };
    // @ts-ignore
    const body: GenerateParams = { params };

    if (!session) {
      toggleLogin();
      return;
    }

    if (genieParams.remixPromptId) {
      body.originalPromptId = genieParams.remixPromptId;
    }

    setSubmitStatus("in_progress");

    await fetch(`/api/generate`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: dev_mockUserHeader(),
    })
      .then((r) => r.json())
      .then((resp: GenerateResponse) => {
        if (resp.status === "success") {
          mutateQueue({
            id: resp.prompt_id,
            params: params,
            status: "pending",
            date: Date.now() / 1000,
            user_id: resp.user_id,
            remix_of: genieParams.remixPromptId,
          });
          mutate(
            (key) => key == "/api/balance",
            (data) => {
              return {
                ...data,
                balance: data.balance - creditCost(params),
              };
            },
            false
          );
          setSubmitStatus("submitted");
          setTimeout(() => {
            setSubmitStatus("not_started");
          }, 1000);
        } else {
          if (resp.code === "insufficent_credits") {
            toggleModal();
          } else {
            setToast({ text: resp.code, type: "error" });
          }
          setSubmitStatus("not_started");
        }
        //console.log("response from generate API call is", resp);
      });
  };

  let Component = modelsToComponent[genieParams.selectedModel];

  return (
    <div
      style={{
        width: "100%",
        height: "fit-content",
        overflowX: "clip",
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
      }}
    >
      <NoCreditsModal />
      <LoginModal />
      <GeneratorCard id="generator-card">
        <PromptBox />
        <InitImageParam />
        <Collapse
          title=" "
          initialVisible={genieParams.viewProps.expanded}
          style={{
            width: "100%",
            border: "none",
            height: "100%",
            paddingTop: ".62rem",
            paddingBottom: ".62rem",
          }}
          onClick={() =>
            genieDispatch({
              type: "setExpanded",
              expanded: !genieParams.viewProps.expanded,
            })
          }
        >
          <Component />
        </Collapse>
      </GeneratorCard>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          gap: "0.38rem",
          placeContent: "center",
          placeItems: "center",
          height: "100%",
        }}
      >
        {/* Private/public button */}
        <ButtonToggle
          value={genieParams.sharedParams.hidden ? true : false}
          callback={() => genieDispatch({ type: "toggleHidden" })}
          icon={genieParams.sharedParams.hidden ? <EyeOff /> : <Eye />}
          style={{
            width: "4rem",
            height: "4rem",
            border: "none",
            borderColor: "none",
            borderTop: "none",
            borderBottom: "none",
            borderLeft: "none",
            borderRight: "none",
            boxShadow: "none",
          }}
        />
        {/* Generate button */}
        <SmartButton onClick={handleSubmit} status={submitStatus} />
      </div>
    </div>
  );
};
