import { useSession } from "next-auth/react";

import { NextPage } from "next/types";
import { useContext, useLayoutEffect, useRef, useState } from "react";
import useSWR from "swr";
import { dev_maybeMockSession } from "../../../utils/devtools";
import { PromptsFailure, PromptsSuccess } from "../../../utils/prompt_types";
import { fetcher } from "../../../utils/swr";
import { useSize } from "../../../utils/use_size";
import { LightboxContext } from "../../prompt_image/lightbox_context";
import { ProfileLink } from "../profile_link";
import { QueueItem } from "./queue_item";

export const RecentsGrid: NextPage = () => {
  const initialRef = useRef(null);
  const { ref, size } = useSize({ initialRef: initialRef });
  const session = dev_maybeMockSession(useSession());
  const [numImages, setNumImages] = useState(0);
  const { openWithLightbox } = useContext(LightboxContext);

  useLayoutEffect(() => {
    setNumImages(size ? Math.floor(size.width / 102) - 1 : 3);
  }, [size, numImages]);

  const { data, error } = useSWR<PromptsSuccess, PromptsFailure>(
    () =>
      session
        ? `/api/prompts?user_id=${session.user_id}&limit=${9}&queue=true`
        : null,
    fetcher,
    {
      refreshInterval: (data) => {
        // console.log("refreshInterval data is", data);
        if (!data) {
          // console.log("refreshInterval data is", data);
          return 500;
        }
        // console.log("refreshInterval data is finally not none!!!");
        // Need to understand what causes a failure better - as this seems to happen often
        // @ts-ignore
        if (data.status === "failure") {
          console.log("failure, waiting 10s:", data);
          return 10 * 1000;
        }
        //check if any prompt has a status of "in progress"
        const promptsInProgress = data.prompts.some(
          (prompt) =>
            prompt.status === "uploading" ||
            prompt.status === "assigned" ||
            prompt.status === "pending"
        );
        //console.log("Prompts in progress: ", promptsInProgress);
        // TODO: We should perhaps stop polling every 0.5s after a certain amount of time
        // Maybe like 2 minutes or so?
        // Need to mostly figure out/test how date works on prompts
        const wait = promptsInProgress ? 500 : 180 * 1000;
        // console.log(`waiting ${wait}`);
        return wait;
      },
      // Need to see if the 500ms will actually work
      // These defaults might stop it:
      dedupingInterval: 500,
      focusThrottleInterval: 500,
    }
  );

  if (error || !data || !data.prompts) {
    //console.log("data is", data);
    //console.log("error", error);
    return null;
  } else {
    //console.log("data", data);
    return (
      <div
        ref={ref}
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fit, 6rem)",
          gridTemplateRows: "repeat(auto-fit, 6rem)",
          gap: "0.5rem",
          paddingTop: "1rem",
          width: "100%",
          maxWidth: "100%",
          placeContent: "center",
          placeItems: "center",
          alignmentBaseline: "middle",
        }}
      >
        {data.prompts.slice(0, numImages).map((prompt, index) => {
          const maybe_blur = (prompt as any).outputs?.blurhashes?.[0];
          const maybe_image_url = (prompt as any).outputs?.image_urls?.[0];
          const openLightboxCallback = openWithLightbox(prompt, 0);
          return (
            <QueueItem
              status={prompt.status}
              maybe_blur={maybe_blur}
              maybe_image_url={maybe_image_url}
              key={prompt.id}
              openLightboxCallback={openLightboxCallback}
            />
          );
        })}
        <div
          style={{
            minHeight: "96px",
            display: "flex",
            flexDirection: "column",
            placeContent: "center",
            placeItems: "center",
          }}
        >
          {data.prompts.length >= numImages && <ProfileLink />}
        </div>
      </div>
    );
  }
};
