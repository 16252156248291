import { NextPage } from "next";
import { ButtonGroup, Text, useTheme } from "sparkl-ui";
import { FinishedPrompt } from "../../utils/prompt_types";
import { AdminButtonBar, ButtonBar } from "./button_bar";
import { Byline } from "./byline";

export interface TombstoneProps {
  prompt: FinishedPrompt;
  promptText: string;
  hasByline?: boolean;
  admin?: boolean; // if true, show the admin buttons
  lightboxIndex?: number; // use this to show image-specific seeds
}

export const Tombstone: NextPage<TombstoneProps> = ({
  prompt,
  promptText,
  hasByline = false,
  admin = false,
  lightboxIndex,
}) => {
  const theme = useTheme();

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
        height: "100%",
        overflowX: "hidden",
      }}
    >
      {hasByline ? (
        <Byline
          username={prompt.username}
          modelName={prompt.params.model}
          userId={prompt.user_id}
        />
      ) : (
        ""
      )}
      <Text
        p
        font={"0.82rem"}
        px={"1.18rem"}
        style={
          lightboxIndex === undefined
            ? {
                display: "-webkit-box",
                WebkitLineClamp: 3,
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
              }
            : { overflow: "visible" }
        }
      >
        {/* {promptText && promptText.length > 150
            ? promptText.slice(0, 157) + "..." */}
        {promptText}
      </Text>
      <ButtonGroup
        type="success"
        ghost
        margin={0}
        height={"3rem"}
        width={"100%"}
        style={{
          color: `${theme.palette.success}`,
          justifyContent: "space-evenly",
          border: "unset",
        }}
      >
        {admin ? (
          <AdminButtonBar prompt={prompt} />
        ) : (
          <ButtonBar prompt={prompt} lightboxIndex={lightboxIndex} />
        )}
      </ButtonGroup>
    </div>
  );
};
