import { type } from "os";
import { img2img_models, Model } from "../../utils/feed_types";
import {
  InitImageParams,
  Mode,
} from "./model_generators/model_generation_component_types";
import {
  defaultParams,
  SharedParams,
  SingleModelParams,
  defaultSharedParams,
  AllModelsParams,
  ModelParams,
} from "./model_generators/model_generation_component_types";

// Types and default values
export interface GeneratorViewProps {
  mode: Mode;
  expanded: boolean;
}

export interface GenieParams {
  selectedModel: Model;
  sharedParams: SharedParams;
  allModelsParams: AllModelsParams;
  remixPromptId?: number;
  viewProps: GeneratorViewProps;
}

export const defaultGenieParams: GenieParams = {
  selectedModel: "verdant",
  sharedParams: defaultSharedParams,
  allModelsParams: defaultParams,
  remixPromptId: undefined,
  viewProps: {
    mode: "txt2img",
    expanded: false,
  },
};

export const getNewGenieParams = () => {
  return defaultGenieParams;
};

export const getSingleModelParams = (
  params: GenieParams
): SingleModelParams => {
  return {
    model: params.selectedModel,
    sharedParams: params.sharedParams,
    modelParams: params.allModelsParams[params.selectedModel],
    remixPromptId: params.remixPromptId,
  };
};

// Utility functions
const setModelParams = (params: GenieParams, model: Model, newParams: {}) => {
  return {
    ...params,
    allModelsParams: {
      ...params.allModelsParams,
      [params.selectedModel]: {
        ...params.allModelsParams[params.selectedModel],
        ...newParams,
      },
    },
  };
};

const getModeFromParams = (params: GenieParams): Mode => {
  if (img2img_models.includes(params.selectedModel)) {
    if ("init_image" in params.allModelsParams[params.selectedModel]) {
      if (
        (params.allModelsParams[params.selectedModel] as InitImageParams)
          .init_image !== undefined
      ) {
        return "img2img";
      }
    } else {
      return "txt2img";
    }
  }
  return "txt2img";
};

// Strong typing of possible actions
export type GenieAction =
  | { type: "switchModel"; model: Model }
  | { type: "setMode"; mode: Mode }
  | { type: "setExpanded"; expanded: boolean }
  | { type: "setSharedParams"; sharedParams: SharedParams }
  | { type: "setText"; text: string }
  | { type: "setNegativeText"; text: string }
  | {
      type: "remix";
      model: Model;
      modelSpecificParams: ModelParams;
      sharedParams: SharedParams;
      remixPromptId?: number;
    }
  | { type: "remixSetMode" }
  | {
      type: "setCondScale";
      condScale: number;
    }
  | {
      type: "setSteps";
      steps: number;
    }
  | {
      type: "setSeed";
      seed: number;
    }
  | {
      type: "setScale";
      scale: number;
    }
  | {
      type: "setImageSize";
      width: number;
      height: number;
    }
  | {
      type: "setNumImages";
      numImages: number;
    }
  | {
      type: "setReactionLoss";
      reactionLoss: number;
    }
  | {
      type: "setCutPow";
      cutPow: number;
    }
  | {
      type: "setInitImageURL";
      initImageURL: string | undefined;
    }
  | {
      type: "setInitImageType";
      initImageType: string;
    }
  | {
      type: "setInitImageMask";
      initImageMask: string;
    }
  | {
      type: "setInitImageStrength";
      initImageStrength: number;
    }
  | {
      type: "reset";
    }
  | {
      type: "toggleHidden";
    };

// What those actions actually do. They all take a GenieParams and return a new
// GenieParams with some transformation
export const genieReducer = (params: GenieParams, action: GenieAction) => {
  // console.log("genieReducer", action);
  switch (action.type) {
    case "switchModel": {
      return {
        ...params,
        selectedModel: action.model,
      };
    }
    case "setMode": {
      return {
        ...params,
        viewProps: {
          ...params.viewProps,
          mode: action.mode,
        },
      };
    }
    case "setExpanded": {
      return {
        ...params,
        viewProps: {
          ...params.viewProps,
          expanded: action.expanded,
        },
      };
    }
    case "setSharedParams": {
      return {
        ...params,
        sharedParams: action.sharedParams,
      };
    }
    case "setText": {
      return {
        ...params,
        sharedParams: {
          ...params.sharedParams,
          prompts: [
            {
              text: action.text,
              weight: params.sharedParams.prompts[0].weight,
            },
          ],
        },
      };
    }
    case "setNegativeText": {
      return setModelParams(params, params.selectedModel, {
        negative_prompts: {text: action.text},
      });
    }
    case "remix": {
      return {
        ...params,
        remixPromptId: action.remixPromptId,
        selectedModel: action.model,
        sharedParams: action.sharedParams,
        allModelsParams: {
          ...params.allModelsParams,
          [action.model]: action.modelSpecificParams,
        },
      };
    }
    case "remixSetMode": {
      return {
        ...params,
        viewProps: { ...params.viewProps, mode: getModeFromParams(params) },
      };
    }
    case "setInitImageURL": {
      // When we're setting this back to no init image, we need to reset the
      // size params to default
      const rest =
        typeof action.initImageURL === "undefined"
          ? defaultGenieParams.allModelsParams[params.selectedModel]
          : params.allModelsParams[params.selectedModel];
      return setModelParams(params, params.selectedModel, {
        ...rest,
        init_image: action.initImageURL,
      });
    }
    case "setInitImageType": {
      return setModelParams(params, params.selectedModel, {
        init_image_type: action.initImageType,
      });
    }
    case "setInitImageMask": {
      return setModelParams(params, params.selectedModel, {
        init_image_mask: action.initImageMask,
      });
    }
    case "setInitImageStrength": {
      return setModelParams(params, params.selectedModel, {
        strength: action.initImageStrength,
      });
    }
    case "setCondScale": {
      return setModelParams(params, params.selectedModel, {
        cond_scale: action.condScale,
      });
    }
    case "setSeed": {
      return setModelParams(params, params.selectedModel, {
        seed: action.seed,
      });
    }
    case "setScale": {
      return setModelParams(params, params.selectedModel, {
        scale: action.scale,
      });
    }
    case "setImageSize": {
      return setModelParams(params, params.selectedModel, {
        width: action.width,
        height: action.height,
      });
    }
    case "setNumImages": {
      return setModelParams(params, params.selectedModel, {
        num_images: action.numImages,
      });
    }
    case "setSteps": {
      return setModelParams(params, params.selectedModel, {
        ddim_steps: action.steps,
      });
    }
    case "setReactionLoss": {
      return setModelParams(params, params.selectedModel, {
        reaction_loss: action.reactionLoss,
      });
    }
    case "setCutPow": {
      return setModelParams(params, params.selectedModel, {
        cut_pow: action.cutPow,
      });
    }
    case "reset": {
      // return the default params
      return {
        ...defaultGenieParams,
      };
    }
    case "toggleHidden": {
      return {
        ...params,
        sharedParams: {
          ...params.sharedParams,
          hidden: !params.sharedParams.hidden,
        },
      };
    }
  }
};
