// Mostly this is copied from the Avatar in 'sparkl-ui'
// but we want to use NextImage at the heart of it
// so we can use the Next.js image optimizer
import React from "react";
import { useTheme, useScale, withScale, useClasses } from "sparkl-ui";
import Image from "next/image";
import { shimmerUrl } from "../utils/use_blur";

//need to expose any props we might send to the NextImage component
interface AvatarProps extends React.ComponentPropsWithoutRef<"img"> {
  src?: string;
  stacked?: boolean;
  text?: string;
  isSquare?: boolean;
  className?: string;
}

const defaultProps = {
  stacked: false,
  text: "",
  isSquare: false,
  className: "",
};

const safeText = (text: string): string => {
  if (text.length <= 4) return text;
  return text.slice(0, 3);
};

const AvatarComponent: React.FC<AvatarProps> = ({
  src,
  stacked = false,
  text = "",
  isSquare = false,
  className = "",
  ...props
}: AvatarProps) => {
  const theme = useTheme();
  const { SCALES } = useScale();
  const showText = !src;
  const radius = isSquare ? theme.layout.radius : "50%";
  const marginLeft = stacked ? SCALES.ml(-0.625) : SCALES.ml(0);
  const classes = useClasses("avatar", className);

  return (
    <span className={classes}>
      {!showText && (
        <Image
          src={src}
          placeholder="blur"
          blurDataURL={shimmerUrl(200, 200)}
          alt={"avatar"}
          loading="eager"
          layout="fill"
          style={{ cursor: "pointer" }}
        />
      )}
      {showText && (
        <span className="avatar-text" {...props}>
          {safeText(text)}
        </span>
      )}

      <style jsx>{`
        .avatar {
          display: inline-block;
          position: relative;
          overflow: hidden;
          border: 1px solid ${theme.palette.accents_2};
          border-radius: ${radius};
          vertical-align: top;
          background-color: ${theme.palette.background};
          box-sizing: border-box;
          width: ${SCALES.width(1.75) || SCALES.height(1.75)};
          height: ${SCALES.height(1.75) || SCALES.width(1.75)};
          padding: ${SCALES.pt(0)} ${SCALES.pr(0)} ${SCALES.pb(0)}
            ${SCALES.pl(0)};
          margin: ${SCALES.mt(0)} ${SCALES.mr(0)} ${SCALES.mb(0)} ${marginLeft};
          flex-shrink: 0;
        }

        .avatar-img {
          display: inline-block;
          object-fit: cover;
          width: 100%;
          height: 100%;
          border-radius: ${radius};
          user-select: none;
        }

        .avatar-text {
          position: absolute;
          left: 50%;
          top: 50%;
          font-size: ${SCALES.font(1)};
          text-align: center;
          transform: translate(-50%, -50%) scale(0.65);
          white-space: nowrap;
          user-select: none;
        }
      `}</style>
    </span>
  );
};

AvatarComponent.defaultProps = defaultProps;
AvatarComponent.displayName = "GeistAvatar";
const Avatar = withScale(AvatarComponent);
export default Avatar;
