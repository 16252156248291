import { Card, useTheme } from "sparkl-ui";

interface Props {
  children: React.ReactNode;
  id: string;
}

export const GeneratorCard = ({ children, ...props }: Props) => {
  const theme = useTheme();

  return (
    <Card
      id={props.id ? props.id : "card"}
      hoverable
      width="100%"
      className="no-scroll"
      style={{
        border: "0",
        borderTop: `1px solid ${theme.palette.accents_1}`,
        borderLeft: `1px solid ${theme.palette.accents_1}`,
        borderBottom: `1px solid ${theme.palette.accents_2}`,
        borderRight: `1px solid ${theme.palette.accents_2}`,
        width: "100%",
        height: "fit-content",
        overflowX: "clip",
        display: "flex",
        flexDirection: "column",
      }}
    >
      {children}
    </Card>
  );
};

export const GeneratorCardContent = ({ children, ...props }: Props) => {
  return <Card.Content padding={0}>{children}</Card.Content>;
};
