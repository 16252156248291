export const mauve = {
  mauve1: "hsl(300, 20.0%, 99.0%)",
  mauve2: "hsl(300, 7.7%, 97.5%)",
  mauve3: "hsl(294, 5.5%, 95.3%)",
  mauve4: "hsl(289, 4.7%, 93.3%)",
  mauve5: "hsl(283, 4.4%, 91.3%)",
  mauve6: "hsl(278, 4.1%, 89.1%)",
  mauve7: "hsl(271, 3.9%, 86.3%)",
  mauve8: "hsl(255, 3.7%, 78.8%)",
  mauve9: "hsl(252, 4.0%, 57.3%)",
  mauve10: "hsl(253, 3.5%, 53.5%)",
  mauve11: "hsl(252, 4.0%, 44.8%)",
  mauve12: "hsl(260, 25.0%, 11.0%)",
};
export const mauveA = {
  mauveA1: "hsla(300, 89.3%, 18.3%, 0.012)",
  mauveA2: "hsla(300, 78.1%, 9.0%, 0.028)",
  mauveA3: "hsla(300, 99.5%, 7.7%, 0.051)",
  mauveA4: "hsla(270, 90.5%, 6.1%, 0.071)",
  mauveA5: "hsla(270, 83.0%, 5.2%, 0.091)",
  mauveA6: "hsla(300, 93.5%, 3.7%, 0.114)",
  mauveA7: "hsla(270, 82.6%, 3.3%, 0.142)",
  mauveA8: "hsla(255, 95.2%, 3.7%, 0.220)",
  mauveA9: "hsla(255, 94.8%, 3.7%, 0.444)",
  mauveA10: "hsla(253, 96.5%, 3.8%, 0.483)",
  mauveA11: "hsla(247, 97.9%, 3.2%, 0.569)",
  mauveA12: "hsla(261, 98.7%, 3.0%, 0.918)",
};
export const plumA = {
  plumA1: "hsla(280, 100%, 51.0%, 0.012)",
  plumA2: "hsla(300, 100%, 51.0%, 0.028)",
  plumA3: "hsla(300, 99.0%, 40.9%, 0.063)",
  plumA4: "hsla(300, 99.9%, 38.5%, 0.102)",
  plumA5: "hsla(298, 98.2%, 35.9%, 0.150)",
  plumA6: "hsla(297, 99.6%, 33.7%, 0.216)",
  plumA7: "hsla(295, 99.7%, 32.6%, 0.314)",
  plumA8: "hsla(292, 99.6%, 32.4%, 0.432)",
  plumA9: "hsla(292, 99.9%, 31.0%, 0.710)",
  plumA10: "hsla(292, 99.9%, 30.8%, 0.765)",
  plumA11: "hsla(292, 99.8%, 30.7%, 0.832)",
  plumA12: "hsla(291, 99.9%, 9.7%, 0.953)",
};
export const plum = {
  plum1: "hsl(292, 90.0%, 99.4%)",
  plum2: "hsl(300, 100%, 98.6%)",
  plum3: "hsl(299, 71.2%, 96.4%)",
  plum4: "hsl(299, 62.0%, 93.8%)",
  plum5: "hsl(298, 56.1%, 90.5%)",
  plum6: "hsl(296, 51.3%, 85.8%)",
  plum7: "hsl(295, 48.2%, 78.9%)",
  plum8: "hsl(292, 47.7%, 70.8%)",
  plum9: "hsl(292, 45.0%, 51.0%)",
  plum10: "hsl(292, 50.2%, 46.9%)",
  plum11: "hsl(292, 60.0%, 42.5%)",
  plum12: "hsl(291, 66.0%, 14.0%)",
};
export const crimsonA = {
  crimsonA1: "hsla(340, 100%, 51.0%, 0.012)",
  crimsonA2: "hsla(330, 100%, 51.0%, 0.032)",
  crimsonA3: "hsla(332, 99.1%, 47.1%, 0.063)",
  crimsonA4: "hsla(331, 99.9%, 44.3%, 0.102)",
  crimsonA5: "hsla(333, 99.9%, 42.3%, 0.153)",
  crimsonA6: "hsla(333, 99.5%, 40.5%, 0.224)",
  crimsonA7: "hsla(335, 99.7%, 39.1%, 0.322)",
  crimsonA8: "hsla(336, 99.5%, 38.5%, 0.440)",
  crimsonA9: "hsla(336, 99.9%, 44.3%, 0.761)",
  crimsonA10: "hsla(336, 100%, 42.5%, 0.808)",
  crimsonA11: "hsla(336, 99.8%, 40.3%, 0.883)",
  crimsonA12: "hsla(340, 99.0%, 10.0%, 0.950)",
};
export const crimson = {
  crimson1: "hsl(332, 100%, 99.4%)",
  crimson2: "hsl(330, 100%, 98.4%)",
  crimson3: "hsl(331, 85.6%, 96.6%)",
  crimson4: "hsl(331, 78.1%, 94.2%)",
  crimson5: "hsl(332, 72.1%, 91.1%)",
  crimson6: "hsl(333, 67.0%, 86.7%)",
  crimson7: "hsl(335, 63.5%, 80.4%)",
  crimson8: "hsl(336, 62.3%, 72.9%)",
  crimson9: "hsl(336, 80.0%, 57.8%)",
  crimson10: "hsl(336, 73.7%, 53.5%)",
  crimson11: "hsl(336, 75.0%, 47.2%)",
  crimson12: "hsl(340, 65.0%, 14.5%)",
};
export const blueA = {
  blueA1: "hsla(210, 100%, 51.0%, 0.016)",
  blueA2: "hsla(210, 100%, 51.0%, 0.040)",
  blueA3: "hsla(210, 100%, 50.3%, 0.071)",
  blueA4: "hsla(210, 100%, 50.1%, 0.118)",
  blueA5: "hsla(208, 99.1%, 47.1%, 0.189)",
  blueA6: "hsla(209, 99.5%, 45.3%, 0.283)",
  blueA7: "hsla(208, 99.9%, 43.8%, 0.412)",
  blueA8: "hsla(206, 99.8%, 45.1%, 0.632)",
  blueA9: "hsla(206, 100%, 50.0%, 0.980)",
  blueA10: "hsla(208, 100%, 47.2%, 0.980)",
  blueA11: "hsla(212, 100%, 43.0%, 0.980)",
  blueA12: "hsla(213, 100%, 14.4%, 0.980)",
};
export const blue = {
  blue1: "hsl(206, 100%, 99.2%)",
  blue2: "hsl(210, 100%, 98.0%)",
  blue3: "hsl(209, 100%, 96.5%)",
  blue4: "hsl(210, 98.8%, 94.0%)",
  blue5: "hsl(209, 95.0%, 90.1%)",
  blue6: "hsl(209, 81.2%, 84.5%)",
  blue7: "hsl(208, 77.5%, 76.9%)",
  blue8: "hsl(206, 81.9%, 65.3%)",
  blue9: "hsl(206, 100%, 50.0%)",
  blue10: "hsl(208, 100%, 47.3%)",
  blue11: "hsl(211, 100%, 43.2%)",
  blue12: "hsl(211, 100%, 15.0%)",
};
export const greenA = {
  greenA1: "hsla(140, 94.9%, 38.7%, 0.016)",
  greenA2: "hsla(138, 99.9%, 38.5%, 0.051)",
  greenA3: "hsla(139, 97.7%, 36.9%, 0.087)",
  greenA4: "hsla(139, 98.5%, 32.7%, 0.134)",
  greenA5: "hsla(141, 100%, 30.4%, 0.200)",
  greenA6: "hsla(142, 99.0%, 28.9%, 0.295)",
  greenA7: "hsla(146, 99.5%, 27.6%, 0.428)",
  greenA8: "hsla(151, 99.5%, 28.8%, 0.644)",
  greenA9: "hsla(151, 99.9%, 28.0%, 0.812)",
  greenA10: "hsla(152, 99.6%, 25.8%, 0.840)",
  greenA11: "hsla(153, 99.9%, 21.0%, 0.906)",
  greenA12: "hsla(155, 99.4%, 6.2%, 0.918)",
};
export const green = {
  green1: "hsl(136, 50.0%, 98.9%)",
  green2: "hsl(138, 62.5%, 96.9%)",
  green3: "hsl(139, 55.2%, 94.5%)",
  green4: "hsl(140, 48.7%, 91.0%)",
  green5: "hsl(141, 43.7%, 86.0%)",
  green6: "hsl(143, 40.3%, 79.0%)",
  green7: "hsl(146, 38.5%, 69.0%)",
  green8: "hsl(151, 40.2%, 54.1%)",
  green9: "hsl(151, 55.0%, 41.5%)",
  green10: "hsl(152, 57.5%, 37.6%)",
  green11: "hsl(153, 67.0%, 28.5%)",
  green12: "hsl(155, 40.0%, 14.0%)",
};
export const yellowA = {
  yellowA1: "hsla(60, 94.3%, 34.6%, 0.024)",
  yellowA2: "hsla(52, 100%, 50.4%, 0.091)",
  yellowA3: "hsla(55, 100%, 50.2%, 0.181)",
  yellowA4: "hsla(54, 100%, 50.1%, 0.267)",
  yellowA5: "hsla(52, 99.9%, 49.5%, 0.357)",
  yellowA6: "hsla(50, 100%, 47.4%, 0.451)",
  yellowA7: "hsla(47, 99.8%, 44.6%, 0.577)",
  yellowA8: "hsla(48, 100%, 46.0%, 0.980)",
  yellowA9: "hsla(53, 100%, 48.0%, 0.961)",
  yellowA10: "hsla(50, 100%, 48.4%, 0.980)",
  yellowA11: "hsla(42, 100%, 28.6%, 0.980)",
  yellowA12: "hsla(41, 98.9%, 8.0%, 0.942)",
};
export const yellow = {
  yellow1: "hsl(60, 54.0%, 98.5%)",
  yellow2: "hsl(52, 100%, 95.5%)",
  yellow3: "hsl(55, 100%, 90.9%)",
  yellow4: "hsl(54, 100%, 86.6%)",
  yellow5: "hsl(52, 97.9%, 82.0%)",
  yellow6: "hsl(50, 89.4%, 76.1%)",
  yellow7: "hsl(47, 80.4%, 68.0%)",
  yellow8: "hsl(48, 100%, 46.1%)",
  yellow9: "hsl(53, 92.0%, 50.0%)",
  yellow10: "hsl(50, 100%, 48.5%)",
  yellow11: "hsl(42, 100%, 29.0%)",
  yellow12: "hsl(40, 55.0%, 13.5%)",
};
export const mintA = {
  mintA1: "hsla(168, 95.4%, 42.8%, 0.024)",
  mintA2: "hsla(164, 99.1%, 47.1%, 0.063)",
  mintA3: "hsla(164, 99.3%, 43.5%, 0.118)",
  mintA4: "hsla(164, 99.3%, 41.3%, 0.177)",
  mintA5: "hsla(165, 99.0%, 37.5%, 0.248)",
  mintA6: "hsla(165, 100%, 35.0%, 0.353)",
  mintA7: "hsla(166, 99.9%, 33.5%, 0.510)",
  mintA8: "hsla(168, 99.6%, 34.6%, 0.750)",
  mintA9: "hsla(167, 99.9%, 39.5%, 0.561)",
  mintA10: "hsla(167, 99.7%, 37.4%, 0.589)",
  mintA11: "hsla(172, 99.8%, 22.4%, 0.922)",
  mintA12: "hsla(172, 99.7%, 8.8%, 0.965)",
};
export const mint = {
  mint1: "hsl(165, 80.0%, 98.8%)",
  mint2: "hsl(164, 88.2%, 96.7%)",
  mint3: "hsl(164, 76.6%, 93.3%)",
  mint4: "hsl(165, 68.8%, 89.5%)",
  mint5: "hsl(165, 60.6%, 84.5%)",
  mint6: "hsl(165, 53.5%, 76.9%)",
  mint7: "hsl(166, 50.7%, 66.1%)",
  mint8: "hsl(168, 52.8%, 51.0%)",
  mint9: "hsl(167, 65.0%, 66.0%)",
  mint10: "hsl(167, 59.3%, 63.1%)",
  mint11: "hsl(172, 72.0%, 28.5%)",
  mint12: "hsl(172, 70.0%, 12.0%)",
};
export const violetA = {
  violetA1: "hsla(270, 94.3%, 34.6%, 0.012)",
  violetA2: "hsla(252, 100%, 51.0%, 0.020)",
  violetA3: "hsla(254, 100%, 50.0%, 0.051)",
  violetA4: "hsla(251, 98.3%, 48.2%, 0.087)",
  violetA5: "hsla(252, 99.0%, 45.7%, 0.130)",
  violetA6: "hsla(251, 99.1%, 44.0%, 0.189)",
  violetA7: "hsla(252, 99.5%, 41.7%, 0.279)",
  violetA8: "hsla(252, 100%, 40.7%, 0.400)",
  violetA9: "hsla(252, 99.9%, 35.8%, 0.663)",
  violetA10: "hsla(251, 99.6%, 32.5%, 0.691)",
  violetA11: "hsla(250, 99.8%, 28.4%, 0.726)",
  violetA12: "hsla(254, 99.5%, 11.9%, 0.926)",
};
export const violet = {
  violet1: "hsl(255, 65.0%, 99.4%)",
  violet2: "hsl(252, 100%, 99.0%)",
  violet3: "hsl(252, 96.9%, 97.4%)",
  violet4: "hsl(252, 91.5%, 95.5%)",
  violet5: "hsl(252, 85.1%, 93.0%)",
  violet6: "hsl(252, 77.8%, 89.4%)",
  violet7: "hsl(252, 71.0%, 83.7%)",
  violet8: "hsl(252, 68.6%, 76.3%)",
  violet9: "hsl(252, 56.0%, 57.5%)",
  violet10: "hsl(251, 48.1%, 53.5%)",
  violet11: "hsl(250, 43.0%, 48.0%)",
  violet12: "hsl(254, 60.0%, 18.5%)",
};
export const redA = {
  redA1: "hsla(0, 100%, 51.0%, 0.012)",
  redA2: "hsla(0, 100%, 51.0%, 0.032)",
  redA3: "hsla(0, 100%, 50.2%, 0.063)",
  redA4: "hsla(0, 100%, 50.0%, 0.102)",
  redA5: "hsla(0, 99.9%, 47.5%, 0.153)",
  redA6: "hsla(0, 99.5%, 44.9%, 0.224)",
  redA7: "hsla(359, 99.7%, 42.7%, 0.318)",
  redA8: "hsla(359, 99.6%, 41.1%, 0.436)",
  redA9: "hsla(358, 99.9%, 42.9%, 0.718)",
  redA10: "hsla(358, 99.9%, 41.0%, 0.761)",
  redA11: "hsla(358, 99.8%, 38.3%, 0.832)",
  redA12: "hsla(355, 99.3%, 7.9%, 0.926)",
};
export const red = {
  red1: "hsl(359, 100%, 99.4%)",
  red2: "hsl(359, 100%, 98.6%)",
  red3: "hsl(360, 100%, 96.8%)",
  red4: "hsl(360, 97.9%, 94.8%)",
  red5: "hsl(360, 90.2%, 91.9%)",
  red6: "hsl(360, 81.7%, 87.8%)",
  red7: "hsl(359, 74.2%, 81.7%)",
  red8: "hsl(359, 69.5%, 74.3%)",
  red9: "hsl(358, 75.0%, 59.0%)",
  red10: "hsl(358, 69.4%, 55.2%)",
  red11: "hsl(358, 65.0%, 48.7%)",
  red12: "hsl(354, 50.0%, 14.6%)",
};
//

export const mauveDarkA = {
  mauveA1: "hsla(0, 0%, 0%, 0)",
  mauveA2: "hsla(240, 76.7%, 91.2%, 0.031)",
  mauveA3: "hsla(240, 86.0%, 95.8%, 0.061)",
  mauveA4: "hsla(240, 91.8%, 94.7%, 0.087)",
  mauveA5: "hsla(240, 91.5%, 95.8%, 0.113)",
  mauveA6: "hsla(240, 92.0%, 93.8%, 0.148)",
  mauveA7: "hsla(240, 94.8%, 95.3%, 0.191)",
  mauveA8: "hsla(249, 98.1%, 95.2%, 0.273)",
  mauveA9: "hsla(248, 97.6%, 96.2%, 0.416)",
  mauveA10: "hsla(248, 95.5%, 96.6%, 0.477)",
  mauveA11: "hsla(250, 98.0%, 98.0%, 0.615)",
  mauveA12: "hsla(240, 93.9%, 99.6%, 0.931)",
};

export const plumDarkA = {
  plumA1: "hsla(0, 0%, 0%, 0)",
  plumA2: "hsla(300, 96.4%, 58.4%, 0.036)",
  plumA3: "hsla(300, 99.4%, 67.1%, 0.102)",
  plumA4: "hsla(295, 99.8%, 66.3%, 0.155)",
  plumA5: "hsla(295, 99.4%, 67.1%, 0.204)",
  plumA6: "hsla(294, 99.0%, 67.8%, 0.262)",
  plumA7: "hsla(294, 99.9%, 67.7%, 0.363)",
  plumA8: "hsla(292, 99.8%, 67.5%, 0.527)",
  plumA9: "hsla(292, 99.9%, 69.2%, 0.695)",
  plumA10: "hsla(295, 99.9%, 70.8%, 0.748)",
  plumA11: "hsla(300, 99.8%, 72.9%, 0.828)",
  plumA12: "hsla(300, 100%, 97.1%, 0.980)",
};

export const crimsonDarkA = {
  crimsonA1: "hsla(0, 0%, 0%, 0)",
  crimsonA2: "hsla(336, 96.8%, 53.2%, 0.045)",
  crimsonA3: "hsla(335, 98.7%, 59.3%, 0.138)",
  crimsonA4: "hsla(336, 99.1%, 59.9%, 0.191)",
  crimsonA5: "hsla(335, 99.4%, 59.4%, 0.244)",
  crimsonA6: "hsla(335, 99.4%, 59.4%, 0.315)",
  crimsonA7: "hsla(336, 99.5%, 57.8%, 0.439)",
  crimsonA8: "hsla(336, 99.9%, 55.4%, 0.642)",
  crimsonA9: "hsla(336, 99.9%, 62.8%, 0.903)",
  crimsonA10: "hsla(339, 99.9%, 66.3%, 0.934)",
  crimsonA11: "hsla(341, 99.9%, 69.5%, 0.965)",
  crimsonA12: "hsla(327, 100%, 97.1%, 0.980)",
};

export const blueDarkA = {
  blueA1: "hsla(0, 0%, 0%, 0)",
  blueA2: "hsla(221, 97.8%, 52.4%, 0.059)",
  blueA3: "hsla(215, 99.3%, 54.2%, 0.135)",
  blueA4: "hsla(215, 99.3%, 53.8%, 0.198)",
  blueA5: "hsla(213, 99.4%, 52.8%, 0.252)",
  blueA6: "hsla(212, 99.9%, 51.7%, 0.323)",
  blueA7: "hsla(211, 100%, 50.7%, 0.435)",
  blueA8: "hsla(211, 99.8%, 50.9%, 0.597)",
  blueA9: "hsla(205, 100%, 50.0%, 0.980)",
  blueA10: "hsla(208, 100%, 60.7%, 0.980)",
  blueA11: "hsla(209, 100%, 66.3%, 0.980)",
  blueA12: "hsla(196, 100%, 96.8%, 0.980)",
};
export const greenDarkA = {
  greenA1: "hsla(0, 0%, 0%, 0)",
  greenA2: "hsla(169, 100%, 48.5%, 0.027)",
  greenA3: "hsla(162, 98.7%, 57.9%, 0.070)",
  greenA4: "hsla(158, 98.6%, 59.7%, 0.105)",
  greenA5: "hsla(158, 98.6%, 60.7%, 0.140)",
  greenA6: "hsla(156, 99.9%, 62.0%, 0.187)",
  greenA7: "hsla(154, 99.5%, 63.1%, 0.257)",
  greenA8: "hsla(152, 99.7%, 64.2%, 0.370)",
  greenA9: "hsla(151, 99.7%, 63.8%, 0.605)",
  greenA10: "hsla(152, 99.9%, 66.5%, 0.661)",
  greenA11: "hsla(151, 99.7%, 69.2%, 0.740)",
  greenA12: "hsla(137, 100%, 95.8%, 0.980)",
};

export const yellowDarkA = {
  yellowA1: "hsla(0, 0%, 0%, 0)",
  yellowA2: "hsla(49, 100%, 49.1%, 0.027)",
  yellowA3: "hsla(45, 100%, 49.7%, 0.071)",
  yellowA4: "hsla(46, 100%, 49.7%, 0.111)",
  yellowA5: "hsla(47, 100%, 49.9%, 0.150)",
  yellowA6: "hsla(51, 100%, 49.8%, 0.199)",
  yellowA7: "hsla(51, 99.8%, 53.6%, 0.269)",
  yellowA8: "hsla(51, 100%, 49.9%, 0.371)",
  yellowA9: "hsla(53, 100%, 52.0%, 0.956)",
  yellowA10: "hsla(56, 100%, 68.4%, 0.980)",
  yellowA11: "hsla(48, 100%, 50.0%, 0.934)",
  yellowA12: "hsla(60, 100%, 91.8%, 0.980)",
};

export const mintDarkA = {
  mintA1: "hsla(0, 0%, 0%, 0)",
  mintA2: "hsla(180, 100%, 49.2%, 0.031)",
  mintA3: "hsla(176, 100%, 49.7%, 0.070)",
  mintA4: "hsla(173, 100%, 49.7%, 0.105)",
  mintA5: "hsla(173, 100%, 49.8%, 0.144)",
  mintA6: "hsla(172, 100%, 49.8%, 0.192)",
  mintA7: "hsla(171, 100%, 49.9%, 0.266)",
  mintA8: "hsla(169, 100%, 49.9%, 0.366)",
  mintA9: "hsla(167, 99.8%, 75.0%, 0.870)",
  mintA10: "hsla(163, 99.9%, 80.7%, 0.948)",
  mintA11: "hsla(167, 99.9%, 58.7%, 0.796)",
  mintA12: "hsla(169, 100%, 96.2%, 0.980)",
};
export const violetDarkA = {
  violetA1: "hsla(0, 0%, 0%, 0)",
  violetA2: "hsla(258, 98.2%, 61.0%, 0.054)",
  violetA3: "hsla(252, 98.8%, 65.8%, 0.148)",
  violetA4: "hsla(253, 99.7%, 65.7%, 0.219)",
  violetA5: "hsla(252, 99.7%, 66.4%, 0.286)",
  violetA6: "hsla(251, 99.7%, 66.2%, 0.371)",
  violetA7: "hsla(250, 99.7%, 66.3%, 0.514)",
  violetA8: "hsla(250, 99.7%, 66.1%, 0.733)",
  violetA9: "hsla(252, 99.9%, 70.3%, 0.786)",
  violetA10: "hsla(251, 99.9%, 72.9%, 0.844)",
  violetA11: "hsla(250, 100%, 77.9%, 0.980)",
  violetA12: "hsla(254, 100%, 97.5%, 0.980)",
};
