import type { NextPage } from "next";
import { CSSProperties, useState } from "react";
import { Card, useMediaQuery, useTheme } from "sparkl-ui";
import { FinishedPrompt } from "../../utils/prompt_types";
import { TwoPaneGrid } from "../golden_grid";
import { Byline } from "./byline";
import { ClickableGrid } from "./clickable_grid";
import { ImageCarousel } from "./image_carousel";
import { getSharedImageProp, LoadingImage } from "./loading_image";
import { PostDetails } from "./post_details";
import { Tombstone } from "./tombstone";

interface PostProps {
  // TODO: would be nice if we could use a PartialPrompt somehow... weird to do an API call for every prompt.
  prompt: FinishedPrompt;
  carousel?: boolean;
  wide?: boolean;
  indexForLightbox?: number;
  isLightboxPost?: boolean;
  admin?: boolean; // if true, show the admin buttons
  className?: string;
  style?: CSSProperties;
}

export const Post: NextPage<PostProps> = ({
  prompt,
  carousel = false,
  wide = false,
  indexForLightbox = 0,
  isLightboxPost,
  admin,
  className = "",
  style = {},
}) => {
  const promptText = prompt.params.sharedParams.prompts
    .map((prompt) => prompt.text)
    .join(", ");
  const theme = useTheme();
  const upMD = useMediaQuery("md", { match: "up" });

  const sharedImageProp = getSharedImageProp(prompt, isLightboxPost, !upMD);
  const image_urls = prompt?.outputs?.image_urls ?? [];
  const indexes = image_urls.slice(0, 9).map((image_url, index) => index);
  const images = indexes.map((index) => (
    <LoadingImage
      sharedImageProp={sharedImageProp}
      prompt={prompt}
      index={index}
      key={index}
    />
  ));

  const imageGrid = <ClickableGrid prompt={prompt} images={images} />;
  const [lightboxIndex, setLightboxIndex] = useState(indexForLightbox);

  const content = carousel ? (
    <ImageCarousel
      images={images.length > 1 ? [imageGrid, ...images] : images}
      selectedIndex={indexForLightbox}
      changeIndex={setLightboxIndex}
    />
  ) : (
    imageGrid
  );

  const caption = (hasByline: boolean) => {
    return (
      <div>
        <Tombstone
          prompt={prompt}
          promptText={promptText}
          admin={admin}
          hasByline={hasByline}
          lightboxIndex={isLightboxPost ? lightboxIndex : undefined}
        />
        {isLightboxPost && (
          <PostDetails prompt={prompt} lightboxIndex={lightboxIndex} />
        )}
      </div>
    );
  };

  const postStyle: CSSProperties = {
    padding: "0",
    border: "0",
    borderTop: `1px solid ${theme.palette.accents_1}`,
    borderLeft: `1px solid ${theme.palette.accents_1}`,
    borderBottom: `1px solid ${theme.palette.accents_2}`,
    borderRight: `1px solid ${theme.palette.accents_2}`,
    width: "100%",
    height: "fit-content",
    overflowX: "hidden",
    ...style,
  };

  const postCard = (
    <>
      <Byline
        username={prompt.username}
        modelName={prompt.params.model}
        userId={prompt.user_id}
      />
      {content}
      {caption(false)}
    </>
  );

  const postDisplay = (
    <TwoPaneGrid
      bigPane={content}
      smallPane={caption(true)}
      fixedDirection="row"
    />
  );

  return (
    <Card style={postStyle} className={className}>
      <Card.Content width={"auto"} padding={0}>
        {wide ? postDisplay : postCard}
      </Card.Content>
    </Card>
  );
};
