import Image from "next/image";
import React from "react";
import { Description, Grid, Text } from "sparkl-ui";
import { useGenieDispatch } from "../use_genie";
import { InfoPopup } from "./info_popup";
import { LabeledParam } from "./labeled_param";
import { Slider } from "./slider";
export interface CutPowProps {
  cut_pow?: number;
}

export const CutPowParam: React.FC<CutPowProps> = ({ cut_pow }) => {
  const genieDispatch = useGenieDispatch();
  // unfortunately this isn't very clear
  /*let imgSrcs = [
    "/images/examples/cut_pow/08.png",
    "/images/examples/cut_pow/09.png",
    "/images/examples/cut_pow/11.png",
    "/images/examples/cut_pow/12.png",
  ];
  let values = [0.8, 0.9, 1.1, 1.2];
  const descriptionContent = (
    <Grid.Container direction="column">
      <Text>Patch size influences how patch-y the image looks.</Text>
      <Text>
        {" "}
        The following four images are generated with the same prompt and seed,
        but ascending patch sizes:
      </Text>

      <Grid>
        <Grid.Container gap={0.3}>
          {imgSrcs.map((src, i) => {
            return (
              <Grid xs={12} key={src} justify="space-around">
                <Image
                  width="160px"
                  height="160px"
                  src={src}
                  style={{ borderRadius: "3px" }}
                  alt={`'a tiger in a beautiful forest at sunset' with patch size  ${values[i]}`}
                  placeholder="blur"
                />
              </Grid>
            );
          })}
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );*/

  return (
    <LabeledParam
      label={
        <>
          <Description title={"Patch size"} />
        </>
        // <InfoPopup content={descriptionContent} />
      }
    >
      <Slider
        value={cut_pow ?? 0}
        onChange={(e) => genieDispatch({ type: "setCutPow", cutPow: e })}
        min={0.5}
        max={1.5}
        step={0.01}
      />
    </LabeledParam>
  );
};
