import { SWRConfiguration, mutate, MutatorCallback } from "swr";
import { unstable_serialize } from "swr/infinite";
import {
  InProgressPrompt,
  PromptsFailure,
  PromptsResponse,
  PromptsSuccess,
} from "./prompt_types";

/*
TODO: Debug this, it's being triggered wayy too often
*/

export const queueSWRConfig: SWRConfiguration = {
  refreshInterval: (data: PromptsResponse) => {
    // console.log("refreshInterval data is", data)
    if (!data) {
      // console.log("refreshInterval data is", data);
      return 500;
    }
    console.log("refreshInterval data is finally not none!!!");
    // Need to understand what causes a failure better - as this seems to happen often
    if (data.status === "failure") {
      console.log("failure, waiting 10s:", data);
      return 10 * 1000;
    }
    //check if any prompt has a status of "in progress"
    const promptsInProgress = data.prompts.some(
      (prompt) =>
        prompt.status === "uploading" ||
        prompt.status === "assigned" ||
        prompt.status === "pending"
    );
    //console.log("Prompts in progress: ", promptsInProgress);
    // TODO: We should perhaps stop polling every 0.5s after a certain amount of time
    // Maybe like 2 minutes or so?
    // Need to mostly figure out/test how date works on prompts
    const wait = promptsInProgress ? 500 : 180 * 1000;
    console.log(`waiting ${wait}`);
    return wait;
  },
  // refreshInterval: (data) => {
  //   console.log("refreshInterval data is", data);
  //   if (!data) {
  //       console.log("not data")
  //       return 501;
  //   }
  //   return 100;
  // },

  // Need to see if the 500ms will actually work
  // These defaults might stop it:
  dedupingInterval: 500,
  focusThrottleInterval: 500,
  // idk about this one
  // keepPreviousData: true,
};

function queueKeyMatcher(key: string): boolean {
  const [path, query] = key.split("?");
  //use query parser to check if there's a queue query
  const queryObj = new URLSearchParams(query);
  return path === "/api/prompts" && queryObj.has("queue");
}
export const mutateQueue = (newPrompt: InProgressPrompt) => {
  // does this trigger checking refreshInterval?
  const mutatorCallback: MutatorCallback<PromptsSuccess> = async (
    data: PromptsSuccess | undefined
  ) => {
    if (!data) {
      return data;
    } else {
      return {
        ...data,
        prompts: [newPrompt, ...data.prompts],
      };
    }
  };
  // not sure if revalidate is appropriate - hope was to reset refreshInterval after mutating
  mutate(queueKeyMatcher, mutatorCallback, { revalidate: true });
};

export function mutateShowcasedFeed(userId: string) {
  // This is a weird workaround due to swr's poor support of mutation for
  // SWRInfinite, see: https://github.com/vercel/swr/issues/1670
  mutate((key) => {
    if (typeof key === "string" && key.startsWith("/api/prompts?")) {
      const params = new URLSearchParams(key.split("?")[1]);
      if (
        params.get("showcased") === "true" &&
        params.get("user_id") == userId
      ) {
        mutate(unstable_serialize((index, prevPageData) => key));
        return true;
      } else {
        return false;
      }
    }
    return false;
  });
}

export function mutateUserLikedFeed(userId: string) {
  // This is a weird workaround due to swr's poor support of mutation for
  // SWRInfinite, see: https://github.com/vercel/swr/issues/1670
  mutate((key) => {
    if (typeof key === "string" && key.startsWith("/api/prompts?")) {
      const params = new URLSearchParams(key.split("?")[1]);
      if (params.get("user_likes") === userId) {
        mutate(unstable_serialize((index, prevPageData) => key));
        return true;
      } else {
        return false;
      }
    }
    return false;
  });
}

export const fetcher = (url: string) => fetch(url).then((res) => res.json());

export const FEED_PAGE_SIZE = "15";
