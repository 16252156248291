import React, { useState } from "react";
import {
  Description,
  Select,
  SelectProps,
  Text,
  Toggle,
  Tooltip,
  useMediaQuery,
  useTheme,
} from "sparkl-ui";
import { img2img_models, Model } from "../../utils/feed_types";
import { Mode } from "../genie/model_generators/model_generation_component_types";
import { generatorWhiteList } from "./model_tags";
import { useGenie, useGenieDispatch } from "../genie/use_genie";
import { LabeledModel } from "./labeled_model";
import { GenericSelector } from "./generic_selector";
import { ModelSelector } from "./model_selector";

export const GeneratorBar: React.FC = () => {
  const params = useGenie();
  const genieDispatch = useGenieDispatch();
  const [lastUsedMode, setLastUsedMode] = useState<Mode>("txt2img");
  const setModel = (model: Model) => {
    genieDispatch({ type: "switchModel", model });
  };
  const setMode = (mode: Mode) => {
    genieDispatch({ type: "setMode", mode });
  };

  return (
    <div
      id="generator-bar"
      className="no-scroll"
      style={{
        position: "absolute",
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        gap: "0.38rem 1rem",
        zIndex: 10,
        paddingTop: "0.38rem",
        paddingBottom: "0.38rem",
        backgroundColor: "rgba(0,0,0,0.0)",
      }}
    >
      {/* Model selector */}
      <ModelSelector
        getPopupContainer={() => document.getElementById("generator-bar")}
        value={params.selectedModel}
        onChange={(value) => {
          setModel(value as Model);
          if (!img2img_models.includes(value as Model)) {
            setLastUsedMode(params.viewProps.mode);
            setMode("txt2img");
          } else {
            setMode(lastUsedMode);
          }
        }}
      />
      {/* Mode selector */}
      <GenericSelector
        value={params.viewProps.mode}
        getPopupContainer={() => document.getElementById("generator-bar")}
        placeholder="Mode"
        //@ts-ignore
        onChange={(value) => {
          genieDispatch({ type: "setMode", mode: value as Mode });
        }}
        disabled={!img2img_models.includes(params.selectedModel)}
      >
        <Select.Option value="txt2img" key="txt2img">
          <Text font="1rem" b>
            Text to Image
          </Text>
        </Select.Option>

        <Select.Option value="img2img" key="img2img">
          <Text font="1rem" b>
            Image to Image
          </Text>
        </Select.Option>

        <Select.Option disabled value="img2vid" key="img2vid">
          <Tooltip text="Coming soon!" placement="bottomEnd" width={"14ch"}>
            <Text font="1rem" b>
              Video
            </Text>
          </Tooltip>
        </Select.Option>
      </GenericSelector>
    </div>
  );
};
