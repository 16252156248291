// This component composes the inputs with the specific params for this model

import React from "react";
import { GuidanceScaleParam } from "../generator_subcomponents/condition_scale";
import { ImageSizeParam } from "../generator_subcomponents/image_size";
import { NumImagesParam } from "../generator_subcomponents/num_images";
import { ParamsGrid } from "../generator_subcomponents/params_grid";
import { SeedParam } from "../generator_subcomponents/seed";
import { StepsParam } from "../generator_subcomponents/steps";
import { useGenie } from "../use_genie";
import { VerdantParams } from "./model_generation_component_types";

export const Waifu: React.FC = () => {
  const params = useGenie();
  const verdantParams = params.allModelsParams.waifu as VerdantParams;

  return (
    <ParamsGrid>
      <NumImagesParam num={verdantParams.num_images} />
      {params.viewProps.mode !== "img2img" && (
        <ImageSizeParam
          width={verdantParams.width}
          height={verdantParams.height}
        />
      )}
      <SeedParam seed={verdantParams.seed} />
      <GuidanceScaleParam scale={verdantParams.scale} />
      <StepsParam steps={verdantParams.ddim_steps} />
    </ParamsGrid>
  );
};
