import { NextPage } from "next";
import { CSSProperties, useContext } from "react";
import { useMediaQuery } from "sparkl-ui";
import { FinishedPrompt } from "../../utils/prompt_types";
import { LightboxContext } from "./lightbox_context";

const makeGridStyle = (side_length: number): CSSProperties => {
  // Make a string that repeats "1fr " times per_side
  const gridTemplateColumns = Array(side_length).fill("1fr ").join("");
  const div_style = {
    display: "grid",
    height: "100%",
    width: "100%",
    gridTemplateColumns: gridTemplateColumns,
    gridTemplateRows: gridTemplateColumns,
    gridGap: "0",
    overflow: "hidden",
    cursor: "pointer",
  };
  return div_style;
};

export interface ClickableGridProps {
  prompt: FinishedPrompt;
  images: JSX.Element[];
}

export const ClickableGrid: NextPage<ClickableGridProps> = ({
  prompt,
  images,
}) => {
  const upMD = useMediaQuery("md", { match: "up" });
  const { openWithLightbox } = useContext(LightboxContext);

  if (images.length > 1) {
    // Just get a square number of images
    const side_length = Math.floor(Math.sqrt(images.length)); // This is the old way
    const sliced_images = images.slice(0, side_length * side_length);
    return (
      <div style={makeGridStyle(side_length)}>
        {sliced_images.map((image: JSX.Element, index: number) => {
          return (
            <div
              key={String(index)}
              id={String(index)}
              onClick={openWithLightbox(prompt, upMD ? 0 : index + 1)}
            >
              {image}
            </div>
          );
        })}
      </div>
    );
  } else {
    let index = 0;
    return (
      <div style={makeGridStyle(images.length)}>
        <div
          key={String(index)}
          id={String(index)}
          onClick={openWithLightbox(prompt, index)}
        >
          {images}
        </div>
      </div>
    );
  }
};
