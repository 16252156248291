import Archive from "@geist-ui/icons/archive";
import FastForward from "@geist-ui/icons/fastForward";
import HeartFill from "@geist-ui/icons/heartFill";
import Star from "@geist-ui/icons/star";
import { ReactElement } from "react";
import { Button, Grid, Modal, useModal, Text } from "sparkl-ui";
import { FeatureCalloutCell, EmbeddedFeatureCalloutCell } from "./feature_cell";
import { config } from "../../utils/config";
import Link from "next/link";

export const useNoCreditsModal = () => {
  const { visible, setVisible, bindings } = useModal();

  const toggleModal = () => {
    setVisible(!visible);
  };
  const NoCreditsModal = (): ReactElement => (
    <Modal width="700px" {...bindings}>
      <Grid.Container justify="center" alignContent="center" padding={2}>
        <Grid xs={24} justify="center">
          <Text h2>Insufficient credits</Text>
        </Grid>
        <Text p width="80%">
          {" "}
          You have run out of credits - head to the credits page to get some
          more, or come back tomorrow.{" "}
        </Text>
        <Grid xs={24} justify="center" padding="5px">
          <Link href="/profile?tab=credits" passHref>
            <Button type="success-light" onClick={toggleModal}>
              {" "}
              Get more credits{" "}
            </Button>
          </Link>
        </Grid>
      </Grid.Container>
    </Modal>
  );

  return {
    toggleModal,
    NoCreditsModal,
  };
};
