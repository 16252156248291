import Image from "next/image";
import { useState } from "react";
import {
  Button,
  Description,
  Grid,
  Input,
  Text,
  useMediaQuery,
} from "sparkl-ui";
import { InfoPopup } from "./info_popup";
import { Dice } from "../../icons";
import { LabeledParam } from "./labeled_param";
import { useGenie, useGenieDispatch } from "../use_genie";
import { generateRandomSeed } from "../model_generators/model_generation_component_types";
import a from "../../../public/images/examples/seed/bear.png";
import b from "../../../public/images/examples/seed/tiger.png";
import c from "../../../public/images/examples/seed/sunrise.png";
import d from "../../../public/images/examples/seed/sunset.png";

type Seed = number;

export interface SeedParamProps {
  seed: Seed;
}

export const SeedParam: React.FC<SeedParamProps> = ({ seed }) => {
  const genieDispatch = useGenieDispatch();
  let upMd = useMediaQuery("md", { match: "up" });
  let xs = useMediaQuery("xs", { match: "down" });

  let textScale = upMd ? 2.0 : xs ? 1.0 : 1.5;
  let inputWidth = xs ? "100px" : "150px";

  const currentSeed = seed ?? (generateRandomSeed() as Seed);
  const [textField, setTextField] = useState<string>(currentSeed.toString());
  const [seedIsRandom, setSeedIsRandom] = useState<boolean>(true);

  function setSeed(newSeed: Seed) {
    console.log("Setting seed to " + newSeed);
    genieDispatch({
      type: "setSeed",
      seed: newSeed,
    });
  }
  function textChangeHandler(e: React.ChangeEvent<HTMLInputElement>) {
    setTextField(e.target.value);
    if (isTextValid()) {
      setSeed(parseInt(e.target.value));
    }
    setSeedIsRandom(false);
  }

  function isTextValid() {
    return !isNaN(Number(textField));
  }

  function setRandomSeed() {
    const randomSeed = generateRandomSeed();
    setTextField(randomSeed.toString());
    setSeed(randomSeed);
    setSeedIsRandom(true);
  }

  let imgSrcs = [a, b, c, d];
  const descriptionContent = (
    <Grid.Container direction="column">
      <Text>
        {" "}
        Seeds control the initial noise pattern. Keeping the same seed while you
        slightly change the prompt can allow you to tune details in a high-level
        structure that you like{" "}
      </Text>
      <Text>
        {" "}
        The following four images are generated with the same seed, but slightly
        different prompts:
      </Text>
      <Grid>
        <Grid.Container gap={0.3}>
          {imgSrcs.map((src, i) => {
            return (
              <Grid xs={12} key={i} justify="space-around">
                <Image
                  width="160px"
                  height="160px"
                  src={src}
                  style={{ borderRadius: "3px" }}
                  alt="Images generated with the same seed but slightly different prompts"
                  placeholder="blur"
                />
              </Grid>
            );
          })}
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );

  return (
    <LabeledParam
      label={
        <>
          <Description title={"Seed"} />
          <InfoPopup content={descriptionContent} />
        </>
      }
    >
      <Button
        auto
        ghost
        type="success"
        scale={1.62}
        onClick={() => {
          setRandomSeed();
        }}
        style={{
          padding: "0",
        }}
        icon={<Dice />}
      />
      <Input
        type={isTextValid() ? "success" : "error"}
        width={inputWidth}
        maxLength={9}
        paddingLeft={"5px"}
        value={String(currentSeed)}
        onChange={textChangeHandler}
        scale={textScale}
        style={{
          height: "100%",
        }}
      />
    </LabeledParam>
  );
};
