import React from "react";
import { Card, Textarea } from "sparkl-ui";
import { img2img_models } from "../../../utils/feed_types";
import { useGenie, useGenieDispatch } from "../use_genie";
import { useUploadImage } from "../use_upload_image";

export const PromptBox: React.FC = () => {
  const params = useGenie();
  const genieDispatch = useGenieDispatch();
  const { uploadImage, isUploading } = useUploadImage();

  return (
    <Textarea
      width="100%"
      height="15vh"
      scale={1.3}
      autoComplete="off"
      placeholder="What do you wish to see?"
      name="prompt"
      value={params.sharedParams.prompts[0].text}
      onChange={(e: any) => {
        genieDispatch({ type: "setText", text: e.target.value });
      }}
      // Want to make it possible to paste an image in the prompt box
      // if the most recent clipboard item is an image
      // and have the genie switch to img2img mode and upload the image
      // but still paste text as normal if there isn't an image
      onPaste={(e) => {
        // Check to see if the most recent clipboard item is an image
        const item = e.clipboardData.items[0];

        if (item.type.indexOf("image") === 0) {
          e.preventDefault();
          if (params.selectedModel in img2img_models) {
            genieDispatch({ type: "setMode", mode: "img2img" });
          } else {
            genieDispatch({ type: "switchModel", model: img2img_models[0] });
            genieDispatch({ type: "setMode", mode: "img2img" });
          }
          // upload the image using the image picker
          uploadImage(item.getAsFile());
        }
      }}
    />
  );
};

