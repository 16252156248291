import { Grid, Description, Text, useToasts, Select } from "sparkl-ui";
import React from "react";
import { InitImageParams } from "../model_generators/model_generation_component_types";
import { ImagePicker } from "./image_picker";
import { InfoPopup } from "./info_popup";
import { LabeledParam } from "./labeled_param";
import { useGenie, useGenieDispatch } from "../use_genie";
import { ImgNoiseDescription } from "./descriptions";
import { Slider } from "./slider";
import { img2img_models } from "../../../utils/feed_types";
// type InitImageType = "none" | "init" | "inpaint";

export const InitImageParam: React.FC = () => {
  const { setToast } = useToasts();
  const params = useGenie();
  const genieDispatch = useGenieDispatch();

  // Currently we don't use this but will later
  // function setInitImageType(initImage: InitImageType) {
  //   console.log("Setting to " + initImage);
  //   setInitImageParams({ ...imageParams, init_image_type: initImage });
  // }
  if (params.viewProps.mode !== "img2img") {
    return <></>;
  }
  const imageParams = params.allModelsParams[
    params.selectedModel
  ] as InitImageParams;
  return (
    <div
      style={{
        display: "grid",
        gridGap: "1rem",
        gridTemplateColumns: "repeat(auto-fit, minmax(12rem, 1fr))",
        gridTemplateRows: "repeat(auto-fit, minmax(12rem, 1fr))",
        alignItems: "stretch",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      }}
    >
      {/* Image drop/upload zone */}
      <LabeledParam label={<Description title={"Upload Image"} />}>
        <ImagePicker
          initImage={
            img2img_models.includes(params.selectedModel)
              ? imageParams.init_image
              : undefined
          }
        />
      </LabeledParam>
      {/* Image Strength slider */}
      <LabeledParam
        label={
          <>
            <Description title={"Image strength"} />
            <InfoPopup content={<ImgNoiseDescription />} />
          </>
        }
      >
        {/* Image strength slider */}
        <Slider
          min={0}
          max={0.6}
          step={0.02}
          value={Number((1 - (imageParams.strength ?? 0.8)).toFixed(2))}
          onChange={(e) => {
            genieDispatch({
              type: "setInitImageStrength",
              initImageStrength: Number((1 - e).toFixed(2)),
            });
          }}
        />
      </LabeledParam>
    </div>
  );
};
