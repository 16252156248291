// This component wraps the Button components from sparkl-ui with some state logic
// to keep track of which button is selected. Only one button can be selected
// at a time. The selected button is highlighted and the callback is called
// when the button is clicked. The button remains selected until changed again.
// Really this is a radio button component but visualized in the form of a multi-button toggle.

import { Button, useTheme } from "sparkl-ui";

// The options passed to this component will be objects that have a callback, a key and a React node label.

export interface ButtonToggleProps {
  value: boolean;
  callback: () => void;
  icon: JSX.Element;
  label?: React.ReactNode;
  style?: React.CSSProperties;
}

export const ButtonToggle: React.FC<ButtonToggleProps> = ({
  value,
  callback,
  icon,
  label,
  style = {},
}) => {
  // console.log(selectedKey);
  const theme = useTheme();
  return (
    <Button
      auto
      scale={0.38}
      type="success-light"
      ghost
      style={{
        backgroundColor: value ? theme.palette.successLight : "",
        color: !value ? `${theme.palette.secondary}` : "",
        borderColor: !value ? `${theme.palette.secondary}` : "",
        width: "fit-content",
        height: "100%",
        padding: "0",
        // Make the button look "indented" when pressed
        borderTop: value ? ".12rem solid" : ".08rem solid",
        borderLeft: value ? ".12rem solid" : ".08rem solid",
        borderRight: value ? ".08rem solid" : ".12rem solid",
        borderBottom: value ? ".08rem solid" : ".12rem solid",
        boxShadow:
          value === true
            ? `inset .08rem .08rem 0 0 1 ${theme.palette.border}`
            : `.08rem .08rem 0 0 1 ${theme.palette.border}`,
        ...style,
      }}
      onClick={() => {
        callback();
      }}
      icon={icon}
    >
      {label ? label : null}
    </Button>
  );
};
