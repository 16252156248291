import Image from "next/image";
import React from "react";
import { Description, Grid, Text } from "sparkl-ui";
import { useGenieDispatch } from "../use_genie";
import { InfoPopup } from "./info_popup";
import { LabeledParam } from "./labeled_param";
import { Slider } from "./slider";

import a from "../../../public/images/examples/scale/1.png";
import b from "../../../public/images/examples/scale/5.png";
import c from "../../../public/images/examples/scale/10.png";
import d from "../../../public/images/examples/scale/15.png";

export interface GuidanceScaleProps {
  scale?: number;
}

export const GuidanceScaleParam: React.FC<GuidanceScaleProps> = ({ scale }) => {
  const genieDispatch = useGenieDispatch();

  let imgSrcs = [a, b, c, d];
  let values = [1, 5, 10, 15];
  // The following four images are generated with the same prompt and seed,
  // but ascending guidances:
  const descriptionContent = (
    <Grid.Container direction="column">
      {/*padding above the <p> looks a little bit strange here*/}
      <Text>
        Guidance controls how strongly the image tries to match your text
      </Text>
      <Text>
        {'"A tiger in a beautiful forest at sunset" with the same seed and guidance values of 1, 5, 10, or 15:'}
      </Text>
      <Grid>
        <Grid.Container gap={0.3}>
          {imgSrcs.map((src, i) => {
            return (
              <Grid xs={12} key={i} justify="space-around">
                <Image
                  width="160px"
                  height="160px"
                  src={src}
                  style={{ borderRadius: "3px" }}
                  alt={`'a tiger in a beautiful forest at sunset' with guidance ${values[i]}`}
                  placeholder="blur"
                />
              </Grid>
            );
          })}
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );

  return (
    <LabeledParam
      label={
        <>
          <Description title={"Guidance"} />
          <InfoPopup content={descriptionContent} />
        </>
      }
    >
      <Slider
        value={scale ?? 0}
        onChange={(e) => genieDispatch({ type: "setScale", scale: e })}
        min={2}
        max={32}
        step={0.5}
      />
    </LabeledParam>
  );
};
