import React from "react";

export interface ParamsGridProps {
  children: React.ReactNode;
}

export const ParamsGrid: React.FC<ParamsGridProps> = ({ children }) => {
  return (
    <div
      style={{
        display: "grid",
        gridGap: "1rem",
        gridTemplateColumns: "repeat(auto-fit, minmax(12rem, 1fr))",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
        height: "100%",
        paddingLeft: "0.5rem",
        paddingRight: "0.5rem",
      }}
    >
      {children}
    </div>
  );
};
