import { NextPage } from "next";
import Head from "next/head";
import { FinishedPrompt } from "../utils/prompt_types";

interface PermalinkHeadProps {
  permalinkMetadata: Record<string, string>;
}

function slice_and_ellipse(str: string, length: number) {
  if (str.length > length) {
    return str.slice(0, length) + "...";
  } else {
    return str;
  }
}

export function getPermalinkMetadataForPrompt(
  prompt: FinishedPrompt
): Record<string, string> {
  const prompt_text = prompt.params.sharedParams.prompts[0].text;
  return {
    title: `"${slice_and_ellipse(prompt_text, 80)}" via Sparkl ✨🎨`,
    description: `"${slice_and_ellipse(prompt_text, 200)}" via Sparkl ✨🎨`,
    "twitter:card": "summary_large_image",
    "twitter:title": `"${prompt_text.slice(0, 100)}" via Sparkl ✨🎨`,
    "twitter:description": `"${prompt_text.slice(0, 500)}" via Sparkl ✨🎨`,
    "og:image": prompt.outputs.combined_image ?? prompt.outputs.image_urls[0],
    "twitter:image":
      prompt.outputs.combined_image ?? prompt.outputs.image_urls[0],
    "twitter:image:src":
      prompt.outputs.combined_image ?? prompt.outputs.image_urls[0],
    "twitter:image:alt": `Synthesized image by Sparkl for ${slice_and_ellipse(
      prompt_text,
      300
    )}`,
  };
}

export const PermalinkHead: NextPage<PermalinkHeadProps> = ({
  permalinkMetadata,
}) => {
  return (
    <Head>
      {"title" in permalinkMetadata && (
        <title>{permalinkMetadata["title"]}</title>
      )}
      {Object.entries(permalinkMetadata).map(([key, value]) => {
        if (key.startsWith("og")) {
          return <meta key={key} property={key} content={value} />;
        } else {
          return <meta key={key} name={key} content={value} />;
        }
      })}
    </Head>
  );
};

export default PermalinkHead;
