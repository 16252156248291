// This component composes the inputs with the specific params for this model

import React from "react";
import { GuidanceScaleParam } from "../generator_subcomponents/condition_scale";
import { ImageSizeParam } from "../generator_subcomponents/image_size";
import { NumImagesParam } from "../generator_subcomponents/num_images";
import { ParamsGrid } from "../generator_subcomponents/params_grid";
import { SeedParam } from "../generator_subcomponents/seed";
import { StepsParam } from "../generator_subcomponents/steps";
import { NegativePromptBox} from "../generator_subcomponents/negative_promptbox";
import { useGenie } from "../use_genie";
import { Verdant2Params } from "./model_generation_component_types";

export const Verdant2: React.FC = () => {
  const params = useGenie();
  const verdant2Params = params.allModelsParams.verdant2 as Verdant2Params;

  return (
    <ParamsGrid>
      {/*<NegativePromptBox/>*/}
      <NumImagesParam num={verdant2Params.num_images} />
      {params.viewProps.mode !== "img2img" && (
        <ImageSizeParam
          width={verdant2Params.width}
          height={verdant2Params.height}
        />
      )}
      <SeedParam seed={verdant2Params.seed} />
      <GuidanceScaleParam scale={verdant2Params.scale} />
      <StepsParam steps={verdant2Params.ddim_steps} />
    </ParamsGrid>
  );
};
