import { NextPage } from "next";
import { Button, Text, useTheme } from "sparkl-ui";
import { creditCost, timeEstimate } from "../../../utils/credit_calculation";
import { Sparkles } from "../../icons";
import {
  disabledForStatus,
  imageForStatus,
  JobStatus,
} from "../generate_button";
import { getSingleModelParams } from "../genie_reducer";
import { useGenie } from "../use_genie";

export interface SmartButtonProps {
  onClick: () => void;
  status: JobStatus;
}

export const SmartButton: NextPage<SmartButtonProps> = ({
  onClick,
  status,
}) => {
  const genieParams = useGenie();
  const creditCostNum = creditCost(getSingleModelParams(genieParams));
  const timeEstimateText = timeEstimate(getSingleModelParams(genieParams));

  const theme = useTheme();
  const disabled = disabledForStatus(status);
  const icon = imageForStatus(status);
  const disabledSuccessStyle = disabled
    ? {
        backgroundColor: theme.palette.successLight,
        borderColor: theme.palette.successLight,
        color: "#fff",
      }
    : {};

  return (
    <Button
      shadow
      title="Generate"
      type="success"
      onClick={onClick}
      height={"4rem"}
      style={{
        ...disabledSuccessStyle,
        height: "4rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        minWidth: "none",
        width: "100%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          height: "100%",
          width: "100%",
          lineHeight: "1rem",
        }}
      >
        <div
          style={{
            height: "3rem",
            width: "3rem",
            position: "relative",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          {icon}
        </div>
        <Text font={"1.5rem"} pb={"0.3rem"} pr={"1rem"}>
          Generate{" "}
        </Text>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Text small>{`${
            creditCostNum === 1
              ? creditCostNum + " credit"
              : creditCostNum + " credits"
          }`}</Text>
          <span
            style={{
              display: "flex",
              flexDirection: "row",
            }}
          >
            {/* The current font has _really_ _really_ bad tildes */}
            <Text style={{ fontFamily: "serif", height: "100%" }}>{`~`}</Text>
            <Text small>{`${timeEstimateText}`}</Text>
          </span>
        </div>
      </div>
    </Button>
  );
};
