import React from "react";
import NextLink from "next/link";
import useSWR from "swr";
import { fetcher } from "../../../utils/swr";

export const Credits: React.FC = () => {
  const { data, error } = useSWR("/api/balance", fetcher);
  const credits = data?.balance > 0 ? data.balance : 0;

  return (
    <>
      {/* <NextLink href="/profile?tab=credits"> */}
      {/* <Button ghost auto type="warning"> */}
      {/* <Link
        font={upMD ? "1rem" : "1.38rem"}
        color
        width={upMD ? "auto" : "100%"}
        style={{
          wordBreak: "keep-all",
        }}
      > */}
      {credits.toFixed(1)} Credits
      {/* </Button> */}
      {/* </Link> */}
      {/* </NextLink> */}
    </>
  );
};
