import { NextPage } from "next";
import { ReactNode } from "react";
import { Card, Grid, Text } from "sparkl-ui";

export interface FeatureCalloutProps {
  icon: ReactNode;
  title: string;
  text: string;
}

export const FeatureCalloutCell: NextPage<FeatureCalloutProps> = ({
  icon,
  title,
  text,
}) => {
  return (
    <Grid xs={24}>
      <Card>
        <Grid.Container justify="center" alignContent="center">
          <Grid
            xs={4}
            justify="flex-start"
            alignContent="center"
            alignItems="center"
          >
            {icon}
          </Grid>
          <Grid xs={20}>
            <Grid.Container alignContent="center" paddingLeft="15px">
              <Grid xs={24}>
                <Text h4 margin="0px" style={{ textAlign: "left" }}>
                  {title}
                </Text>
              </Grid>
              <Grid xs={24}>
                <Text margin="0px" style={{ textAlign: "left" }}>
                  {text}
                </Text>
              </Grid>
            </Grid.Container>
          </Grid>
        </Grid.Container>
      </Card>
    </Grid>
  );
};

export const EmbeddedFeatureCalloutCell: NextPage<FeatureCalloutProps> = ({
  icon,
  title,
  text,
}) => {
  return (
    <Grid xs={12} sm={8}>
      <Card>
        <Grid.Container alignContent="center">
          <Grid xs={24}>
            <Grid.Container justify="center" alignContent="center">
              <Grid
                xs={4}
                justify="flex-start"
                alignContent="center"
                alignItems="center"
              >
                {icon}
              </Grid>
              <Grid xs={20}>
                <Text
                  h5
                  margin="0px"
                  style={{ textAlign: "left", paddingLeft: "15px" }}
                >
                  {title}
                </Text>
              </Grid>
            </Grid.Container>
          </Grid>
          <Grid xs={24}>
            <Text margin="0px" style={{ textAlign: "left", fontSize: "12px" }}>
              {text}
            </Text>
          </Grid>
        </Grid.Container>
      </Card>
    </Grid>
  );
};
