import { Text, useMediaQuery, Image } from "sparkl-ui";
import logo from "../../../public/images/logo.png";

// Using both xs and upMD to have different performance on mobile and iPad
// Specifically - the navbar should be much smaller on mobile, but looks quite nice as-is on iPad
export const SparklLogo = () => {
  let xs = useMediaQuery("xs");
  // why is this using geist Image?
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: "1rem",
        cursor: "pointer",
      }}
    >
      <Image
        src={logo.src}
        draggable={false}
        alt="sparkl logo"
        title="Logo"
        style={{
          borderRadius: "48%",
          maxHeight: xs ? "1.62rem" : "3rem",
          minHeight: "1rem",
          verticalAlign: "middle",
        }}
        marginLeft={xs ? ".32rem" : ".68rem"}
        marginRight="-.32rem"
      />
      {xs && (
        <Text h4 margin={0}>
          Sparkl
        </Text>
      )}
      {!xs && (
        <Text h2 margin={0}>
          Sparkl
        </Text>
      )}
    </div>
  );
};
