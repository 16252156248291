import { NextPage } from "next";
import { ChangeEvent, useState } from "react";
import { useModal, Modal, Textarea, Text, Button } from "sparkl-ui";

interface ReportProps {
  promptId: number;
}

export const ReportMenu: NextPage<ReportProps> = ({ promptId }) => {
  const [message, setMessage] = useState<string>("");
  const [submitted, setSubmitted] = useState<boolean>(false);
  const handler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setMessage(e.target.value);
    console.log(e.target.value);
  };

  async function submit() {
    setSubmitted(true);

    await fetch(`/api/post/${promptId}/report`, {
      method: "POST",
      body: message,
    });
  }

  return (
    <div>
      <Text h3> Report </Text>
      {submitted ? (
        <Text> Thank you for your report! </Text>
      ) : (
        <div>
          <Text p> Whats wrong with this post? </Text>
          <Textarea
            width="100%"
            value={message}
            onChange={handler}
            marginBottom="1rem"
          />
          <Button type="success" onClick={submit} disabled={message === ""}>
            {" "}
            Submit{" "}
          </Button>
        </div>
      )}
    </div>
  );
};
