import { NextPage } from "next";
import NextLink from "next/link";
import { Avatar, Button, Grid, Link, User } from "sparkl-ui";
import { dev_maybeMockSession } from "../../utils/devtools";
import { useSession } from "next-auth/react";
import React, { Dispatch } from "react";
import { TabState, useTabDispatch } from "./tabnav";

export interface ProfileLinkProps {}

export const ProfileLink: React.FC<ProfileLinkProps> = ({}) => {
  const session = dev_maybeMockSession(useSession());
  const tabDispatch = useTabDispatch();

  const ProfileLink = (
    <NextLink href={"/profile/?tab=recents"} passHref>
      <Link
        color
        onClick={() => tabDispatch({ type: "switchTab", tab: "1", scroll: 0 })}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            height: "100%",
            textAlign: "center",
          }}
        >
          More
          <br />
          Recent
          <br />
          Images
        </div>
      </Link>
    </NextLink>
  );
  //only return link if session is defined
  if (session) {
    return ProfileLink;
  } else {
    return <></>;
  }
};
