import { Description } from "sparkl-ui";
import { Images1, Images4, Images9 } from "../../icons";
import { useGenie, useGenieDispatch } from "../use_genie";
import { ButtonSelect } from "./button_select";
import { LabeledParam } from "./labeled_param";

export type NumImages = 1 | 4 | 9;

export const NumImagesParam: React.FC<{ num: number }> = ({ num }) => {
  const genieDispatch = useGenieDispatch();

  function setNumImages(n: NumImages) {
    //console.log("Setting to " + n);
    genieDispatch({
      type: "setNumImages",
      numImages: n,
    });
  }

  const numImageOptions = [
    {
      key: "1",
      icon: <Images1 />,
      callback: () => setNumImages(1),
    },
    {
      key: "4",
      icon: <Images4 />,
      callback: () => setNumImages(4),
    },
    {
      key: "9",
      icon: <Images9 />,
      callback: () => setNumImages(9),
    },
  ];

  return (
    <LabeledParam label={<Description title={`${num} images`} />}>
      <ButtonSelect options={numImageOptions} selectedKey={String(num)} />
    </LabeledParam>
  );
};
