import { FC } from "react";

export interface TabButtonProps {
  label: JSX.Element;
}

export const TabButton: FC<TabButtonProps> = ({ label }) => {
  return (
    <div
      style={{
        width: "100%",
        height: "2rem",
        display: "flex",
        alignItems: "center",
      }}
    >
      {label}
    </div>
  );
};
