import Image from "next/image";
import React from "react";
import { Description, Grid, Text } from "sparkl-ui";
import { useGenieDispatch } from "../use_genie";
import { InfoPopup } from "./info_popup";
import { LabeledParam } from "./labeled_param";
import { Slider } from "./slider";
export interface AestheticProps {
  reaction_loss?: number;
}

import a from "../../../public/images/examples/aesthetic_vqgan/0.png";
import b from "../../../public/images/examples/aesthetic_vqgan/33.png";
import c from "../../../public/images/examples/aesthetic_vqgan/66.png";
import d from "../../../public/images/examples/aesthetic_vqgan/99.png";

export const AestheticParam: React.FC<AestheticProps> = ({ reaction_loss }) => {
  const genieDispatch = useGenieDispatch();

  let imgSrcs = [a, b, c, d];
  let values = [0.0, 0.33, 0.66, 1.0];
  // The following four images are generated with the same prompt and seed,
  // but ascending aesthetic values:
  const descriptionContent = (
    <Grid.Container direction="column">
      <Text>
        Aesthetic makes the image more similar to prompts others liked,
        resulting in more colorful, abstract images
      </Text>
      <Text>
        {'"Moomin revolution" with aesthetic values 0, 0.3, 0.7, and 1:'}
      </Text>
      <Grid>
        <Grid.Container gap={0.3}>
          {imgSrcs.map((src, i) => {
            return (
              <Grid xs={12} key={i} justify="space-around">
                <Image
                  width="160px"
                  height="160px"
                  src={src}
                  style={{ borderRadius: "3px" }}
                  alt={`'moomin revolution' with aesthetic ${values[i]}`}
                  placeholder="blur"
                />
              </Grid>
            );
          })}
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );

  return (
    <LabeledParam
      label={
        <>
          <Description title={"Aesthetic"} />
          <InfoPopup content={descriptionContent} />
        </>
      }
    >
      <Slider
        value={reaction_loss ?? 0}
        onChange={(e) =>
          genieDispatch({ type: "setReactionLoss", reactionLoss: e })
        }
        min={0.0}
        max={1.0}
        step={0.1}
      />
    </LabeledParam>
  );
};
