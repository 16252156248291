import { NextPage } from "next";
import { ReactNode } from "react";
import { useTheme } from "sparkl-ui";
import { Navbar } from "./navbar/navbar";

export interface Props {
  topNavHeight: string;
  children: ReactNode;
}

export const NoGeneratorLayout: NextPage<Props> = ({
  topNavHeight,
  children,
}) => {
  const theme = useTheme();
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "minmax(0,1fr)",
        gridTemplateRows: `${topNavHeight} 1fr`,
        gridTemplateAreas: `"header" "content"`,
        gridGap: `0 1rem`,
        width: "100vw",
        backgroundColor: theme.palette.accents_1,
        alignItems: "start",
      }}
    >
      <nav
        style={{
          gridArea: "header",
          position: "sticky",
          top: 0,
          zIndex: 1,
          backgroundColor: theme.palette.background,
          height: "100%",
        }}
      >
        <Navbar />
      </nav>
      <div
        style={{
          gridArea: "content",
          paddingRight: "1rem",
        }}
      >
        {children}
      </div>
    </div>
  );
};
