import React, { MutableRefObject, useLayoutEffect, useState } from "react";
import useResizeObserver from "@react-hook/resize-observer";

export interface useSizeProps {
  initialRef: MutableRefObject<HTMLElement | null>;
  initialSize?: DOMRect | undefined;
}

//https://github.com/jaredLunde/react-hook/issues/165

export const useSize = ({
  initialRef,
  initialSize = undefined,
}: useSizeProps) => {
  const [size, setSize] = useState<DOMRect | undefined>();
  const [node, ref] = useState<HTMLElement | null>(null);

  useLayoutEffect(() => {
    node !== null && setSize(node.getBoundingClientRect());
  }, [node]);

  // Where the magic happens
  useResizeObserver(node, (entry) => {
    setSize(entry.contentRect);
  });

  return { ref, size }; // this is also a little wierd since ref isn't a ref object but a setter function, but works if using it in the context of <div ref={ref}>
};
