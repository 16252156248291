import Home from "@geist-ui/icons/home";
import { NextPage } from "next";
import { ReactNode, useState } from "react";
import { useTheme } from "sparkl-ui";
import { Sparkles } from "../icons";
import { GeneratorArea } from "./generator_area";
import { Navbar } from "./navbar/navbar";
import { TabNav, TabState } from "./tabnav";
import { TabButton } from "./tabnav_button";
import { useRouter } from "next/router";
import { useGenieDispatch } from "../genie/use_genie";

export interface PortraitGridProps {
  topNavHeight: string;
  children: ReactNode;
}

export const PortraitGrid: NextPage<PortraitGridProps> = ({
  topNavHeight,
  children,
}) => {
  const router = useRouter();
  const genieDispatch = useGenieDispatch();
  const theme = useTheme();
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns: "1fr",
        gridTemplateRows: `${topNavHeight} 1fr`,
        gridTemplateAreas: `"header" "content"`,
        width: "100vw",
        backgroundColor: theme.palette.accents_1,
      }}
    >
      <nav
        style={{
          gridArea: "header",
          position: "sticky",
          top: 0,
          zIndex: 3,
          backgroundColor: theme.palette.background,
          height: topNavHeight,
        }}
      >
        <Navbar />
      </nav>
      <div
        style={{
          gridArea: "content",
          backgroundColor: theme.palette.background,
          maxWidth: "100vw",
        }}
      >
        <TabNav>
          {{
            label: <TabButton label={<Sparkles />} />,
            content: <GeneratorArea topNavHeight={topNavHeight} />,
            onSecondClick: () => {
              genieDispatch({ type: "reset" });
            },
          }}
          {{
            label: <TabButton label={<Home />} />,
            content: children,
            onSecondClick: () => {
              router.push("/");
            },
          }}
        </TabNav>
      </div>
    </div>
  );
};
