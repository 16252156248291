import React, { CSSProperties } from "react";
import { Button } from "sparkl-ui";
import { FinishedPrompt } from "../../utils/prompt_types";
import { useGenieDispatch, useGenieFocus } from "../genie/use_genie";
import { Sparkles } from "../icons";

export const RemixButton: React.FC<{
  prompt: FinishedPrompt;
  style: CSSProperties;
}> = ({ prompt, style }) => {
  const genieDispatch = useGenieDispatch();
  const focusGenie = useGenieFocus();

  const remixPrompt = () => {
    //console.log("remixing", prompt);
    genieDispatch({
      type: "switchModel",
      model: prompt.params.model,
    });

    genieDispatch({
      type: "remix",
      model: prompt.params.model,
      sharedParams: prompt.params.sharedParams,
      modelSpecificParams: prompt.params.modelParams,
      remixPromptId: prompt.id,
    });

    genieDispatch({
      type: "remixSetMode",
    });

    focusGenie();
  };

  return (
    <Button
      style={style}
      auto
      onClick={remixPrompt}
      className="reactionButton"
      title="Remix"
    >
      <Sparkles />
    </Button>
  );
};
