import { NextPage } from "next";
import { Model } from "../../../utils/feed_types";

export function generateRandomSeed() {
  /* We'd ideally return a random 32 bit int... buuuuut
       it's so much easier to just prevent the text field
       from being longer than 9 digits! */
  return Math.floor(Math.random() * 999999999);
}

export interface UIPrompt {
  text: string;
  weight: number;
}

// https://github.com/dryadsystems/sprkpnt/blob/main/api/params.md

//Params that should be persisted as you switch models
export interface SharedParams {
  prompts: UIPrompt[];
  hidden?: boolean;
}
// And we don't trust the server to not send us additional params that we don't want!
const sharedParamsKeys = ["prompts"];

export const defaultSharedParams: SharedParams = {
  prompts: [{ text: "", weight: 1 }],
  hidden: false,
};

// Each model should extend this to denote that it is a model-specific set of params
export interface ModelParams extends CommonParams {}

//Params that are common between models, but should not be persisted
export interface CommonParams {
  num_images: number;
  height: number;
  width: number;
  seed: number;
}
export const commonParamsKeys = ["num_images", "height", "width", "seed"];

export interface InitImageParams {
  init_image?: string;
  init_image_type?: string; // none, init, inpaint
  init_image_mask?: string; //
  strength: number;
}
export const initImageParamsKeys = [
  "init_image",
  "init_image_type",
  "init_image_mask",
  "strength",
];
export const defaultInitImageParams: InitImageParams = {
  strength: 0.8,
};

export interface DalleParams extends CommonParams {
  gen_top_k?: number;
  gen_top_p?: number;
  temperature?: number;
  cond_scale: number;
}
export const dalleParamsKeys = [
  ...commonParamsKeys,
  "gen_top_k",
  "gen_top_p",
  "temperature",
  "cond_scale",
];

export const defaultDalleParams: DalleParams = {
  num_images: 9,
  height: 256,
  width: 256,
  seed: generateRandomSeed(),
  cond_scale: 10.0,
};

export interface VQGANParams extends CommonParams, InitImageParams {
  step_size: number;
  cutn: number;
  cut_pow: number;
  max_iterations: 300;
  fade: number;
  dwell: number;
  video: boolean;
  reaction_loss: number;
}
export const vqganParamsKeys = [
  ...commonParamsKeys,
  ...initImageParamsKeys,
  "step_size",
  "cutn",
  "cut_pow",
  "max_iterations",
  "fade",
  "dwell",
  "video",
  "reaction_loss",
];

export const defaultVQGANParams: VQGANParams = {
  num_images: 1,
  height: 512,
  width: 512,
  seed: generateRandomSeed(),
  step_size: 0.1,
  cutn: 64,
  cut_pow: 1.0,
  max_iterations: 300,
  fade: 0.0,
  dwell: 0.0,
  video: false,
  reaction_loss: 0.0,
  ...defaultInitImageParams,
};

export interface VerdantParams extends CommonParams, InitImageParams {
  ddim_steps: number;
  scale: number;
  ddim_eta: number;
  f: number;
}
export const verdantParamsKeys = [
  ...commonParamsKeys,
  ...initImageParamsKeys,
  "ddim_steps",
  "scale",
  "ddim_eta",
  "f",
];

export const defaultVerdantParams: VerdantParams = {
  num_images: 1,
  height: 512,
  width: 512,
  seed: generateRandomSeed(),
  ddim_steps: 50,
  scale: 7.5,
  ddim_eta: 0.0,
  f: 8,
  ...defaultInitImageParams,
};

export interface Verdant2Params extends CommonParams, InitImageParams {
  ddim_steps: number;
  scale: number;
  ddim_eta: number;
  f: number;
  negative_prompts: UIPrompt[];
}
export const verdant2ParamsKeys = [
  ...commonParamsKeys,
  ...initImageParamsKeys,
  "ddim_steps",
  "scale",
  "ddim_eta",
  "f",
  "negative_prompts",
];

export const defaultVerdant2Params: Verdant2Params = {
  num_images: 1,
  height: 512,
  width: 512,
  seed: generateRandomSeed(),
  ddim_steps: 50,
  scale: 7.5,
  ddim_eta: 0.0,
  f: 8,
  negative_prompts: [{ text: "", weight: 1 }],
  ...defaultInitImageParams,
};

export interface LatentParams extends CommonParams, InitImageParams {
  plms: boolean;
  ddim_eta: number;
  scale: number;
}
export const latentParamsKeys = [
  ...commonParamsKeys,
  ...initImageParamsKeys,
  "plms",
  "ddim_eta",
  "n_samples",
  "scale",
];

export const defaultLatentParams: LatentParams = {
  num_images: 1,
  height: 512,
  width: 512,
  seed: generateRandomSeed(),
  plms: false,
  ddim_eta: 0.0,
  scale: 7.5,
  ...defaultInitImageParams,
};

// A union type for model specific params
export type ModelSpecificParams =
  | DalleParams
  | VQGANParams
  | VerdantParams
  | Verdant2Params
  | LatentParams;

// AllModelsParams is needed on the generator - so that you can consistently store
// all the params for the various models as the user clicks between them

export type AllModelsParams = Record<Model, ModelSpecificParams>;

export const defaultParams: AllModelsParams = {
  dalle: defaultDalleParams,
  vqgan: defaultVQGANParams,
  diffuse: defaultLatentParams,
  verdant: defaultVerdantParams,
  waifu: defaultVerdantParams,
  verdant2: defaultVerdant2Params,
};

// SingleModelParams is needed for sending and receiving params
// This is because it wants to only store the params for a single model
export interface SingleModelParams {
  model: Model;
  sharedParams: SharedParams;
  modelParams: ModelParams;
  remixPromptId?: number;
}

// This is used to take the unstructured params array from the server, and
// type it as a model-specific set of params
function addFromObjectToObjectIfInList(obj1: any, obj2: any, list: string[]) {
  for (const key of list) {
    if (key in obj1) {
      obj2[key] = obj1[key];
    }
  }
}
export function singleModelParamsFromParams(
  p: any,
  m: Model
): SingleModelParams {
  let sharedParams = { ...defaultSharedParams };
  addFromObjectToObjectIfInList(p, sharedParams, sharedParamsKeys);
  switch (m) {
    case "dalle":
      let dalle = { ...defaultDalleParams };
      addFromObjectToObjectIfInList(p, dalle, dalleParamsKeys);
      return { model: "dalle", sharedParams, modelParams: dalle };
    case "vqgan":
      let vqgan = { ...defaultVQGANParams };
      addFromObjectToObjectIfInList(p, vqgan, vqganParamsKeys);
      return { model: "vqgan", sharedParams, modelParams: vqgan };
    case "verdant":
      let verdant = { ...defaultVerdantParams };
      addFromObjectToObjectIfInList(p, verdant, verdantParamsKeys);
      return { model: "verdant", sharedParams, modelParams: verdant };
    case "waifu":
      let waifu = { ...defaultVerdantParams };
      addFromObjectToObjectIfInList(p, waifu, verdantParamsKeys);
      return { model: "waifu", sharedParams, modelParams: waifu };
    case "verdant2":
      let verdant2 = { ...defaultVerdant2Params };
      addFromObjectToObjectIfInList(p, verdant2, verdantParamsKeys);
      return { model: "verdant2", sharedParams, modelParams: verdant2 };
    case "diffuse":
      let latent = { ...defaultLatentParams };
      addFromObjectToObjectIfInList(p, latent, latentParamsKeys);
      return { model: "diffuse", sharedParams, modelParams: latent };
  }
}
export function paramsFromSingleModelParams(p: SingleModelParams) {
  return { ...p.sharedParams, ...p.modelParams };
}
interface ModelGenerationComponentProps {
  params: SingleModelParams;
  setParams: (params: SingleModelParams) => void;
}

export type ModelGenerationComponent = NextPage<ModelGenerationComponentProps>;
export type Mode = "txt2img" | "img2img" | "img2vid" | "txt2vid";
