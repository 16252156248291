import { FinishedPrompt } from "./prompt_types";

interface FeedFailure {
  status: "failure";
  code: string;
}

export type UserReaction = "like";
export type AdminReaction =
  | "masterpiece"
  | "curated"
  | "great"
  | "okay"
  | "bad"
  | "tos";
export const adminReactions = [
  "masterpiece",
  "curated",
  "great",
  "okay",
  "bad",
  "tos",
];
export type Reaction = UserReaction | AdminReaction;

export type Reactions = Record<string, Reaction>;

interface FeedSuccess {
  status: "success";
  prompts: FinishedPrompt[];
}

// TODO: DRY
export type Model = "dalle" | "vqgan" | "diffuse" | "verdant" | "waifu" | "verdant2";
export const models: Model[] = ["dalle", "vqgan", "diffuse", "verdant", "waifu", "verdant2"];
export const img2img_models: Model[] = ["verdant", "waifu", "verdant2"];
export const models_with_feeds: Model[] = [
  "verdant",
  "dalle",
  "diffuse",
  "vqgan",
  "waifu",
  "verdant2"
]; // models we display in main feed

export type FeedResponse = FeedSuccess | FeedFailure;
