import { Children } from "react";

export interface LabeledParamProps {
  label: JSX.Element;
  children: React.ReactNode;
}

export const LabeledParam: React.FC<LabeledParamProps> = ({
  label,
  children,
}) => {
  return (
    <div
      style={{
        width: "auto",
        minHeight: "4rem",
        display: "grid",
        gridTemplateAreas: '"description description" "content content"',
        gridTemplateRows: "auto 1fr",
        gridTemplateColumns: "auto auto",
        alignItems: "start",
        padding: "0.12rem",
        height: "100%",
      }}
    >
      <div
        style={{
          gridArea: "description",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          lineHeight: "1rem",
          height: "2rem",
        }}
      >
        {label}
      </div>
      <div
        style={{
          gridArea: "content",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
          width: "100%",
          height: "100%",
        }}
      >
        {children && Children.map(children, (child) => child)}
      </div>
    </div>
  );
};
