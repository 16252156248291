import FastForward from "@geist-ui/icons/fastForward";
import Gift from "@geist-ui/icons/gift";
import Save from "@geist-ui/icons/save";
import { signIn } from "next-auth/react";
import { ReactElement } from "react";
import { Button, Grid, Modal, useModal } from "sparkl-ui";
import { FeatureCalloutCell } from "./feature_cell";

function LoginAdvert() {
  return (
    <Grid.Container justify="center" alignContent="center">
      <Grid xs={24} justify="center">
        <Grid.Container justify="center" alignContent="center" gap={2}>
          <FeatureCalloutCell
            icon={<FastForward color="blue" />}
            title="Blazing fast generations"
            text="Results in as little as 15s"
          />
          <FeatureCalloutCell
            icon={<Gift color="blue" />}
            title="Free forever"
            text="Get 25 free daily credits"
          />
          <FeatureCalloutCell
            icon={<Save color="blue" />}
            title="Save & Share"
            text="Your generations are saved -  showcase your best work"
          />
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );
}

interface LoginModalProps {
  callbackUrl?: string;
  clearUI?: () => void;
}

export const useLoginModal = ({ callbackUrl, clearUI }: LoginModalProps) => {
  const { visible, setVisible, bindings } = useModal();

  const toggleLogin = () => {
    //console.log("Setting login visible to", !visible);
    clearUI && clearUI();
    setVisible(!visible);
  };

  const LoginModal = (): ReactElement => (
    <Modal width="700px" {...bindings}>
      <Grid.Container justify="center" alignContent="center" padding={2}>
        <Grid xs={24} justify="center">
          <h2>Login to get started</h2>
        </Grid>
        <Grid sm={12} justify="center">
          <LoginAdvert />
        </Grid>
        <Grid sm={12} justify="center">
          <Grid.Container justify="center" alignContent="center">
            <Grid xs={24} justify="center" padding="5px">
              <Button
                type="success-light"
                onClick={() => signIn("google", { callbackUrl: callbackUrl })}
              >
                Sign in with Google
              </Button>
            </Grid>
            <Grid xs={24} justify="center" padding="5px">
              <span style={{ fontSize: "12px" }}>
                By using Sparkl, you agree to our{" "}
                <a href="https://docs.sparklpaint.com/terms-of-service">
                  Terms of Service{" "}
                </a>
                and{" "}
                <a href="https://docs.sparklpaint.com/privacy-policy">
                  Privacy Policy
                </a>
              </span>
            </Grid>
            {/*
            <Grid xs={24} justify="center" padding="5px">
              <Button type="success-light" onClick={() => signIn("twitter")}>
                Sign in with Twitter
              </Button>
            </Grid>
  */}
          </Grid.Container>
        </Grid>
      </Grid.Container>
    </Modal>
  );

  return {
    toggleLogin,
    LoginModal,
  };
};
