import { NextPage } from "next";
import { useSession } from "next-auth/react";
import {
  Button,
  ButtonGroup,
  Description,
  Grid,
  Link,
  Modal,
  useMediaQuery,
  useModal,
  useTheme,
  useToasts,
} from "sparkl-ui";
import { dev_maybeMockSession, dev_mockUserHeader } from "../../utils/devtools";
import {
  FinishedPrompt,
  GenerateParams,
  GenerateResponse,
} from "../../utils/prompt_types";
import Image from "next/image";
import { GenerateButton, JobStatus } from "../genie/generate_button";
import Download from "@geist-ui/icons/download";
import AlertTriangle from "@geist-ui/icons/alertTriangle";
import Eye from "@geist-ui/icons/eye";
import EyeOff from "@geist-ui/icons/eyeOff";
import Star from "@geist-ui/icons/star";
import User from "@geist-ui/icons/user";
import ImageIcon from "@geist-ui/icons/image";
import { saveAs } from "file-saver";
import { mutate } from "swr";
import { ReportMenu } from "./report";
import { SetAvatarMenu } from "./set_avatar";
import { useEffect, useState } from "react";
import { useLoginModal } from "../modals/login_modal";
import { mutateQueue, mutateShowcasedFeed } from "../../utils/swr";
import { unstable_serialize } from "swr/infinite";
import { AdminButtonBar } from "./button_bar";
import { isModerator } from "../../utils/roles";
import { useGenieDispatch, useGenieFocus } from "../genie/use_genie";
import {
  defaultSharedParams,
  defaultVerdantParams,
} from "../genie/model_generators/model_generation_component_types";

interface PostDetailsProps {
  prompt: FinishedPrompt;
  lightboxIndex: number;
}

// $id-$prompt-$index.png
function promptFileName(prompt: FinishedPrompt, index: number) {
  let promptText = prompt.params.sharedParams.prompts[0].text;
  promptText = promptText.replace(/ /g, "_");
  promptText = promptText.replace(/[^a-zA-Z0-9_]/g, "");
  promptText = promptText.substring(0, 150);

  let fileName = prompt.id.toString();
  if (promptText.length > 0) {
    fileName += "-" + promptText;
  }
  fileName += "-" + index;
  fileName += ".png";

  return fileName;
}

function upscaleTextForStatus(status: JobStatus): string {
  switch (status) {
    case "not_started":
    case "requesting":
      return "Upscale";
    case "in_progress":
      return "Upscaling";
    case "submitted":
      return "Submitted";
    case "done":
      return "Upscaled";
  }
}

function upscaleStatusFromPrompt(
  prompt: FinishedPrompt,
  index: number
): JobStatus {
  if (prompt.outputs.upscaled_image_urls) {
    if (prompt.outputs.upscaled_image_urls[index] === "pending") {
      return "in_progress";
    } else if (
      !prompt.outputs.upscaled_image_urls[index] ||
      prompt.outputs.upscaled_image_urls[index] === ""
    ) {
      return "not_started";
    } else {
      return "done";
    }
  }
  return "not_started";
}

// variation status:
// not_started, requesting, submitted

// upscale_status:
// not_started, requesting, submitted, in_progress, done

export const PostDetails: NextPage<PostDetailsProps> = ({
  prompt,
  lightboxIndex,
}) => {
  const session = dev_maybeMockSession(useSession());
  const { toggleLogin, LoginModal } = useLoginModal({});
  const genieDispatch = useGenieDispatch();
  const focusGenie = useGenieFocus();

  const theme = useTheme();

  const isAdmin = session && isModerator(session);
  const postIsByUser = session && session.user_id == prompt.user_id;
  const isSingleImage =
    prompt.outputs.image_urls.length === 1 || lightboxIndex > 0;
  const imageUrlIndex =
    prompt.outputs.image_urls.length === 1 ? 0 : lightboxIndex - 1;

  const upMD = useMediaQuery("md", { match: "up" });
  const upLg = useMediaQuery("lg", { match: "up" });

  // VariationStatus is reset whenever you change prompt or image
  const [variationStatus, setVariationStatus] =
    useState<JobStatus>("not_started");
  const [upscaleStatus, setUpscaleStatus] = useState<JobStatus>(
    upscaleStatusFromPrompt(prompt, imageUrlIndex)
  );

  useEffect(() => {
    setUpscaleStatus(upscaleStatusFromPrompt(prompt, imageUrlIndex));
  }, [prompt, imageUrlIndex]);

  //console.log("upscaleStatus", upscaleStatus);

  /* Report Modal */
  const { setVisible: setReportVisible, bindings: reportBindings } = useModal();
  const { setVisible: setAvatarVisible, bindings: avatarBindings } = useModal();

  const { setToast } = useToasts();

  const [imageType, setImageType] = useState<"avatar" | "cover">("avatar");

  function togglePrivate() {
    setToast({ text: "Not implemented yet", type: "error" });
  }

  function useAsInitialImage() {
    //console.log("remixing", prompt);
    genieDispatch({
      type: "remix",
      model: "verdant",
      sharedParams: defaultSharedParams,
      modelSpecificParams: defaultVerdantParams,
      remixPromptId: prompt.id,
    });

    genieDispatch({ type: "setMode", mode: "img2img" });

    genieDispatch({
      type: "setImageSize",
      height: prompt.params.modelParams.height,
      width: prompt.params.modelParams.width,
    });
    genieDispatch({
      type: "setInitImageURL",
      initImageURL: prompt.outputs.image_urls[imageUrlIndex],
    });

    focusGenie();
  }

  async function variations() {
    const body: GenerateParams = {
      type: "variants",
      params: prompt.params,
      originalPromptId: prompt.id,
      imageIndex: imageUrlIndex,
      imageURL: prompt.outputs.image_urls[imageUrlIndex],
    };

    if (!session) {
      toggleLogin();
      return;
    }

    setVariationStatus("requesting");

    await fetch(`/api/generate`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: dev_mockUserHeader(),
    })
      .then((r) => r.json())
      .then((resp: GenerateResponse) => {
        if (resp.status === "success") {
          mutateQueue({
            id: resp.prompt_id,
            params: body.params,
            status: "pending",
            date: Date.now() / 1000,
            user_id: resp.user_id,
            remix_of: body.originalPromptId,
          });
          mutate("/api/balance");
        } else {
          if (resp.overloaded) {
            // explain we're overloaded and try to upsell
          }
          setToast({ text: resp.code, type: "error" });
        }
      });

    setVariationStatus("submitted");

    setTimeout(() => {
      setVariationStatus("not_started");
    }, 1000);
  }

  async function upscale() {
    const body: GenerateParams = {
      type: "upscale",
      params: prompt.params,
      originalPromptId: prompt.id,
      imageIndex: imageUrlIndex,
      imageURL: prompt.outputs.image_urls[imageUrlIndex],
    };

    if (!session) {
      toggleLogin();
      return;
    }

    setUpscaleStatus("requesting");

    await fetch(`/api/generate`, {
      method: "POST",
      body: JSON.stringify(body),
      headers: dev_mockUserHeader(),
    })
      .then((r) => r.json())
      .then((resp: GenerateResponse) => {
        if (resp.status === "success") {
          mutate("/api/balance");
          mutate("/api/prompts?completed=true&id=" + prompt.id.toString);
        } else {
          if (resp.overloaded) {
            // explain we're overloaded and try to upsell
          }
          setToast({ text: resp.code, type: "error" });
        }
      });

    setUpscaleStatus("submitted");

    setTimeout(() => {
      setUpscaleStatus("in_progress");
    }, 1000);
  }

  function setAsAvatar() {
    setImageType("avatar");
    setAvatarVisible(true);
  }

  function setAsCover() {
    setImageType("cover");
    setAvatarVisible(true);
  }

  async function toggleHidden(hidden: boolean) {
    const response = {
      status: "success",
      prompts: [
        {
          ...prompt,
          hidden: !hidden,
        },
      ],
    };

    mutate(
      `/api/prompts?completed=true&id=${prompt.id}`,
      async () => {
        await fetch(
          `/api/post/${prompt.id}/hide?action=${hidden ? "unhide" : "hide"}`,
          {
            method: "POST",
          }
        );
        return response;
      },
      {
        optimisticData: response,
      }
    );
  }

  async function toggleShowcased(showcased: boolean) {
    const response = {
      status: "success",
      prompts: [
        {
          ...prompt,
          showcased: !showcased,
        },
      ],
    };

    await mutate(
      `/api/prompts?completed=true&id=${prompt.id}`,
      async () => {
        await fetch(
          `/api/post/${prompt.id}/showcase?action=${
            showcased ? "unshowcase" : "showcase"
          }`,
          {
            method: "POST",
          }
        );
        // sleep for 1 second to give the server time to update the showcase

        return response;
      },
      {
        optimisticData: response,
      }
    );
    mutateShowcasedFeed(prompt.user_id.toString());
  }

  function download() {
    if (isSingleImage) {
      if (upscaleStatus === "done" && prompt.outputs.upscaled_image_urls) {
        saveAs(
          prompt.outputs.upscaled_image_urls[imageUrlIndex],
          promptFileName(prompt, imageUrlIndex)
        );
      } else {
        saveAs(
          prompt.outputs.image_urls[imageUrlIndex],
          promptFileName(prompt, imageUrlIndex)
        );
      }
    } else {
      prompt.outputs.image_urls.map((url, index) => {
        saveAs(url, promptFileName(prompt, index));
      });
    }
  }

  function report() {
    setReportVisible(true);
  }

  const linkWithIconStyle = {
    display: "flex",
    alignItems: "center",
    gap: "0.3rem",
  };

  const myPostInfo = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gridGap: "1rem",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Link
        href="javascript:"
        onClick={() => toggleHidden(prompt.hidden ?? false)}
        color
      >
        {prompt.hidden ? (
          <div style={linkWithIconStyle}>
            <EyeOff size={"1em"} /> Make public
          </div>
        ) : (
          <div style={linkWithIconStyle}>
            <Eye size={"1em"} /> Make private
          </div>
        )}
      </Link>
      <Link
        href="javascript:"
        onClick={() => toggleShowcased(prompt.showcased ?? false)}
        color
      >
        {prompt.showcased ? (
          <div style={linkWithIconStyle}>
            <Star size={"1em"} /> Remove from my showcase
          </div>
        ) : (
          <div style={linkWithIconStyle}>
            <Star size={"1em"} /> Add to my showcase
          </div>
        )}
      </Link>
      {isSingleImage && (
        <Link href="javascript:" onClick={setAsAvatar} color>
          <div style={linkWithIconStyle}>
            <User size={"1em"} /> Set as avatar
          </div>
        </Link>
      )}
      {isSingleImage && (
        <Link href="javascript:" onClick={setAsCover} color>
          <div style={linkWithIconStyle}>
            <ImageIcon size={"1em"} /> Set as profile banner
          </div>
        </Link>
      )}
    </div>
  );

  const thisPostInfo = (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        gridGap: "1rem",
        flexWrap: "wrap",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Grid.Container gap={0.2}>
        <Grid xs={12} md={24} lg={12}>
          {isSingleImage && (
            <GenerateButton
              onClick={variations}
              text={
                variationStatus == "submitted" ? "Submitted!" : "Variations"
              }
              creditCost={
                prompt.params.modelParams.height *
                  prompt.params.modelParams.width <=
                512 * 512
                  ? 4
                  : 8
              }
              timeEstimate={
                prompt.params.modelParams.height *
                  prompt.params.modelParams.width <=
                512 * 512
                  ? "1 min"
                  : "2 mins"
              }
              fontSize={"16px"}
              status={variationStatus}
            />
          )}
        </Grid>
        <Grid xs={12} md={24} lg={12}>
          {isSingleImage && (
            <GenerateButton
              onClick={upscale}
              text={upscaleTextForStatus(upscaleStatus)}
              creditCost={2}
              timeEstimate={"30s"}
              fontSize={"16px"}
              status={upscaleStatus}
            />
          )}
        </Grid>
      </Grid.Container>
      <Link
        href="javascript:"
        color
        onClick={download}
        style={{ flexShrink: 0 }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
          <Download size={"1em"} />
          {isSingleImage
            ? upscaleStatus == "done"
              ? "Download image (upscaled)"
              : "Download image"
            : "Download all images"}
        </div>
      </Link>
      {isSingleImage && (
        <Link
          href="javascript:"
          color
          onClick={useAsInitialImage}
          style={{ flexShrink: 0 }}
        >
          <div style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
            <ImageIcon size={"1em"} />
            Use as initial image
          </div>
        </Link>
      )}
      <Link href="javascript:" color onClick={report} style={{ flexShrink: 0 }}>
        <div style={{ display: "flex", alignItems: "center", gap: "0.3rem" }}>
          <AlertTriangle size={"1em"} /> Report
        </div>
      </Link>
    </div>
  );

  const initImageUrl = (prompt.params.modelParams as any).init_image;
  const originalImage = (
    <div
      style={{ display: "flex", flexDirection: "column", alignItems: "center" }}
    >
      <Description title={"Original Image"} />
      {/* Temporary hardcoded pixel value - not being able to get this to do what I want */}
      {/* I think we'd ideally make this be ~60-80% of the maximum width - so it's still smaller than a post on mobile kinda */}
      <div
        style={{
          height: upMD ? "20vw" : "50vw",
          width: upMD ? "20vw" : "50vw",
          position: "relative",
        }}
      >
        <Image
          src={initImageUrl}
          alt="Initial image"
          layout="fill"
          objectFit="contain"
        />
      </div>
    </div>
  );

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        margin: "1rem",
        gridGap: "1rem",
      }}
    >
      {thisPostInfo}
      {postIsByUser && myPostInfo}
      {initImageUrl && originalImage}
      {isAdmin && (
        /* 
        This is some code duplication - TODO: DRY later 
        */
        <div>
          <Description title={"Admin"} />
          <ButtonGroup
            type="success"
            ghost
            margin={0}
            height={"3rem"}
            width={"100%"}
            style={{
              color: `${theme.palette.success}`,
              justifyContent: "space-evenly",
              border: "unset",
            }}
          >
            <AdminButtonBar prompt={prompt} />
          </ButtonGroup>
        </div>
      )}
      <Modal {...reportBindings}>
        <ReportMenu promptId={prompt.id} />
      </Modal>
      <Modal
        width={upMD ? (upLg ? "35vw" : "45vw") : "90vw"}
        {...avatarBindings}
      >
        <SetAvatarMenu
          prompt={prompt}
          type={imageType}
          index={imageUrlIndex}
          closeModal={() => setAvatarVisible(false)}
        />
      </Modal>
      <LoginModal />
    </div>
  );
};
