import "@yaireo/tagify/dist/tagify.css";
import { SessionProvider } from "next-auth/react";
import type { AppProps } from "next/app";
import { NextPage } from "next/types";
import { useState } from "react";
import { CookiesProvider } from "react-cookie";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { CssBaseline, GeistProvider, GeistUIThemes } from "sparkl-ui";
import Layout from "../components/layout";
import { TabNavProvider } from "../components/layouts/tabnav";
import { LayoutType } from "../components/layout";
import { SparklTheme } from "../components/sparkl-theme";
import { GoogleAnalytics } from "../components/_app/analytics";
import { SparklHead } from "../components/_app/head";

import "../public/fonts/moriston.css";
import "../styles/globals.css";
import "../styles/tagify.css";

export type NextPageWithCustomLayout<P = {}, IP = P> = NextPage<P, IP> & {
  layoutType: LayoutType;
};

type AppPropsWithLayout = AppProps & {
  Component: NextPageWithCustomLayout | NextPage;
};

export default function App({
  Component,
  pageProps: { session, ...pageProps },
}: AppPropsWithLayout) {
  const [themeType, setThemeType] = useState<string>("sparkl");
  const [customTheme, setCustomTheme] = useState<GeistUIThemes>(SparklTheme);

  const layoutType =
    "layoutType" in Component ? Component.layoutType : "default";

  return (
    <CookiesProvider>
      <SessionProvider session={session}>
        <GeistProvider themeType={themeType} themes={[customTheme]}>
          <CssBaseline />
          <SparklHead />
          <GoogleAnalytics />
          <TabNavProvider>
          <Layout layoutType={layoutType}>
            <Component {...pageProps} />
          </Layout>
          </TabNavProvider>
        </GeistProvider>
      </SessionProvider>
    </CookiesProvider>
  );
}
