import { GenericSelector } from "./generic_selector";
import { Model } from "../../utils/feed_types";
import { generatorWhiteList, ModelTagInfo } from "./model_tags";
import { Select, SelectProps } from "sparkl-ui";
import { LabeledModel } from "./labeled_model";
export interface ModelSelectorProps extends SelectProps {
  value: Model | "all";
  models?: ModelTagInfo[];
}

export const ModelSelector: React.FC<ModelSelectorProps> = ({
  value,
  onChange,
  models = generatorWhiteList,
  ...props
}) => {
  return (
    <GenericSelector
      value={value}
      placeholder="Model"
      onChange={onChange}
      {...props}
    >
      {models.map((model) => (
        <Select.Option
          value={model.modelName}
          displayName={<LabeledModel model={model} short={true} />}
          key={model.modelName}
          height="100%"
          width="100%"
          ellipsis={false}
        >
          <LabeledModel model={model} />
        </Select.Option>
      ))}
    </GenericSelector>
  );
};
