import { Divider, Link } from "sparkl-ui";
import NextLink from "next/link";
import { useMediaQuery } from "sparkl-ui";
import React from "react";
import { Credits } from "./credits";
import { dev_maybeMockSession } from "../../../utils/devtools";
import { useSession } from "next-auth/react";

const MenuLink = ({
  href,
  children,
}: {
  href: string;
  children: React.ReactNode;
}) => {
  let upMD = useMediaQuery("md", { match: "up" });
  return (
    <NextLink href={href} passHref>
      <Link
        font={upMD ? "1rem" : "1.38rem"}
        color
        width={upMD ? "auto" : "100%"}
        style={{
          wordBreak: "keep-all",
        }}
      >
        <div
          style={{
            padding: upMD ? "0 1rem" : "0.5rem 1rem",
            display: "flex",
            width: "100%",
            flexDirection: "row",
            justifyContent: "end",
          }}
        >
          {children}
        </div>
      </Link>
    </NextLink>
  );
};

export const MenuLinks = () => {
  let upMD = useMediaQuery("md", { match: "up" });

  const session = dev_maybeMockSession(useSession());

  return (
    <div
      style={{
        display: "flex",
        flexDirection: upMD ? "row" : "column",
        width: upMD ? "fit-content" : "100%",
        height: upMD ? "100%" : "fit-content",
        alignItems: upMD ? "center" : "end",
        alignContent: "stretch",
        alignSelf: "center",
        gap: "0.5rem",
      }}
    >
      <MenuLink href="https://docs.sparklpaint.com/">Docs</MenuLink>
      {session && (
        <MenuLink href="/profile?tab=credits">
          <Credits />
        </MenuLink>
      )}
    </div>
  );
};
