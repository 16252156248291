import {
  CSSProperties,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import { Generator } from "../genie/generator";
import { GeneratorBar } from "../selectors/generator_bar";
import { RecentsGrid } from "./recents_grid/recents_grid";
import { useSize } from "../../utils/use_size";
export interface GeneratorAreaProps {
  topNavHeight: string;
  fullWidth?: boolean;
}

// @ts-ignore
export const GeneratorArea: React.FC<GeneratorAreaProps> = ({
  topNavHeight,
  fullWidth = false,
}) => {
  const initialRef = useRef(null);
  const { ref, size } = useSize({ initialRef });

  const commonAreaStyles: CSSProperties = {
    position: "sticky",
    top: topNavHeight,
  };

  const areaStyle = fullWidth
    ? ({
        ...commonAreaStyles,
        height: "100%",
        display: "grid",
        alignContent: "start",
        paddingBottom: "4rem",
      } as CSSProperties)
    : ({
        ...commonAreaStyles,

        overflowY: "auto",
        maxWidth: "100%",
        paddingLeft: "0.685rem",
        paddingRight: "0.685rem",
      } as CSSProperties);

  const commonFormStyles = {
    //@ts-ignore
    paddingTop: size ? (size.width > 436.6 ? "3.1rem" : "6.2rem") : 0,
    paddingBottom: "4rem",
  };

  const formStyle = fullWidth
    ? ({
        ...commonFormStyles,
        width: "100%",
        height: "100%",
        minHeight: "1vw",
      } as CSSProperties)
    : ({
        ...commonFormStyles,
        position: "sticky",
        top: topNavHeight,
        zIndex: -1,
        overflowY: "auto",
        maxWidth: "100%",
        maxHeight: `calc(100vh - ${topNavHeight})`,
      } as CSSProperties);

  return (
    <div ref={ref} id="generator-area" className="no-scroll" style={areaStyle}>
      <GeneratorBar />
      <div id="generator-form" className="no-scroll" style={formStyle}>
        <Generator />
        <RecentsGrid />
      </div>
    </div>
  );
};
