import Archive from "@geist-ui/icons/archive";
import FastForward from "@geist-ui/icons/fastForward";
import HeartFill from "@geist-ui/icons/heartFill";
import Star from "@geist-ui/icons/star";
import { ReactElement } from "react";
import { Button, Grid, Modal, useModal } from "sparkl-ui";
import { FeatureCalloutCell, EmbeddedFeatureCalloutCell } from "./feature_cell";
import { config } from "../../utils/config";

function UpsellAdvert() {
  return (
    <Grid.Container justify="center" alignContent="center">
      <Grid xs={24} justify="center">
        <Grid.Container justify="center" alignContent="center" gap={2}>
          <FeatureCalloutCell
            icon={<Archive color="blue" />}
            title="Generate more"
            text="Get 1000 additional monthly credits"
          />
          <FeatureCalloutCell
            icon={<FastForward color="blue" />}
            title="Priority queueing"
            text="Your jobs run first if our servers are overloaded"
          />
          <FeatureCalloutCell
            icon={<Star color="blue" />}
            title="Other perks"
            text="Get beta access to new features, and more!"
          />
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );
}

export const UpsellEmbed = () => {
  return (
    <Grid.Container
      justify="center"
      alignContent="center"
      style={{ overflowX: "scroll" }}
    >
      <Grid xs={24} justify="center">
        <Grid.Container
          justify="center"
          alignContent="center"
          gap={2}
          direction="row"
        >
          <EmbeddedFeatureCalloutCell
            icon={<Archive color="blue" />}
            title="Generate more"
            text="Get 1000 additional monthly credits"
          />
          <EmbeddedFeatureCalloutCell
            icon={<FastForward color="blue" />}
            title="Priority queueing"
            text="Your jobs run first if our servers are busy"
          />
          <EmbeddedFeatureCalloutCell
            icon={<Star color="blue" />}
            title="Other perks"
            text="Get beta access to new features, and more!"
          />
        </Grid.Container>
      </Grid>
    </Grid.Container>
  );
};

export const useUpsellModal = () => {
  const { visible, setVisible, bindings } = useModal();

  const toggleUpsell = () => {
    //console.log("Setting upsell visible to", !visible);
    setVisible(!visible);
  };
  const UpsellModal = (): ReactElement => (
    <Modal width="700px" {...bindings}>
      <Grid.Container justify="center" alignContent="center" padding={2}>
        <Grid xs={24} justify="center">
          <h2>Subscribe to Sparklpaint Plus</h2>
        </Grid>
        <Grid sm={12} justify="center">
          <UpsellAdvert />
        </Grid>
        <Grid sm={12} justify="center">
          <Grid.Container justify="center" alignContent="center">
            <Grid xs={24} justify="center" padding="5px">
              <form action="/api/billing/checkout" method="POST">
                {/* Add a hidden field with the lookup_key of your Price */}
                <input
                  type="hidden"
                  name="lookup_key"
                  value={config.subscriptionPriceId}
                />
                <Button type="success-light" htmlType="submit" scale={2} shadow>
                  Subscribe ($10/mo)
                </Button>
              </form>
            </Grid>
          </Grid.Container>
        </Grid>
      </Grid.Container>
    </Modal>
  );

  return {
    toggleUpsell,
    UpsellModal,
  };
};
