import { BaseTagData, TagData, TagifyRuntimeSettings } from "@yaireo/tagify";
import { Model } from "../../utils/feed_types";
import knight from "../../public/images/model_avatars/knight-face.png";
import cheavlier from "../../public/images/model_avatars/chevalier-face.png";
import maiden from "../../public/images/model_avatars/maiden-face.png";
import bard from "../../public/images/model_avatars/bard-face.png";
import mage from "../../public/images/model_avatars/mage-face.png";
import squire from "../../public/images/model_avatars/squire-face.png";
import sparky from "../../public/images/model_avatars/sparky.png";
import { StaticImageData } from "next/image";

export interface ModelTagInfo extends BaseTagData {
  modelName: Model | "all";
  name: string;
  avatar: StaticImageData;
  popularName: string;
  text: string;
  value: Model | "all";
  color: string;
}

const VerdantInfo: ModelTagInfo = {
  modelName: "verdant",
  value: "verdant",
  name: "Knight",
  popularName: "Stable Diffusion",
  avatar: knight,
  text: "General powerful model, does well with style prompts",
  color: "#0058B4",
};
const Verdant2Info: ModelTagInfo = {
  modelName: "verdant2",
  value: "verdant2",
  name: "Chevalier",
  popularName: "Stable Diffusion 2",
  avatar: cheavlier,
  text: "New general powerful model, does well with style prompts",
  color: "#0058B4",
};
const WaifuInfo: ModelTagInfo = {
  modelName: "waifu",
  value: "waifu",
  name: "Maiden",
  popularName: "Waifu Diffusion",
  avatar: maiden,
  text: "A finetuned model that generates anime-style images",
  color: "#FDFE01",
};
const DalleInfo: ModelTagInfo = {
  modelName: "dalle",
  value: "dalle",
  name: "Bard",
  popularName: "Dalle-mini",
  avatar: bard,
  text: "Excels at mixing unrelated concepts, great for memes.",
  color: "#2CB87F",
};
const VQGANInfo: ModelTagInfo = {
  modelName: "vqgan",
  value: "vqgan",
  name: "Mage",
  popularName: "VQGAN+CLIP",
  avatar: mage,
  text: "Generates less realistic, but more psychedelic visuals",
  color: "#961BF5",
};
const DiffuseInfo: ModelTagInfo = {
  modelName: "diffuse",
  value: "diffuse",
  name: "Squire",
  popularName: "Latent Diffusion",
  avatar: squire,
  text: "A predecessor of Knight, less powerful but similar in capabilities",
  color: "#CA0640",
};

const AllModels: ModelTagInfo = {
  modelName: "all",
  value: "all",
  name: "All Models",
  popularName: "",
  avatar: sparky,
  text: "See generations from all models",
  color: "#F9965A",
};

export const generatorWhiteList = [
  VerdantInfo,
  Verdant2Info,
  WaifuInfo,
  DalleInfo,
  VQGANInfo,
  DiffuseInfo,
];
export const selectorWhiteList = [
  AllModels,
  VerdantInfo,
  Verdant2Info,
  WaifuInfo,
  DalleInfo,
  VQGANInfo,
  DiffuseInfo,
];

export const nameToModelTagInfo: Record<Model, ModelTagInfo> = {
  verdant: VerdantInfo,
  verdant2: Verdant2Info,
  dalle: DalleInfo,
  vqgan: VQGANInfo,
  diffuse: DiffuseInfo,
  waifu: WaifuInfo,
};
