export const FilledHeart: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="-5 -5 55 55"
    >
      <path
        style={{ fill: "currentColor" }}
        d="m24 41.95-2.05-1.85q-5.3-4.85-8.75-8.375-3.45-3.525-5.5-6.3T4.825 20.4Q4 18.15 4 15.85q0-4.5 3.025-7.525Q10.05 5.3 14.5 5.3q2.85 0 5.275 1.35Q22.2 8 24 10.55q2.1-2.7 4.45-3.975T33.5 5.3q4.45 0 7.475 3.025Q44 11.35 44 15.85q0 2.3-.825 4.55T40.3 25.425q-2.05 2.775-5.5 6.3T26.05 40.1Z"
      />
    </svg>
  );
};

export const EmptyHeart: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="-5 -5 55 55"
    >
      <path
        style={{ fill: "currentColor" }}
        d="m24 41.95-2.05-1.85q-5.3-4.85-8.75-8.375-3.45-3.525-5.5-6.3T4.825 20.4Q4 18.15 4 15.85q0-4.5 3.025-7.525Q10.05 5.3 14.5 5.3q2.85 0 5.275 1.35Q22.2 8 24 10.55q2.1-2.7 4.45-3.975T33.5 5.3q4.45 0 7.475 3.025Q44 11.35 44 15.85q0 2.3-.825 4.55T40.3 25.425q-2.05 2.775-5.5 6.3T26.05 40.1ZM24 38q5.05-4.65 8.325-7.975 3.275-3.325 5.2-5.825 1.925-2.5 2.7-4.45.775-1.95.775-3.9 0-3.3-2.1-5.425T33.5 8.3q-2.55 0-4.75 1.575T25.2 14.3h-2.45q-1.3-2.8-3.5-4.4-2.2-1.6-4.75-1.6-3.3 0-5.4 2.125Q7 12.55 7 15.85q0 1.95.775 3.925.775 1.975 2.7 4.5Q12.4 26.8 15.7 30.1 19 33.4 24 38Zm0-14.85Z"
      />
    </svg>
  );
};

export const Sparkles: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 332 270"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M172.4 204.75C172.4 204.75 164.05 163.859 146.077 145.969C128.566 128.538 88.1667 119.25 88.1667 119.25C88.1667 119.25 128.566 109.962 146.077 92.5313C164.05 74.6407 172.4 33.75 172.4 33.75C172.4 33.75 180.75 74.6407 198.723 92.5313C216.234 109.962 256.633 119.25 256.633 119.25C256.633 119.25 216.234 128.538 198.723 145.969C180.75 163.859 172.4 204.75 172.4 204.75Z"
        fill="currentColor"
      />
      <path
        d="M85.4 239.792C85.4 239.792 81.7587 220.861 73.9208 212.578C66.2844 204.508 48.6667 200.208 48.6667 200.208C48.6667 200.208 66.2844 195.908 73.9208 187.839C81.7587 179.556 85.4 160.625 85.4 160.625C85.4 160.625 89.0413 179.556 96.8792 187.839C104.516 195.908 122.133 200.208 122.133 200.208C122.133 200.208 104.516 204.508 96.8792 212.578C89.0413 220.861 85.4 239.792 85.4 239.792Z"
        fill="currentColor"
      />
      <path
        d="M53.2 105.858C53.2 105.858 49.025 85.7159 40.0386 76.9031C31.283 68.3169 11.0833 63.7417 11.0833 63.7417C11.0833 63.7417 31.283 59.1665 40.0386 50.5802C49.025 41.7675 53.2 21.625 53.2 21.625C53.2 21.625 57.375 41.7675 66.3615 50.5802C75.117 59.1665 95.3167 63.7417 95.3167 63.7417C95.3167 63.7417 75.117 68.3169 66.3615 76.9031C57.375 85.7159 53.2 105.858 53.2 105.858Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const Share: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="-3 -3 58 58"
    >
      <path
        style={{ fill: "currentColor" }}
        d="M11.6 46.65q-1.65 0-2.8-1.175T7.65 42.7V19.05q0-1.65 1.15-2.8t2.8-1.15h7.35v3.95H11.6V42.7h24.8V19.05h-7.45V15.1h7.45q1.65 0 2.825 1.15 1.175 1.15 1.175 2.8V42.7q0 1.6-1.175 2.775Q38.05 46.65 36.4 46.65ZM21.95 31.4V9l-4.4 4.4-2.8-2.8 9.2-9.25 9.2 9.25-2.8 2.8-4.4-4.4v22.4Z"
      />
    </svg>
  );
};

export const Screenshot_filled: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="-3 -3 58 58"
    >
      <path
        style={{ fill: "currentColor" }}
        d="M24 34.7q3.6 0 6.05-2.45 2.45-2.45 2.45-6.05 0-3.65-2.45-6.075Q27.6 17.7 24 17.7q-3.65 0-6.075 2.425Q15.5 22.55 15.5 26.2q0 3.6 2.425 6.05Q20.35 34.7 24 34.7Zm-16.75 8q-1.6 0-2.775-1.175Q3.3 40.35 3.3 38.75V13.6q0-1.6 1.175-2.8Q5.65 9.6 7.25 9.6h6.95l3.95-4.65H29.9l3.9 4.65h6.95q1.6 0 2.8 1.2 1.2 1.2 1.2 2.8v25.15q0 1.6-1.2 2.775-1.2 1.175-2.8 1.175Z"
      />
    </svg>
  );
};

export const Screenshot: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="-3 -3 58 58"
    >
      <path
        style={{ fill: "currentColor" }}
        d="M24 34.7q3.6 0 6.05-2.45 2.45-2.45 2.45-6.1 0-3.6-2.45-6.025Q27.6 17.7 24 17.7q-3.65 0-6.075 2.425Q15.5 22.55 15.5 26.15q0 3.65 2.425 6.1Q20.35 34.7 24 34.7Zm0-3q-2.4 0-3.95-1.575-1.55-1.575-1.55-3.975 0-2.35 1.55-3.9Q21.6 20.7 24 20.7q2.35 0 3.925 1.55 1.575 1.55 1.575 3.9 0 2.4-1.575 3.975Q26.35 31.7 24 31.7Zm-16.75 11q-1.6 0-2.775-1.175Q3.3 40.35 3.3 38.75V13.6q0-1.6 1.175-2.8Q5.65 9.6 7.25 9.6h6.95l3.95-4.65H29.9l3.9 4.65h6.95q1.6 0 2.8 1.2 1.2 1.2 1.2 2.8v25.15q0 1.6-1.2 2.775-1.2 1.175-2.8 1.175Zm33.5-3.95V13.6H32l-3.95-4.65h-8.1L16 13.6H7.25v25.15ZM24 26.2Z"
      />
    </svg>
  );
};

export const Images1: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="100" height="100" rx="10" fill="currentColor" />
    </svg>
  );
};

export const Images4: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 214 214"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="114" width="100" height="100" rx="10" fill="currentColor" />
      <rect width="100" height="100" rx="10" fill="currentColor" />
      <rect
        x="114"
        y="114"
        width="100"
        height="100"
        rx="10"
        fill="currentColor"
      />
      <rect y="114" width="100" height="100" rx="10" fill="currentColor" />
    </svg>
  );
};

export const Images9: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 328 328"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect x="114" width="100" height="100" rx="10" fill="currentColor" />
      <rect width="100" height="100" rx="10" fill="currentColor" />
      <rect x="228" width="100" height="100" rx="10" fill="currentColor" />
      <rect
        x="114"
        y="114"
        width="100"
        height="100"
        rx="10"
        fill="currentColor"
      />
      <rect y="114" width="100" height="100" rx="10" fill="currentColor" />
      <rect
        x="228"
        y="114"
        width="100"
        height="100"
        rx="10"
        fill="currentColor"
      />
      <rect
        x="114"
        y="228"
        width="100"
        height="100"
        rx="10"
        fill="currentColor"
      />
      <rect y="228" width="100" height="100" rx="10" fill="currentColor" />
      <rect
        x="228"
        y="228"
        width="100"
        height="100"
        rx="10"
        fill="currentColor"
      />
    </svg>
  );
};

export const Inpaint: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 128 128"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M93.2102 34.0923C93.0298 34.1955 88.8399 38.4418 83.9409 43.4848C72.9698 54.7849 65.3828 62.9073 61.2832 67.7394C60.6966 68.4331 59.5879 69.811 59.5879 69.8485C59.5879 69.8673 59.8973 69.961 60.2841 70.0641C60.6644 70.1625 61.5088 70.4391 62.1663 70.6781C63.8874 71.3061 64.9574 71.8076 66.6011 72.7685C67.0395 73.0215 67.4069 73.2231 67.4133 73.2137C67.4714 73.1668 68.1031 72.3841 68.6574 71.6764C72.886 66.3052 81.6332 53.5194 92.5076 36.806C93.3069 35.5828 93.9709 34.5001 93.9902 34.4064C94.016 34.2658 93.9967 34.2142 93.8613 34.1158C93.655 33.9705 93.4423 33.9611 93.2102 34.0923Z"
        fill="currentColor"
      />
      <path
        d="M58.4663 71.4796C57.4736 73.2043 56.4745 74.8213 54.4504 77.9803C52.7487 80.633 51.8076 82.1656 51.8656 82.1984C51.8785 82.2031 52.0718 82.2359 52.3039 82.2641C53.5609 82.4422 55.0821 83.0983 55.9201 83.8342C56.1006 83.9888 56.2746 84.1248 56.3069 84.1341C56.3842 84.1576 57.5896 82.9999 60.0455 80.5346C62.6239 77.9474 64.1839 76.4195 65.4537 75.2384C66.5882 74.1839 66.556 74.2261 66.382 74.123C66.3046 74.0808 65.9565 73.8746 65.6084 73.6636C63.6489 72.5013 61.4057 71.5592 59.4009 71.0624C59.098 70.9874 58.8401 70.9265 58.8208 70.9265C58.8015 70.9265 58.6403 71.1749 58.4663 71.4796Z"
        fill="currentColor"
      />
      <path
        d="M49.1969 82.7609C47.4565 82.9671 45.9159 83.6514 44.8136 84.7059C44.414 85.0856 43.9112 85.8355 43.7372 86.2995C43.1441 87.893 42.8476 88.5304 42.319 89.3178C41.8227 90.0536 41.2619 90.6254 40.5399 91.1551C39.1863 92.144 37.5748 92.7299 35.0608 93.1376C34.4227 93.2454 34.1971 93.3017 34.1133 93.3766C33.9457 93.5266 33.965 93.7328 34.1648 93.8781L34.3324 94L37.4394 93.9719C39.1412 93.9531 41.0492 93.9203 41.668 93.8922C48.8682 93.5969 53.0001 92.6924 55.3206 90.916C56.4616 90.0443 56.9901 89.1163 57.0546 87.8696C57.1255 86.5197 56.6356 85.4746 55.456 84.4341C54.6438 83.717 53.2515 83.089 51.9494 82.8499C51.1307 82.7046 49.9962 82.6624 49.1969 82.7609Z"
        fill="currentColor"
      />
      <path
        d="M24.6667 113.867C21.8222 113.867 19.3556 112.822 17.2667 110.733C15.1778 108.644 14.1334 106.178 14.1334 103.333V24.6667C14.1334 21.7333 15.1778 19.2222 17.2667 17.1333C19.3556 15.0444 21.8222 14 24.6667 14H103.333C106.267 14 108.778 15.0444 110.867 17.1333C112.956 19.2222 114 21.7333 114 24.6667V103.333C114 106.178 112.956 108.644 110.867 110.733C108.778 112.822 106.267 113.867 103.333 113.867H24.6667ZM24.6667 103.333H103.333V24.6667H24.6667V103.333Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const NoImage: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="m42.5 47.05-4.35-4.35H9.25q-1.65 0-2.8-1.15t-1.15-2.8V9.85l-3.85-3.8 2.35-2.4L44.85 44.7Zm-33.25-8.3H34.2L28.45 33H12l6-7 4 5.45 2.25-2.7-15-15v25Zm33.5-.5-4-3.95V9.25H13.7l-3.95-4h29q1.65 0 2.825 1.175Q42.75 7.6 42.75 9.25Zm-16.4-16.3Zm-4.6 4.35Z"
      />
    </svg>
  );
};

export const InitImage: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      width="100%"
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="M9.25 42.7q-1.6 0-2.775-1.175Q5.3 40.35 5.3 38.75V9.25q0-1.65 1.175-2.825Q7.65 5.25 9.25 5.25h29.5q1.65 0 2.825 1.175Q42.75 7.6 42.75 9.25v29.5q0 1.6-1.175 2.775Q40.4 42.7 38.75 42.7Zm0-3.95h29.5V9.25H9.25v29.5Zm2.2-4.35H36.6l-7.7-10.45-6.6 8.55-4.65-6.35Zm-2.2 4.35V9.25v29.5Z"
      />
    </svg>
  );
};

export const AspectSquare: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="-10 -10 100 100"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="80" height="80" rx="7" fill="currentColor" />
    </svg>
  );
};
export const AspectLandscape: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 40 30"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="40" height="30" rx="3" fill="currentColor" />
    </svg>
  );
};

export const AspectPortrait: React.FC = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 30 40"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect width="30" height="40" rx="3" fill="currentColor" />
    </svg>
  );
};

export const Dice: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="M15.2 35.3q1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.75-1.775-.75-.725-1.8-.725-1 0-1.725.75-.725.75-.725 1.8 0 1 .725 1.725.725.725 1.775.725Zm0-17.6q1.05 0 1.775-.75.725-.75.725-1.8 0-1-.75-1.725-.75-.725-1.8-.725-1 0-1.725.725-.725.725-.725 1.775 0 1.05.725 1.775.725.725 1.775.725Zm8.8 8.8q1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775Q25.05 21.5 24 21.5q-1.05 0-1.775.725Q21.5 22.95 21.5 24q0 1.05.725 1.775.725.725 1.775.725Zm8.85 8.8q1 0 1.725-.725.725-.725.725-1.775 0-1.05-.725-1.775-.725-.725-1.775-.725-1.05 0-1.775.75-.725.75-.725 1.8 0 1 .75 1.725.75.725 1.8.725Zm0-17.6q1 0 1.725-.75.725-.75.725-1.8 0-1-.725-1.725-.725-.725-1.775-.725-1.05 0-1.775.725-.725.725-.725 1.775 0 1.05.75 1.775.75.725 1.8.725ZM9.1 42.3q-1.35 0-2.375-1.025T5.7 38.9V9.1q0-1.4 1.025-2.4t2.375-1h29.8q1.4 0 2.4 1t1 2.4v29.8q0 1.35-1 2.375T38.9 42.3Zm0-3.4h29.8V9.1H9.1v29.8Zm0-29.8v29.8V9.1Z"
      />
    </svg>
  );
};

export const DiceFill: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
    >
      <path
        fill="currentColor"
        d="M15.75 34.75q1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775-.725-.725-1.775-.725-1.05 0-1.775.725-.725.725-.725 1.775 0 1.05.725 1.775.725.725 1.775.725Zm0-16.5q1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775-.725-.725-1.775-.725-1.05 0-1.775.725-.725.725-.725 1.775 0 1.05.725 1.775.725.725 1.775.725ZM24 26.5q1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775Q25.05 21.5 24 21.5q-1.05 0-1.775.725Q21.5 22.95 21.5 24q0 1.05.725 1.775.725.725 1.775.725Zm8.25 8.25q1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775-.725-.725-1.775-.725-1.05 0-1.775.725-.725.725-.725 1.775 0 1.05.725 1.775.725.725 1.775.725Zm0-16.5q1.05 0 1.775-.725.725-.725.725-1.775 0-1.05-.725-1.775-.725-.725-1.775-.725-1.05 0-1.775.725-.725.725-.725 1.775 0 1.05.725 1.775.725.725 1.775.725Zm-22.8 25q-1.95 0-3.325-1.375Q4.75 40.5 4.75 38.55V9.45q0-1.95 1.375-3.35Q7.5 4.7 9.45 4.7h29.1q1.95 0 3.35 1.4 1.4 1.4 1.4 3.35v29.1q0 1.95-1.4 3.325-1.4 1.375-3.35 1.375Z"
      />
    </svg>
  );
};

export const Popular: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
    >
      <path d="m11.65 44 3.25-14.05L4 20.5l14.4-1.25L24 6l5.6 13.25L44 20.5l-10.9 9.45L36.35 44 24 36.55Z" />
    </svg>
  );
};

export const New: React.FC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100%"
      height="100%"
      viewBox="0 0 48 48"
    >
      <path d="M23.85 42q-7.45 0-12.65-5.275T6 23.95h3q0 6.25 4.3 10.65T23.85 39q6.35 0 10.75-4.45t4.4-10.8q0-6.2-4.45-10.475Q30.1 9 23.85 9q-3.4 0-6.375 1.55t-5.175 4.1h5.25v3H7.1V7.25h3v5.3q2.6-3.05 6.175-4.8Q19.85 6 23.85 6q3.75 0 7.05 1.4t5.775 3.825q2.475 2.425 3.9 5.675Q42 20.15 42 23.9t-1.425 7.05q-1.425 3.3-3.9 5.75-2.475 2.45-5.775 3.875Q27.6 42 23.85 42Zm6.4-9.85-7.7-7.6v-10.7h3v9.45L32.4 30Z" />
    </svg>
  );
};
