import { ALLOW_DEV } from "../../../utils/devtools";
import { Login } from "../../login";
import DevTools from "../../devtools";
import { Spacer, useMediaQuery } from "sparkl-ui";
import { MenuLinks } from "./menu_links";
import React from "react";

export interface HeadsUpDisplayProps {
  style?: React.CSSProperties;
  toggleLogin: () => void;
}

export const HeadsUpDisplay: React.FC<HeadsUpDisplayProps> = ({
  style,
  toggleLogin,
}) => {
  let upMD = useMediaQuery("md", { match: "up" });

  return (
    <div
      style={{
        display: "flex",
        flexDirection: upMD ? "row-reverse" : "column",
        width: upMD ? "fit-content" : "auto",
        height: upMD ? "fit-content" : "100%",
        alignItems: "stretch",
        alignContent: "center",
        gap: "1rem",
        ...style,
      }}
    >
      {/* Top button bar  */}
      <div
        style={{
          display: "flex",
          flexDirection: "row-reverse",
          flexWrap: "wrap",
          width: "auto",
          height: "fit-content",
          gap: "0.62rem 1rem",
          justifyContent: "end",
          alignItems: "end",
          paddingRight: "1.5rem",
        }}
      >
        <Login toggleLogin={toggleLogin} />
        <div style={{ display: "flex", flexDirection: "row", gap: "1rem" }}>
          {ALLOW_DEV && <DevTools />}
        </div>
      </div>
      {/* Links to various pages */}
      <MenuLinks />
    </div>
  );
};
