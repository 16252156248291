import { useState } from "react";
import { Description, Text, Tooltip, useTheme } from "sparkl-ui";
import { ModelTagInfo } from "./model_tags";
import Avatar from "../avatar";
export interface LabeledModelProps {
  model: ModelTagInfo;
  short?: boolean;
}

export const LabeledModel: React.FC<LabeledModelProps> = ({
  model,
  short = false,
}) => {
  const theme = useTheme();
  const [hover, setHover] = useState(false);
  const size = short ? "1.75rem" : "2.75rem";
  const scaleStyle = hover
    ? {
        transform: `scale(1.21)`,
        transformOrigin: "center",
        transition: "0.12s ease-out",
      }
    : {};

  return (
    <div
      style={{
        width: "100%",
        maxWidth: "100vw",
      }}
      onMouseOver={() => {
        // use a scale transform to make the avatar grow
        setHover(true);
      }}
      onMouseOut={() => {
        setHover(false);
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "start",
          justifyItems: "space-between",
          alignItems: "center",

          wordBreak: "break-word",
          height: "100%",
          paddingTop: "0.38rem",
          paddingBottom: "0.38rem",
          gap: "0.38rem",
        }}
      >
        <div style={{ minWidth: size, ...scaleStyle }}>
          <Avatar
            alt={"Avatar for " + model.name}
            src={model.avatar.src}
            height={size}
            width={size}
            marginLeft={0}
            style={{
              borderRadius: "50%",
              border: `2px solid ${model.color}`,
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              alignItems: "center",

              gap: "0.38rem",
            }}
          >
            <Text font="1rem" b>
              {model.name}
            </Text>
            <Text style={{ opacity: 0.5, paddingRight: "0.5rem" }} i>
              {model.popularName}
            </Text>
          </div>
          {/* if short is true, don't show this */}
          {!short ? (
            <Text font={"14px"} small color={theme.palette.secondary}>
              {model.text}
            </Text>
          ) : (
            ""
          )}
        </div>
      </div>
    </div>
  );
};
