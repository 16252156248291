// This section sets up the context provider (used in _app.tsx)
// and the context hooks, which are used by any component that needs to

import { createContext, useContext, useReducer } from "react";
import { defaultGenieParams, GenieAction, genieReducer } from "./genie_reducer";

// read or set the GenieParams respectively
const GenieContext = createContext(defaultGenieParams);
const GenieDispatchContext = createContext((action: GenieAction) =>
  console.log("no dispatch")
);
const GenieFocusContext = createContext(() => console.log("no focus"));

export function GenieProvider({
  children,
  focusGenie,
}: {
  children: React.ReactNode;
  focusGenie: () => void;
}) {
  const [genie, dispatch] = useReducer(genieReducer, defaultGenieParams);

  return (
    <GenieFocusContext.Provider value={focusGenie}>
      <GenieContext.Provider value={genie}>
        <GenieDispatchContext.Provider value={dispatch}>
          {children}
        </GenieDispatchContext.Provider>
      </GenieContext.Provider>
    </GenieFocusContext.Provider>
  );
}

export function useGenie() {
  return useContext(GenieContext);
}

export function useGenieDispatch() {
  return useContext(GenieDispatchContext);
}

export function useGenieFocus() {
  return useContext(GenieFocusContext);
}
