// This component wraps the Button components from sparkl-ui with some state logic
// to keep track of which button is selected. Only one button can be selected
// at a time. The selected button is highlighted and the callback is called
// when the button is clicked. The button remains selected until changed again.
// Really this is a radio button component but visualized in the form of a multi-button toggle.

import React, { useState } from "react";
import { Button, Radio, useTheme } from "sparkl-ui";

// The options passed to this component will be objects that have a callback, a key and a React node label.
export interface ButtonSelectOption {
  callback: () => void;
  key: string;
  icon: JSX.Element;
  label?: React.ReactNode;
}
export interface ButtonSelectProps {
  options: ButtonSelectOption[];
  selectedKey?: string;
}

export const ButtonSelect: React.FC<ButtonSelectProps> = ({
  options,
  selectedKey,
}) => {
  // console.log(selectedKey);
  const theme = useTheme();
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      {options.map((option: any, index: number) => {
        return (
          <Button
            auto
            scale={1.62}
            type="success-light"
            ghost={true}
            style={{
              backgroundColor:
                selectedKey === option.key ? theme.palette.successLight : "",
              color:
                selectedKey !== option.key ? `${theme.palette.secondary}` : "",
              borderColor:
                selectedKey !== option.key ? `${theme.palette.secondary}` : "",
              width: "100%",
              padding: "0",
              // Make the button look "indented" when pressed
              borderTop:
                option.key === selectedKey ? ".12rem solid" : ".08rem solid",
              borderLeft:
                option.key === selectedKey ? ".12rem solid" : ".08rem solid",
              borderRight:
                option.key === selectedKey ? ".08rem solid" : ".12rem solid",
              borderBottom:
                option.key === selectedKey ? ".08rem solid" : ".12rem solid",
              boxShadow:
                selectedKey === option.key
                  ? `inset .08rem .08rem 0 0 1 ${theme.palette.border}`
                  : `.08rem .08rem 0 0 1 ${theme.palette.border}`,
              // Make the buttons look like they're connected
              borderTopLeftRadius: index === 0 ? theme.layout.radius : "0",
              borderBottomLeftRadius: index === 0 ? theme.layout.radius : "0",
              borderTopRightRadius:
                index === options.length - 1 ? theme.layout.radius : "0",
              borderBottomRightRadius:
                index === options.length - 1 ? theme.layout.radius : "0",
            }}
            key={option.key}
            onClick={() => {
              option.callback();
            }}
            icon={option.icon}
          >
            {option.label ? option.label : null}
          </Button>
        );
      })}
    </div>
  );
};
