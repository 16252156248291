import cookie from "cookie";
import { IncomingMessage } from "http";
import { Session } from "next-auth";
import { JWT } from "next-auth/jwt/types";
import { SessionContextValue } from "next-auth/react";
import { NextIncomingMessage } from "next/dist/server/request-meta";
import { config } from "./config";

// TODO: Move this to a config somewhere
// This should _not_ be the same as checking if node env is "development"
// Because that says "false" for a deploy to fly, whereas we want to have
// dev instances on fly as well.

// Ideally this should be a per-branch thing, ie: some branches are just _always_ dev
// and have security backdoors + additional utilities exposed
export const ALLOW_DEV = config.allowDev;

// Gates whether this file is noisy or not
// Would be nice to do this better in the long run?
const VERBOSE_DEV_LOGGING = false;

export function parseCookies(req: NextIncomingMessage | null) {
  return cookie.parse(
    req
      ? req.headers.cookie || ""
      : typeof document !== "undefined"
      ? document.cookie
      : ""
  );
}

export function dev_getDevCookie(key: string): string | undefined {
  if (!ALLOW_DEV) {
    return undefined;
  }
  const cookies = parseCookies(null);

  return cookies["DryadDevTools_" + key];
}

type useSessionReturnType =
  | SessionContextValue
  | {
      readonly data: null;
      readonly status: "loading";
    };

const getMockSession = (): Session | null => {
  if (!ALLOW_DEV) {
    return null;
  }

  const mockUser = dev_getDevCookie("mockUser");
  if (mockUser !== undefined) {
    return JSON.parse(Buffer.from(mockUser, "base64").toString());
  } else {
    VERBOSE_DEV_LOGGING &&
      console.log("Didn't find mockUser in cookie, returning null");
  }
  return null;
};

export const dev_maybeMockSession = (
  useSessionReturn: useSessionReturnType
): Session | null => {
  const { data: session } = useSessionReturn;
  if (!ALLOW_DEV) {
    return session;
  }

  return getMockSession() ?? session;
};

export const dev_mockUserHeader = (): Headers | undefined => {
  if (!ALLOW_DEV) {
    return undefined;
  }
  const mockSession = getMockSession();
  if (mockSession !== null) {
    const mockSessionBase64 = Buffer.from(JSON.stringify(mockSession)).toString(
      "base64"
    );
    console.log("Setting header cookie with", mockSessionBase64);
    return new Headers({
      cookie: `DryadDevTools_mockUser=${mockSessionBase64}`,
    });
  }
  return undefined;
};

export const dev_mockToken = (req: IncomingMessage): JWT | undefined => {
  if (!ALLOW_DEV) {
    return undefined;
  }
  //Check if a mock user cookie is present
  const mockUserCookie = parseCookies(req)["DryadDevTools_mockUser"];
  VERBOSE_DEV_LOGGING && console.log("Mock userCookie", mockUserCookie);
  if (mockUserCookie !== undefined) {
    //base 64 decode mockHeader
    const mockSession = Buffer.from(mockUserCookie, "base64").toString();
    VERBOSE_DEV_LOGGING && console.log("Mocking token with", mockSession);
    VERBOSE_DEV_LOGGING && console.log("Mocking token for", req.url);
    return JSON.parse(mockSession);
  }
  VERBOSE_DEV_LOGGING && console.log("Not mocking token for ", req.url);
  return undefined;
};

export interface MockUserSession extends Session, JWT {
  user_id: number;
  username: string;
  picture: string;
  is_subscriber: boolean;
  role: string | null;
  stripe_id: string | null;
}
export interface EncodedMockUserSession {
  encoded: string;
  user: MockUserSession;
}

function mockUserFor(
  user_id: number,
  username: string,
  role: string | null,
  is_subscriber: boolean
): EncodedMockUserSession {
  const obj = {
    user_id: user_id,
    username: username,
    picture: "",
    is_subscriber: is_subscriber,
    role: role,
    stripe_id: null,
    expires: "",
  };
  return {
    encoded: Buffer.from(JSON.stringify(obj)).toString("base64"),
    user: obj,
  };
}

const mockAdmin = mockUserFor(38, "mock_admin", "admin", false);
const mockSubscriber = mockUserFor(39, "mock_subscriber", "alpha", true);
const mockUser = mockUserFor(41, "mock_user", null, false);

export const mockUsers = [mockAdmin, mockSubscriber, mockUser];
